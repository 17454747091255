<template>
    <div>
        <div class="box">
            <div class="box-header | flex justify-between">
                <h4 class="box-title">
                    {{ $t('users.edit.auth') }}
                </h4>

                <a class="action-link float-right" @click="showCreateTokenForm">
                    {{ $t('users.edit.personalAccessToken.createNewToken') }}
                </a>
            </div>
            <div class="box-body | p-0">
                <table class="table | border-t-2">
                    <thead>
                        <tr>
                            <th>{{ $t('users.edit.name') }}</th>
                            <th>{{ $t('users.edit.personalAccessToken.scopes') }}</th>
                            <th>{{ $t('users.edit.personalAccessToken.revoked') }}</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="!tokens.length">
                            <td class="text-center italic" colspan="100">
                                {{ $t('users.edit.personalAccessToken.noAccessToken') }}
                            </td>
                        </tr>

                        <tr :key="token.id" v-for="token in tokens">
                            <td>{{ token.name }}</td>
                            <td class="text-center">
                                {{ token.scopes }}
                            </td>
                            <td class="text-center">
                                {{ token.revoked ? $t('general.yes') : $t('general.no') }}
                            </td>
                            <td class="text-center">
                                <a class="action-link text-red-500" @click="revoke(token)" v-if="!token.revoked">
                                    {{ $t('users.edit.personalAccessToken.revoke') }}
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <create-access-token :opened.sync="createTokenModalOpened" :scopes="scopes" @created="showAccessToken" />

        <show-access-token :opened.sync="showTokenModalOpened" :access-token="accessToken" />

        <activix-confirm-modal
            type="warning"
            :title="$t('users.edit.personalAccessToken.revokeToken')"
            :approve-text="$t('general.yes')"
            :deny-text="$t('general.cancel')"
            :opened.sync="revokeTokenConfirmModal.opened"
            @approve="onRevokeConfirm"
            @cancel="revokeTokenConfirmModal.id = null"
        />
    </div>
</template>

<script>
    import { find } from 'lodash-es';

    // Components
    import { mapState } from 'pinia';
    import CreateAccessToken from '../modals/CreateAccessToken.vue';
    import ShowAccessToken from '../modals/ShowAccessToken.vue';
    import { useGlobalStore } from '../../store/store.js';
    import { useUserCardStore } from '../../store/modules/userCard/store.js';

    export default {
        components: {
            ShowAccessToken,
            CreateAccessToken,
        },

        data() {
            return {
                accessToken: null,

                scopes: [],
                revokeTokenConfirmModal: {
                    opened: false,
                    id: null,
                },
                createTokenModalOpened: false,
                showTokenModalOpened: false,
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['configs']),
            ...mapState(useUserCardStore, ['currentForm']),

            tokens() {
                return this.currentForm.personal_tokens;
            },
        },

        watch: {
            'form.name'() {
                this.form.scopes = [];
            },
        },

        methods: {
            prepareComponent() {
                this.getScopes();
            },

            getScopes() {
                this.$axios.get('oauth/scopes').then(response => {
                    this.scopes = response.data;
                });
            },

            showCreateTokenForm() {
                this.createTokenModalOpened = true;
            },

            showAccessToken(accessToken) {
                if (!accessToken) {
                    return;
                }

                this.accessToken = accessToken;

                this.$nextTick(() => {
                    this.showTokenModalOpened = true;
                });
            },

            revoke(token) {
                this.revokeTokenConfirmModal.opened = true;
                this.revokeTokenConfirmModal.id = token.id;
            },

            onRevokeConfirm() {
                this.$axios
                    .delete(
                        `v1/users/${this.$route.params.id}/personal-access-tokens/${this.revokeTokenConfirmModal.id}`,
                    )
                    .then(() => {
                        const tmpToken = find(
                            this.currentForm.personal_tokens,
                            obj => obj.id == this.revokeTokenConfirmModal.id,
                        );

                        tmpToken.revoked = true;
                        this.revokeTokenConfirmModal.id = null;
                    });
            },
        },

        mounted() {
            this.$nextTick(() => {
                this.prepareComponent();
            });
        },
    };
</script>
