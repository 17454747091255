<template>
    <div class="flex">
        <div class="flex-1">
            <activix-multiselect
                :options="orderedSuppliers"
                :selected="supplier"
                :disabled="disabled"
                @input="update('id', $event)"
            />
        </div>

        <div class="flex-1 ml-8">
            <activix-input
                :disabled="disabled"
                :value="supplier.state.related_id"
                @input="update('state.related_id', $event)"
            />
        </div>

        <div class="flex items-center w-4 ml-4">
            <icon
                :name="$icons.trash"
                class="link-grey-light"
                :class="disabled ? 'disabled' : 'hover:text-red-500'"
                @click="$emit('delete')"
            />
        </div>
    </div>
</template>

<script>
    import { orderBy, cloneDeep, set } from 'lodash-es';
    import { mapActions, mapState } from 'pinia';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useUserCardStore } from '../../store/modules/userCard/store.js';

    export default {
        props: {
            disabled: {
                type: Boolean,
                default: false,
            },
            identifier: {
                type: Number,
                required: true,
            },
            supplier: {
                type: Object,
                required: true,
            },
        },

        computed: {
            ...mapState(useUserCardStore, {
                suppliersUser: store => store.currentForm.suppliers,
            }),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            orderedSuppliers() {
                const suppliers = this.contextAccount.suppliers.filter(supplier => supplier.user_setting);

                return orderBy(suppliers, 'name').map(supplier => {
                    return {
                        id: supplier.id,
                        label: supplier.name,
                        disabled: this.suppliersUser.map(supplierUser => supplierUser.id).includes(supplier.id) && this.supplier.id != supplier.id,
                    };
                });
            },
        },

        methods: {
            ...mapActions(useUserCardStore, ['updateUserProperties']),

            update(attribute, value) {
                const suppliers = cloneDeep(this.suppliersUser);

                set(suppliers[this.identifier], attribute, value);

                this.updateUserProperties({ suppliers });
            },
        },
    };
</script>
