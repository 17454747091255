import Enum from './Enum.js';

export default class PersonalTokenPermissions extends Enum {
    static get entries() {
        return {
            viewLeads: 'view-leads',
            manageLeads: 'manage-leads',
            triggerFlow: 'trigger-flow',
            notesCreate: 'notes:create',
            notesUpdate: 'notes:update',
            notesView: 'notes:view',
        }
    }
}
