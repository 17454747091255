<template>
    <div>
        <div class="box | mb-6">
            <div class="box-header | border-b flex items-center justify-between">
                <h4 class="box-title">
                    {{ $t('users.edit.personalInformation') }}
                </h4>
                <activix-tooltip :content="userActiveTooltip">
                    <activix-switcher
                        :disabled="$route.params.id === authUser.id || (!authUser.isDirector() && !authUser.isAdmin()) || (!contextUser.active && contextUser.api_user)"
                        :value="currentForm.active"
                        @input="toggleActive"
                    />
                </activix-tooltip>
            </div>
            <div class="box-body">
                <div class="flex mb-4">
                    <el-popover placement="top" v-model="profilePicturePopoverVisible">
                        <div class="btn-group">
                            <activix-button @click="uploadPicture">
                                <icon name="regular/pencil-1" />
                            </activix-button>
                            <activix-button @click="deletePicture">
                                <icon :name="$icons.trash" />
                            </activix-button>
                        </div>
                        <div slot="reference">
                            <activix-tooltip
                                :content="profilePictureSrc == '/img/nobody.jpg' ? $t('users.edit.addPhoto') : ''"
                            >
                                <div class="flex">
                                    <img
                                        alt="Profile Picture"
                                        class="h-48 cursor-pointer"
                                        :src="profilePictureSrc"
                                        ref="profile_picture_img"
                                        slot="reference"
                                    />
                                </div>
                            </activix-tooltip>
                        </div>
                    </el-popover>

                    <input
                        class="hidden"
                        type="file"
                        accept=".jpg, .jpeg, .png"
                        ref="profile_picture"
                        @change="updatePicture"
                    />
                </div>

                <div class="row mb-4">
                    <div class="col-xs-3">
                        <label>{{ $t('users.edit.firstName') }}</label>
                        <input
                            type="text"
                            class="form-control"
                            @input="updateUserProperties({ first_name: $event.target.value })"
                            v-model="currentForm.first_name"
                        />
                    </div>
                    <div class="col-xs-3">
                        <label>{{ $t('users.edit.lastName') }}</label>
                        <input
                            type="text"
                            class="form-control"
                            @input="updateUserProperties({ last_name: $event.target.value })"
                            v-model="currentForm.last_name"
                        />
                    </div>
                    <div :class="mayUpdateTimezone ? 'col-xs-3' : 'col-xs-6'">
                        <label>{{ $t('users.edit.email') }}</label>
                        <input
                            type="text"
                            class="form-control"
                            @input="
                                updateUserProperties({ email: $event.target.value.toLowerCase() })
                            "
                            v-model="currentForm.email"
                        />
                    </div>
                    <div class="col-xs-3" v-if="mayUpdateTimezone">
                        <label>{{ $t('general.timezone') }}</label>
                        <activix-multiselect
                            identifier="value"
                            label="text"
                            :allow-empty="false"
                            :options="timezones"
                            :searchable="true"
                            :selected="selectedTimezone"
                            @update="updateTimezone"
                        />
                    </div>
                </div>

                <div class="row mb-4">
                    <div class="col-xs-3">
                        <label>{{ $t('users.edit.password') }}</label>
                        <activix-tooltip :content="hasParentUserTooltip">
                            <div>
                                <input
                                    type="password"
                                    class="form-control"
                                    :disabled="passwordDisabled"
                                    @input="updateUserProperties({ password: $event.target.value })"
                                    v-model="currentForm.password"
                                />
                            </div>
                        </activix-tooltip>
                    </div>
                    <div class="col-xs-3">
                        <label>{{ $t('users.edit.confirmation') }}</label>
                        <activix-tooltip :content="hasParentUserTooltip">
                            <div>
                                <input
                                    type="password"
                                    class="form-control"
                                    :disabled="passwordDisabled"
                                    @input="
                                        updateUserProperties({ password_confirmation: $event.target.value })
                                    "
                                    v-model="currentForm.password_confirmation"
                                />
                            </div>
                        </activix-tooltip>
                    </div>
                    <div class="col-xs-3">
                        <label>{{ $t('users.edit.gender') }}</label>
                        <activix-multiselect
                            identifier="value"
                            :options="genderOptions"
                            :selected="selectedGender"
                            :searchable="false"
                            :allow-empty="false"
                            @update="updateGender"
                        />
                    </div>
                    <div class="col-xs-3">
                        <label>{{ $t('users.edit.language') }}</label>
                        <activix-multiselect
                            identifier="value"
                            :options="langOptions"
                            :selected="selectedLang"
                            :searchable="false"
                            :allow-empty="false"
                            @update="updateLang"
                        />
                    </div>
                </div>

                <div class="row mb-4">
                    <div class="col-xs-3">
                        <label>{{ $t('users.edit.division') }}</label>
                        <activix-multiselect
                            multiple="multiple"
                            :options="orderedDivisions"
                            :selected="currentForm.divisions"
                            :searchable="false"
                            :allow-empty="false"
                            :close-on-select="false"
                            :taggable="true"
                            :custom-label="formatDivision"
                            :disabled="!authUser.isAdmin() && !authUser.isDirector() && !authUser.isBdcDirector()"
                            @update="updateDivisions"
                        />
                    </div>
                    <div class="col-xs-3">
                        <label class="flex items-center">
                            {{ $t('users.edit.defaultDashboard') }}
                            <activix-tooltip
                                :content="
                                    defaultDashboardDisabled
                                        ? $t('users.edit.defaultDashboardServiceTooltip')
                                        : $t('users.edit.defaultDashboardTooltip')
                                "
                            >
                                <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                            </activix-tooltip>
                        </label>
                        <activix-multiselect
                            identifier="value"
                            :options="orderedDashboards"
                            :selected="selectedDashboard"
                            :searchable="false"
                            :allow-empty="false"
                            :disabled="defaultDashboardDisabled"
                            @update="updateDefaultDashboard"
                        />
                    </div>
                    <div class="col-xs-3">
                        <label>{{ $t('users.edit.role') }}</label>
                        <activix-tooltip :content="hasParentUserTooltip">
                            <div>
                                <activix-multiselect
                                    :options="orderedRoles"
                                    :selected="currentForm.role"
                                    :searchable="false"
                                    :disabled="roleDisabled"
                                    :custom-label="formatRole"
                                    :allow-empty="false"
                                    @update="updateRole"
                                />
                            </div>
                        </activix-tooltip>
                    </div>
                    <div class="col-xs-3">
                        <label>{{ $t('users.edit.title') }}</label>
                        <activix-multiselect
                            :options="postOptions"
                            :selected="currentForm.post"
                            :searchable="false"
                            :disabled="empty(currentForm.role) || empty(postOptions)"
                            :custom-label="formatPost"
                            :allow-empty="false"
                            @update="updatePost"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="box | mb-6">
            <div class="box-header | border-b flex items-baseline">
                <h4 class="box-title">
                    {{ $t('users.edit.communicationInformation') }}
                </h4>
                <icon
                    name="bold/add"
                    class="link-grey-light ml-2"
                    @click="addPhone"
                    v-if="empty(currentForm.parent_user_id)"
                />
            </div>
            <div class="box-body">
                <activix-tooltip :content="hasParentUserTooltip">
                    <table class="inline-table">
                        <tr v-if="$route.name == 'users.store' || currentForm.user_phones.length">
                            <th>
                                {{ $t('users.edit.phoneNumber') }}
                            </th>
                            <th>
                                {{ $t('users.edit.post') }}
                            </th>
                            <th>
                                <div class="flex items-center">
                                    {{ $t('users.edit.ringtone') }}
                                    <activix-tooltip :content="$t('users.edit.ringtoneDescription')">
                                        <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                    </activix-tooltip>
                                </div>
                            </th>
                            <th>
                                <div class="flex items-center">
                                    {{ $t('users.edit.answer') }}
                                    <activix-tooltip :content="$t('users.edit.answerDescription')">
                                        <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                    </activix-tooltip>
                                </div>
                            </th>
                            <th class="collapsing">
                                {{ $t('users.edit.cellphone') }}
                            </th>
                            <th class="collapsing" v-if="currentForm.user_phones.length > 1">
                                <div class="flex items-center">
                                    {{ $t('users.edit.preferred') }}
                                    <activix-tooltip :content="$t('users.edit.preferredTooltip')">
                                        <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                    </activix-tooltip>
                                </div>
                            </th>
                            <th
                                class="collapsing"
                                v-if="currentForm.user_phones.length > 1 && contextAccount.hasOption('webboost')"
                            >
                                {{ $t('users.edit.webBoost') }}
                            </th>
                            <th
                                class="collapsing"
                                v-if="currentForm.user_phones.length > 1 && contextAccount.hasOption('phone_up')"
                            >
                                {{ $t('users.edit.phoneUp') }}
                            </th>
                            <th class="text-center">
                                <div class="flex items-center">
                                    <activix-tooltip :content="$t('accounts.edit.pressToTalkTooltip')">
                                        <icon name="bold/dial-finger" />
                                    </activix-tooltip>
                                </div>
                            </th>
                            <th />
                        </tr>
                        <tr
                            is="phone-number"
                            :default-phone="defaultPhone"
                            :phone-data="phone"
                            :identifier="index"
                            :disabled="!empty(currentForm.parent_user_id)"
                            :key="index"
                            @delete="deletePhoneAtIndex(index)"
                            v-for="(phone, index) in currentForm.user_phones"
                        />
                    </table>
                </activix-tooltip>
            </div>
        </div>

        <div class="flex space-x-6">
            <suppliers-user />

            <div class="box | mb-6">
                <div class="box-header | border-b">
                    <h4 class="box-title">
                        {{ $t('users.edit.accountInformation') }}
                    </h4>
                </div>
                <div class="box-body">
                    <div class="flex flex-wrap -mx-4 -my-2">
                        <div class="px-4 py-2" :class="hasSuppliersUserSetting ? 'w-1/2' : 'w-1/3'">
                            <label>{{ $t('users.edit.group') }}</label>
                            <activix-multiselect
                                multiple="multiple"
                                :options="orderedGroups"
                                :selected="currentForm.groups"
                                :searchable="false"
                                :close-on-select="false"
                                :disabled="!hasAccessToGroup"
                                :taggable="true"
                                @update="updateGroups"
                            />
                        </div>
                        <div class="px-4 py-2" :class="hasSuppliersUserSetting ? 'w-1/2' : 'w-1/3'" v-if="!empty(contextAccount.children)">
                            <label>{{ $t('users.edit.accountInfos.childAccount') }}</label>
                            <activix-multiselect
                                label="name"
                                multiple="multiple"
                                :options="contextAccount.children"
                                :selected="currentForm.child_accounts"
                                :searchable="true"
                                :close-on-select="false"
                                :disabled="
                                    !authUser.isAdmin() &&
                                        !authUser.isDirector() &&
                                        !authUser.isBdcDirector() &&
                                        !authUser.can_create_child
                                "
                                :taggable="true"
                                @update="updateChildAccounts"
                            />
                        </div>
                        <div class="px-4 py-2" :class="hasSuppliersUserSetting ? 'w-1/2' : 'w-1/3'" v-if="relatedData.teams && relatedData.teams.length">
                            <label>{{ $t('users.edit.team') }}</label>
                            <activix-multiselect
                                label="number"
                                clearable
                                :options="orderedTeams"
                                :selected="currentForm.team"
                                :searchable="false"
                                :close-on-select="true"
                                :disabled="authUser.hasAdvisorSkills()"
                                @update="updateTeam"
                            />
                        </div>
                        <div class="px-4 py-2" :class="hasSuppliersUserSetting ? 'w-1/2' : 'w-1/3'">
                            <label>{{ $t('users.edit.ccCommInbound') }}</label>
                            <activix-tooltip :content="$t('users.edit.ccCommInboundDescription')">
                                <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                            </activix-tooltip>
                            <activix-multiselect
                                :options="orderedUsers"
                                :selected="selectedUserInactive"
                                :searchable="false"
                                :clearable="currentForm.active"
                                :custom-label="formatUser"
                                :disabled="!authUser.isAdmin() && !authUser.isDirector()"
                                @update="updateTransferUser"
                            />
                        </div>
                        <div class="px-4 py-2" :class="hasSuppliersUserSetting ? 'w-1/2' : 'w-1/3'" v-if="authUser.isAdmin()">
                            <label>{{ $t('users.edit.supplier') }}</label>
                            <activix-multiselect
                                :options="orderedSuppliers"
                                :selected="currentForm.supplier"
                                :searchable="true"
                                :clearable="true"
                                :close-on-select="true"
                                :disabled="false"
                                @update="updateSupplier"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="box | mb-6">
            <div class="box-header | border-b">
                <h4 class="box-title">
                    {{ $t('users.edit.modulesAndOptions') }}
                </h4>
            </div>
            <div class="box-body">
                <div class="row">
                    <div
                        class="col-xs-12 col-sm-6 col-md-3"
                        v-if="
                            contextAccount.hasOption('leadxpress') ||
                                contextAccount.hasOption('webboost') ||
                                contextAccount.hasOption('walk_in') ||
                                contextAccount.hasOption('phone_up')
                        "
                    >
                        <div>
                            <activix-tooltip :content="disabledTooltip('crmMax')">
                                <activix-checkbox
                                    class="mb-4"
                                    :value="crmCheckbox"
                                    :disabled="
                                        (!authUser.isAdmin() && !authUser.isDirector()) ||
                                            (crmCheckbox && hasWebOrderAccess)
                                    "
                                    @input="$emit('update:crmCheckbox', $event)"
                                >
                                    <span class="font-semibold" slot="before">{{ $t('users.edit.crmMax') }}</span>
                                </activix-checkbox>
                            </activix-tooltip>
                        </div>
                        <div v-if="contextAccount.hasOption('leadxpress')">
                            <activix-tooltip :content="disabledTooltip('leadxpress')">
                                <activix-checkbox
                                    class="mb-4"
                                    :value="currentForm.module_access.leadxpress"
                                    :disabled="
                                        (!authUser.isAdmin() && !authUser.isDirector()) ||
                                            (hasWebOrderAccess && currentForm.module_access.leadxpress)
                                    "
                                    @input="updateModuleAccess('leadxpress', $event)"
                                >
                                    {{ $t('users.edit.leadXpress') }}
                                </activix-checkbox>
                            </activix-tooltip>
                        </div>
                        <div v-if="contextAccount.hasOption('webboost')">
                            <activix-checkbox
                                class="mb-4"
                                :value="currentForm.module_access.web_boost"
                                :disabled="!authUser.isAdmin() && !authUser.isDirector()"
                                @input="updateModuleAccess('web_boost', $event)"
                            >
                                {{ $t('users.edit.webBoost') }}
                            </activix-checkbox>
                        </div>
                        <div v-if="contextAccount.hasOption('walk_in')">
                            <activix-checkbox
                                class="mb-4"
                                :value="currentForm.module_access.walk_in"
                                :disabled="!authUser.isAdmin() && !authUser.isDirector()"
                                @input="updateModuleAccess('walk_in', $event)"
                            >
                                {{ $t('users.edit.walkIn') }}
                            </activix-checkbox>
                        </div>
                        <div v-if="contextAccount.hasOption('phone_up')">
                            <activix-checkbox
                                class="mb-4"
                                :value="currentForm.module_access.phone_up"
                                :disabled="!authUser.isAdmin() && !authUser.isDirector()"
                                @input="updateModuleAccess('phone_up', $event)"
                            >
                                {{ $t('users.edit.phoneUp') }}
                            </activix-checkbox>
                        </div>
                    </div>

                    <div
                        class="col-xs-12 col-sm-6 col-md-3"
                        v-if="
                            contextAccount.hasOption('web_order') ||
                                contextAccount.hasOption('loyalty') ||
                                contextAccount.hasOption('renewal') ||
                                contextAccount.hasOption('service') ||
                                contextAccount.hasOption('commercial') ||
                                contextAccount.hasOption('event') ||
                                contextAccount.hasOption('activity_report')
                        "
                    >
                        <div>
                            <activix-checkbox
                                class="mb-4"
                                :value="departmentCheckbox"
                                :disabled="(!authUser.isAdmin() && !authUser.isDirector()) || webOrderOptionDisabled"
                                @input="$emit('update:departmentCheckbox', $event)"
                            >
                                <span class="font-semibold" slot="before">{{ $t('users.edit.departmentMoney') }}</span>
                            </activix-checkbox>
                        </div>
                        <div v-if="contextAccount.hasOption('web_order')">
                            <activix-tooltip :content="disabledTooltip('web_order')">
                                <activix-checkbox
                                    class="mb-4"
                                    :value="currentForm.module_access.web_order"
                                    :disabled="webOrderOptionDisabled"
                                    @input="updateModuleAccess('web_order', $event)"
                                >
                                    {{ $t('users.edit.webOrder') }}
                                </activix-checkbox>
                            </activix-tooltip>
                        </div>
                        <div v-if="contextAccount.hasOption('loyalty')">
                            <activix-checkbox
                                class="mb-4"
                                :value="currentForm.module_access.loyalty"
                                :disabled="!authUser.isAdmin() && !authUser.isDirector()"
                                @input="updateModuleAccess('loyalty', $event)"
                            >
                                {{ $t('users.edit.loyalty') }}
                            </activix-checkbox>
                        </div>
                        <div v-if="contextAccount.hasOption('commercial')">
                            <activix-checkbox
                                class="mb-4"
                                :value="currentForm.module_access.commercial"
                                :disabled="!authUser.isAdmin() && !authUser.isDirector()"
                                @input="updateModuleAccess('commercial', $event)"
                            >
                                {{ $t('users.edit.commercialDirector') }}
                            </activix-checkbox>
                        </div>
                        <div v-if="contextAccount.hasOption('renewal')">
                            <activix-checkbox
                                class="mb-4"
                                :value="currentForm.module_access.renewal"
                                :disabled="!authUser.isAdmin() && !authUser.isDirector()"
                                @input="updateModuleAccess('renewal', $event)"
                            >
                                {{ $t('users.edit.renewal') }}
                            </activix-checkbox>
                        </div>
                        <div v-if="contextAccount.hasOption('service')">
                            <activix-checkbox
                                class="mb-4"
                                :value="currentForm.module_access.service"
                                :disabled="!authUser.isAdmin() && !authUser.isDirector()"
                                @input="updateModuleAccess('service', $event)"
                            >
                                {{ $t('users.edit.service') }}
                            </activix-checkbox>
                        </div>
                        <div v-if="contextAccount.hasOption('event')">
                            <activix-checkbox
                                class="mb-4"
                                :value="currentForm.module_access.event"
                                :disabled="!authUser.isAdmin() && !authUser.isDirector()"
                                @input="updateModuleAccess('event', $event)"
                            >
                                {{ $t('users.edit.event') }}
                            </activix-checkbox>
                        </div>
                        <div v-if="contextAccount.hasOption('activity_report')">
                            <activix-checkbox
                                class="mb-4"
                                :value="currentForm.module_access.activity_report"
                                :disabled="!authUser.isAdmin() && !authUser.isDirector()"
                                @input="updateModuleAccess('activity_report', $event)"
                            >
                                {{ $t('users.edit.activityReport') }}
                            </activix-checkbox>
                        </div>
                    </div>

                    <div class="col-xs-12 col-md-6">
                        <div class="mb-4">
                            <span class="font-semibold">{{ $t('users.edit.optionsMoney') }}</span>
                        </div>

                        <div class="flex flex-wrap -mx-3">
                            <div class="w-1/3 px-3" v-if="contextAccount.hasOption('sale_table')">
                                <activix-checkbox
                                    class="mb-4"
                                    :value="currentForm.module_access.sale_table"
                                    :disabled="!authUser.isAdmin() && !authUser.isDirector()"
                                    @input="updateModuleAccess('sale_table', $event)"
                                >
                                    {{ $t('users.edit.saleTable') }}
                                </activix-checkbox>
                            </div>
                            <div
                                class="w-1/3 px-3"
                                v-if="
                                    !empty(currentForm.role) &&
                                        Role.hasDirectorRights(currentForm.role.id) &&
                                        (authUser.isAdmin() || authUser.isDirector())
                                "
                            >
                                <activix-checkbox
                                    class="mb-4"
                                    :value="currentForm.custom_permissions.delete_lead"
                                    :disabled="!authUser.isAdmin()"
                                    @input="updateCustomPermission('delete_lead', $event)"
                                >
                                    {{ $t('users.edit.leadDeletion') }}
                                </activix-checkbox>
                            </div>
                            <div class="w-1/3 px-3">
                                <activix-tooltip :content="$t('users.edit.nextStepTooltip')">
                                    <activix-checkbox
                                        class="mb-4"
                                        :value="currentForm.next_step"
                                        :disabled="!authUser.isAdmin() && !authUser.isDirector()"
                                        @input="updateUserProperties({ next_step: $event })"
                                    >
                                        {{ $t('users.edit.nextStep') }}
                                    </activix-checkbox>
                                </activix-tooltip>
                            </div>
                            <div class="w-1/3 px-3" v-if="authUser.isAdmin() || authUser.isDirector()">
                                <activix-checkbox
                                    class="mb-4"
                                    :value="currentForm.block_lead_info"
                                    @input="updateUserProperties({ block_lead_info: $event })"
                                >
                                    {{ $t('users.edit.hideNumber') }}
                                </activix-checkbox>
                            </div>
                            <div class="w-1/3 px-3">
                                <activix-checkbox
                                    class="mb-4"
                                    :value="currentForm.mass_mailing"
                                    :disabled="!authUser.isAdmin() && !authUser.isDirector()"
                                    @input="updateUserProperties({ mass_mailing: $event })"
                                >
                                    {{ $t('options.massMailing') }}
                                </activix-checkbox>
                            </div>
                            <div class="w-1/3 px-3">
                                <activix-tooltip :content="$t('users.edit.nextStepMandatoryTooltip')">
                                    <activix-checkbox
                                        class="mb-4"
                                        :value="currentForm.next_step_mandatory"
                                        :disabled="
                                            (!authUser.isAdmin() && !authUser.isDirector()) || !currentForm.next_step
                                        "
                                        @input="
                                            updateUserProperties({ next_step_mandatory: $event })
                                        "
                                    >
                                        {{ $t('users.edit.nextStepMandatory') }}
                                    </activix-checkbox>
                                </activix-tooltip>
                            </div>
                            <div class="w-1/3 px-3" v-if="contextAccount.niotext && !contextAccount.account_manager">
                                <activix-checkbox
                                    class="mb-4"
                                    :disabled="!authUser.isAdmin() && !authUser.isDirector()"
                                    :value="currentForm.niotext"
                                    @input="updateUserProperties({ niotext: $event })"
                                >
                                    {{ $t('users.edit.nioText') }}
                                </activix-checkbox>
                            </div>
                            <div class="w-1/3 px-3">
                                <activix-checkbox
                                    class="mb-4"
                                    :value="currentForm.custom_permissions.merge_lead"
                                    :disabled="!authUser.isAdmin() && !authUser.isDirector()"
                                    @input="updateCustomPermission('merge_lead', $event)"
                                >
                                    {{ $t('users.edit.leadMerging') }}
                                </activix-checkbox>
                            </div>
                            <div
                                class="w-1/3 px-3"
                                v-if="contextAccount.commercial && (authUser.isDirector() || authUser.isAdmin())"
                            >
                                <activix-checkbox
                                    class="mb-4"
                                    :value="currentForm.freeze_financial_data"
                                    @input="updateUserProperties({ freeze_financial_data: $event })"
                                >
                                    {{ $t('users.edit.freezeData') }}
                                    <activix-tooltip :content="$t('users.edit.freezeFinancialDataTooltip')">
                                        <icon class="text-grey-500 text-sm" name="regular/information-circle" />
                                    </activix-tooltip>
                                </activix-checkbox>
                            </div>
                            <div class="w-1/3 px-3">
                                <activix-checkbox
                                    class="mb-4"
                                    :value="currentForm.custom_permissions.limit_audio_access"
                                    :disabled="!canEditAudioAccess"
                                    @input="updateCustomPermission('limit_audio_access', $event)"
                                >
                                    {{ $t('users.edit.limitAudioAccess') }}
                                    <activix-tooltip :content="$t('users.edit.limitAudioAccessDesc')">
                                        <icon class="text-grey-500 text-sm" name="regular/information-circle" />
                                    </activix-tooltip>
                                </activix-checkbox>
                            </div>
                            <div class="w-1/3 px-3">
                                <activix-checkbox
                                    class="mb-4"
                                    :value="currentForm.hide_fi_profits"
                                    :disabled="disableHideFIProfits"
                                    @input="updateUserProperties({ hide_fi_profits: $event })"
                                >
                                    {{ $t('users.edit.hideFIProfits') }}
                                </activix-checkbox>
                            </div>
                            <div class="w-1/3 px-3" v-if="contextAccount.in_turn">
                                <activix-checkbox
                                    class="mb-4"
                                    :value="currentForm.in_turn_edit"
                                    :disabled="!authUser.isAdmin() && !authUser.isDirector()"
                                    @input="updateUserProperties({ in_turn_edit: $event })"
                                >
                                    {{ $t('users.edit.inTurnEdit') }}
                                    <activix-tooltip :content="$t('users.edit.inTurnEditTooltip')">
                                        <icon class="text-grey-500 text-sm" name="regular/information-circle" />
                                    </activix-tooltip>
                                </activix-checkbox>
                            </div>
                            <div class="w-1/3 px-3" v-if="hasAccessToModule('event')">
                                <activix-checkbox
                                    class="mb-4"
                                    :value="currentForm.allow_event_creation"
                                    :disabled="!authUser.isAdmin() && !authUser.isDirector()"
                                    @input="updateUserProperties({ allow_event_creation: $event })"
                                >
                                    {{ $t('users.edit.allowEventCreation') }}
                                </activix-checkbox>
                            </div>
                            <div class="w-1/3 px-3" v-if="showImportAccessOption">
                                <activix-checkbox
                                    class="mb-4"
                                    :value="currentForm.allow_import_access"
                                    @input="updateUserProperties({ allow_import_access: $event })"
                                >
                                    {{ $t('users.edit.allowImportAccess') }}
                                </activix-checkbox>
                            </div>
                            <div class="w-1/3 px-3" v-if="showExportAccessOption">
                                <activix-checkbox
                                    class="mb-4"
                                    :value="currentForm.allow_export_access"
                                    @input="updateUserProperties({ allow_export_access: $event })"
                                >
                                    {{ $t('users.edit.allowExportAccess') }}
                                </activix-checkbox>
                            </div>
                            <div class="w-1/3 px-3" v-if="showAutomationAccessOption">
                                <activix-checkbox
                                    class="mb-4"
                                    :value="currentForm.automation_access"
                                    @input="updateUserProperties({ automation_access: $event })"
                                >
                                    {{ $t('users.edit.allowAutomationAccess') }}
                                </activix-checkbox>
                            </div>
                            <div class="w-1/3 px-3" v-if="authUser.isAdmin() || authUser.isDirector()">
                                <activix-checkbox
                                    class="mb-4"
                                    :value="currentForm.end_contract_required"
                                    @input="updateUserProperties({ end_contract_required: $event })"
                                >
                                    {{ $t('users.edit.endContractRequired') }}
                                    <activix-tooltip :content="$t('users.edit.endContractRequiredTooltip')">
                                        <icon class="text-grey-500 text-sm" name="regular/information-circle" />
                                    </activix-tooltip>
                                </activix-checkbox>
                            </div>
                            <div class="w-1/3 px-3" v-if="showBellEventOption">
                                <activix-checkbox
                                    class="mb-4"
                                    :value="currentForm.bell_event_option"
                                    @input="updateUserProperties({ bell_event_option: $event })"
                                >
                                    {{ $t('users.edit.bellEventOption') }}
                                    <activix-tooltip :content="$t('users.edit.bellEventOptionTooltip')">
                                        <icon class="text-grey-500 text-sm" name="regular/information-circle" />
                                    </activix-tooltip>
                                </activix-checkbox>
                            </div>
                            <div class="w-1/3 px-3" v-if="showAutomationAdvancedAccessOption">
                                <activix-checkbox
                                    class="mb-4"
                                    :value="currentForm.automation_advanced_access"
                                    @input="
                                        updateUserProperties({ automation_advanced_access: $event })
                                    "
                                >
                                    {{ $t('users.edit.allowAdvancedAutomationAccess') }}
                                </activix-checkbox>
                            </div>
                            <div
                                class="w-1/3 px-3"
                                v-if="
                                    contextAccount.account_manager &&
                                        !empty(currentForm.role) &&
                                        Role.hasAdvisorSkills(currentForm.role.id)
                                "
                            >
                                <activix-checkbox
                                    class="mb-4"
                                    :value="currentForm.can_create_child"
                                    :disabled="
                                        !authUser.isAdmin() && !authUser.isDirector() && !authUser.isBdcDirector()
                                    "
                                    @input="updateUserProperties({ can_create_child: $event })"
                                >
                                    {{ $t('users.edit.options.canCreateChild') }}
                                </activix-checkbox>
                            </div>
                            <div
                                class="w-1/3 px-3"
                                v-if="!empty(currentForm.role) && currentForm.role.id == Role.AGENT"
                            >
                                <activix-checkbox
                                    class="mb-4"
                                    :value="currentForm.bdc_super_access"
                                    :disabled="
                                        !authUser.isAdmin() && !authUser.isDirector() && !authUser.isBdcDirector()
                                    "
                                    @input="updateUserProperties({ bdc_super_access: $event })"
                                >
                                    {{ $t('users.edit.bdcSuperAccess') }}
                                    <activix-tooltip :content="$t('users.edit.bdcSuperAccessTooltip')">
                                        <icon class="text-grey-500 text-sm" name="regular/information-circle" />
                                    </activix-tooltip>
                                </activix-checkbox>
                            </div>
                            <div
                                class="w-1/3 px-3"
                                :disabled="
                                    !authUser.isAdmin() && !authUser.isDirector() && !authUser.isBdcDirector()
                                "
                            >
                                <activix-checkbox
                                    class="mb-4"
                                    :value="currentForm.credit"
                                    @input="updateUserProperties({ credit: $event })"
                                >
                                    {{ $t('users.edit.credit') }}
                                </activix-checkbox>
                            </div>
                            <div class="w-1/3 px-3" v-if="contextAccount.trade_report">
                                <activix-checkbox
                                    class="mb-4"
                                    :value="currentForm.trade_report"
                                    :disabled="!authUser.isAdmin() && !authUser.isDirector()"
                                    @input="updateUserProperties({ trade_report: $event })"
                                >
                                    {{ $t('users.edit.tradeReport') }}
                                    <activix-tooltip :content="$t('users.edit.tradeReportTooltip')">
                                        <icon class="text-grey-500 text-sm" name="regular/information-circle" />
                                    </activix-tooltip>
                                </activix-checkbox>
                            </div>
                            <div class="w-1/3 px-3" v-if="showAccessRenewalDirector">
                                <activix-checkbox
                                    class="mb-4"
                                    :value="currentForm.director_access_renewal"
                                    :disabled="!authUser.isAdmin() && !authUser.isDirector()"
                                    @input="
                                        updateUserProperties({ director_access_renewal: $event })
                                    "
                                >
                                    {{ $t('users.edit.accessRenewalDirector') }}
                                    <activix-tooltip :content="$t('users.edit.accessRenewalDirectorTooltip')">
                                        <icon class="text-grey-500 text-sm" name="regular/information-circle" />
                                    </activix-tooltip>
                                </activix-checkbox>
                            </div>
                            <div class="w-1/3 px-3" v-show="showTaskAccessField">
                                <activix-checkbox
                                    class="mb-4"
                                    :value="currentForm.custom_permissions.limit_calendar_task_access"
                                    :disabled="disableTaskAccessField"
                                    @input="updateCustomPermission('limit_calendar_task_access', $event)"
                                >
                                    {{ $t('users.edit.limitCalendarTaskAccess') }}
                                    <activix-tooltip :content="$t('users.edit.limitCalendarTaskAccessTooltip')">
                                        <icon class="text-grey-500 text-sm" name="regular/information-circle" />
                                    </activix-tooltip>
                                </activix-checkbox>
                            </div>
                            <div class="w-1/3 px-3" v-if="showCalculatorValueVisible">
                                <activix-checkbox
                                    class="mb-4"
                                    :value="currentForm.calculator_value_visible"
                                    :disabled="calculatorValueVisibleDisabled"
                                    @input="
                                        updateUserProperties({ calculator_value_visible: $event })
                                    "
                                >
                                    {{ $t('users.edit.showEstimatevalue') }}
                                    <activix-tooltip :content="$t('users.edit.showEstimatevalueTooltip')">
                                        <icon class="text-grey-500 text-sm" name="regular/information-circle" />
                                    </activix-tooltip>
                                </activix-checkbox>
                            </div>
                            <div class="w-1/3 px-3" v-if="!empty(currentForm.parent_user_id) || !empty(contextAccount.children)">
                                <activix-tooltip :content="hasParentUserTooltip">
                                    <activix-checkbox
                                        class="mb-4"
                                        :value="currentForm.hide_in_user_select"
                                        :disabled="hideInUserSelectValueDisabled"
                                        @input="updateUserProperties({ hide_in_user_select: $event})"
                                    >
                                        {{ $t('users.edit.hideInUserSelect') }}
                                    </activix-checkbox>
                                </activix-tooltip>
                            </div>
                            <div class="w-1/3 px-3" v-if="currentForm.role_id == Role.DIRECTOR || currentForm.role_id == Role.DIRECTOR_BDC">
                                <activix-tooltip :content="hasParentUserTooltip">

                                    <activix-checkbox
                                        class="mb-4"
                                        :value="currentForm.no_reassignation_notification"
                                        :disabled="noReassignationNotificationValueDisabled"
                                        @input="updateUserProperties({ no_reassignation_notification: $event})"
                                    >
                                        {{ $t('users.edit.noReassignationNotification') }}
                                    </activix-checkbox>
                                </activix-tooltip>
                            </div>
                            <div class="w-1/3 px-3" v-if="!empty(currentForm.role) && (currentForm.role.id == Role.SERVICE_AGENT || currentForm.role.id == Role.SERVICE_ADVISOR)">
                                <activix-checkbox
                                    class="mb-4"
                                    :value="currentForm.service_super_access"
                                    :disabled="
                                        !authUser.isAdmin() && !authUser.isDirector() && !authUser.isServiceDirector()
                                    "
                                    @input="updateUserProperties({ service_super_access: $event })"
                                >
                                    {{ $t('users.edit.serviceSuperAccess') }}
                                    <activix-tooltip :content="$t('users.edit.serviceSuperAccessTooltip')">
                                        <icon class="text-grey-500 text-sm" name="regular/information-circle" />
                                    </activix-tooltip>
                                </activix-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <activix-confirm-modal
            type="info"
            :content="$t('users.edit.phoneProviderWarning')"
            :opened.sync="phoneProviderWarning"
            @approve="toggleUserIsActive"
        />

        <transfer-user
            :opened="transferDataOpened"
            :related-data="relatedData"
            @closed="transferDataOpened=false"
        />
    </div>
</template>

<script>
    /* eslint-disable vue/require-prop-types */
    import { orderBy, cloneDeep, get } from 'lodash-es';
    import SuppliersUser from '@/components/users/SuppliersUser.vue';
    import { mapActions, mapState } from 'pinia';
    import PhoneNumber from './PhoneNumber.vue';
    import Account from '../../entities/Account.js';
    import Role from '../../entities/Role.js';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';
    import { useUserCardStore } from '../../store/modules/userCard/store.js';
    import TransferUser from '../modals/TransferUser.vue';

    const defaultPhone = {
        phone: '',
        extension: '',
        timeout_ringing: 18,
        timeout_keypress: 10,
        cellphone: false,
        preferred: false,
        press_to_talk: true,
        preferred_service: {
            phone_up: true,
            web_boost: true,
        },
    };

    Object.freeze(defaultPhone);

    export default {
        components: {
            TransferUser,
            SuppliersUser,
            PhoneNumber,
        },

        props: ['relatedData', 'crmCheckbox', 'departmentCheckbox', 'imgResolutionToLarge'],

        data() {
            return {
                defaultPhone,
                profilePictureData: '',
                profilePicturePopoverVisible: false,
                Role,
                phoneProviderWarning: false,
                disableUserWarning: false,
                transferDataOpened: false,
            };
        },

        computed: {
            ...mapState(useUserCardStore, {
                currentForm: 'currentForm',
                preferenceOptions: store => store.currentForm.options,
            }),
            ...mapState(useGlobalStore, ['authUser', 'timezones', 'configs']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
                contextUser: 'user',
            }),

            userHasPhoneProvider() {
                return this.contextAccount.phone_providers.some(phone => phone.user_id == this.currentForm.id);
            },

            genderOptions() {
                return [
                    {
                        label: this.$t('database.male'),
                        value: 'M',
                    },
                    {
                        label: this.$t('database.female'),
                        value: 'W',
                    },
                ];
            },

            langOptions() {
                return [
                    {
                        label: this.$t('database.fr'),
                        value: 'fr',
                    },
                    {
                        label: this.$t('database.en'),
                        value: 'en',
                    },
                ];
            },

            hasSuppliersUserSetting() {
                return this.contextAccount.hasSuppliersUserSetting() && !this.currentForm.child_accounts.length;
            },

            hasLimitedRenewalAccess() {
                return (
                    !this.currentForm.role ||
                    [Role.ADVISOR, Role.AGENT, Role.COMMERCIAL].includes(this.currentForm.role.id)
                );
            },

            hasWebOrderAccess() {
                return this.currentForm.module_access.web_order && this.contextAccount.web_order;
            },

            profilePictureSrc() {
                return this.profilePictureData || this.currentForm.profilePicturePath || '/img/nobody.jpg';
            },

            hasParentUserTooltip() {
                return empty(this.currentForm.parent_user_id) ? '' : this.$t('users.edit.hasParentUser');
            },

            mayUpdateTimezone() {
                return [Account.ACTIVIX, Account.DEMO, Account.AUTO_ONE_GROUP].includes(this.contextAccount.id);
            },

            passwordDisabled() {
                return !empty(this.currentForm.parent_user_id) ||
                    (
                        this.currentForm.role_id &&
                        this.authUser.id != this.$route.params.id &&
                        (
                            (!this.authUser.isProgrammer() && this.authUser.role_id >= this.currentForm.role_id) ||
                            (this.authUser.isProgrammer() && this.authUser.role_id == this.currentForm.role_id)
                        )
                    );
            },

            roleDisabled() {
                return (
                    this.authUser.id == this.$route.params.id ||
                    (this.currentForm.role.id === Role.PROGRAMMER && !this.authUser.isProgrammer()) ||
                    !empty(this.currentForm.parent_user_id) ||
                    (!this.authUser.isAdmin() && !this.authUser.isDirector() && !this.authUser.isBdcDirector())
                );
            },

            defaultDashboardDisabled() {
                return this.currentForm.divisions.length == 1 && this.currentForm.divisions[0].name == 'service';
            },

            disableHideFIProfits() {
                if (!this.authUser.isAdmin() && !this.authUser.isDirector()) {
                    return true;
                }

                return (
                    !empty(this.currentForm.role) &&
                    (Role.hasAdminRights(this.currentForm.role.id) ||
                        [Role.DIRECTOR, Role.COMMERCIAL].includes(this.currentForm.role.id))
                );
            },

            canEditAudioAccess() {
                return (
                    !empty(this.currentForm.role) &&
                    (((this.authUser.isAdmin() || this.authUser.isDirector()) &&
                        Role.hasAdvisorSkills(this.currentForm.role.id)) ||
                        (this.authUser.isAdmin() &&
                            [Role.DIRECTOR, Role.DIRECTOR_BDC].includes(this.currentForm.role.id)))
                );
            },

            postOptions() {
                if (empty(this.currentForm.role)) {
                    return [];
                }
                return this.relatedData.posts
                    .filter(post => {
                        return (
                            this.currentForm.sex == post.sex &&
                            this.currentForm.role.id == post.role_id &&
                            (post.locale_specific == this.contextAccount.locale || post.locale_specific == null)
                        );
                    })
                    .sort((a, b) => {
                        const translateA = this.$t(`database.post_${a.name}`);
                        const translateB = this.$t(`database.post_${b.name}`);

                        return translateA.localeCompare(translateB);
                    });
            },

            dashboardOptions() {
                if (empty(this.relatedData.dashboards)) {
                    return [];
                }
                const optionsEquivalences = {
                    'dashboards.allLead': {
                        module: 'allLead',
                        translation: 'allLead',
                    },
                    'dashboards.webOrder': {
                        module: 'web_order',
                        translation: 'webOrder',
                    },
                    'dashboards.leadXpress': {
                        module: 'leadxpress',
                        translation: 'leadXpress',
                    },
                    'dashboards.loyalty': {
                        module: 'loyalty',
                        translation: 'loyalty',
                    },
                    'dashboards.renewal': {
                        module: 'renewal',
                        translation: 'renewal',
                    },
                    'dashboards.webBoost': {
                        module: 'web_boost',
                        option: 'webboost',
                        translation: 'webBoost',
                    },
                    'dashboards.phoneUp': {
                        module: 'phone_up',
                        translation: 'phoneUp',
                    },
                    'dashboards.walkIn': {
                        module: 'walk_in',
                        translation: 'walkIn',
                    },
                    'dashboards.saleTable': {
                        module: 'sale_table',
                        translation: 'saleTable',
                    },
                    'dashboards.event': {
                        module: 'event',
                        translation: 'event',
                    },
                    'dashboards.service': {
                        module: 'service',
                        translation: 'service',
                    },
                    calendar: {
                        module: 'calendar_options',
                        translation: 'calendar_options',
                    },
                    'inTurn.management': {
                        module: 'in_turn',
                        translation: 'inTurn',
                    },
                };

                const availableDashboards = this.relatedData.dashboards.filter(dashboard => {
                    let moduleName = dashboard;
                    let moduleOption = dashboard;
                    if (typeof optionsEquivalences[dashboard] !== 'undefined') {
                        moduleName = optionsEquivalences[dashboard].module;
                        moduleOption = optionsEquivalences[dashboard].module;
                        if (typeof optionsEquivalences[dashboard].option !== 'undefined') {
                            moduleOption = optionsEquivalences[dashboard].option;
                        }
                    }

                    return (
                        moduleName === 'allLead' ||
                        (['in_turn', 'calendar_options'].includes(moduleName) &&
                            this.contextAccount.hasOption(moduleOption)) ||
                        (this.currentForm.module_access[moduleName] &&
                            this.contextAccount.hasOption(moduleOption))
                    );
                });

                return availableDashboards.map(dashboard => {
                    let moduleTranslation = dashboard;
                    if (typeof optionsEquivalences[dashboard] !== 'undefined') {
                        moduleTranslation = optionsEquivalences[dashboard].translation;
                    }

                    return {
                        label: this.$t(`users.edit.${moduleTranslation}`),
                        value: dashboard,
                    };
                });
            },

            selectedGender() {
                return this.genderOptions.find(gender => gender.value == this.currentForm.sex);
            },

            selectedLang() {
                return this.langOptions.find(lang => lang.value == this.currentForm.locale);
            },

            selectedDashboard() {
                return this.dashboardOptions.find(dashboard => dashboard.value == this.currentForm.default_dashboard);
            },

            selectedUserInactive() {
                return this.relatedData.users.find(user => user.id == this.currentForm.director_inactive);
            },

            selectedTimezone() {
                return this.timezones.find(timezone => timezone.value == this.currentForm.timezone) || [];
            },

            hasService() {
                let hasService = false;

                if (this.contextAccount.service) {
                    hasService = true;
                }

                if (!hasService) {
                    const moduleAccessRights = this.currentForm.module_access;
                    moduleAccessRights.service = false;
                    useUserCardStore().currentForm.module_access = moduleAccessRights;
                }

                return hasService;
            },

            orderedDivisions() {
                return orderBy(this.relatedData.divisions, 'id');
            },

            orderedDashboards() {
                return orderBy(this.dashboardOptions, 'label');
            },

            orderedRoles() {
                const roles = this.relatedData.roles.filter(role => {
                    return this.roleIsAvailable(role);
                });

                return orderBy(roles, 'name');
            },

            orderedGroups() {
                const orderedGroup = orderBy(this.relatedData.groups, 'name');

                return orderedGroup.map(group => {
                    return {
                        id: group.id,
                        label: group.name,
                    };
                });
            },

            orderedTeams() {
                return orderBy(this.relatedData.teams, 'number');
            },

            orderedSuppliers() {
                const orderedSupplier = orderBy(this.relatedData.suppliers, 'name');

                return orderedSupplier.map(supplier => {
                    return {
                        id: supplier.id,
                        label: supplier.name,
                    };
                });
            },

            orderedUsers() {
                const relatedUsers = this.relatedData.users.filter(user => {
                    return user.id != this.currentForm.id;
                });
                return orderBy(relatedUsers, 'first_name');
            },

            showImportAccessOption() {
                return (
                    this.authUser.isAdmin() &&
                    !empty(this.currentForm.role) &&
                    (Role.hasAdminRights(this.currentForm.role.id) ||
                        [Role.DIRECTOR, Role.DIRECTOR_BDC].includes(this.currentForm.role.id))
                );
            },

            showExportAccessOption() {
                return this.authUser.isAdmin();
            },

            showAutomationAccessOption() {
                return get(this.contextAccount, 'automation', false) && this.authUser.isAdmin();
            },

            showAutomationAdvancedAccessOption() {
                return (
                    get(this.contextAccount, 'automation', false) &&
                    get(this.currentForm, 'automation_access', false) &&
                    this.authUser.isAdmin()
                );
            },

            showBellEventOption() {
                return this.authUser.isAdmin() || this.authUser.isDirector() || this.authUser.isBdcDirector();
            },

            hasAccessToGroup() {
                return this.authUser.isProgrammer();
            },

            disableTaskAccessField() {
                return !this.authUser.isAdmin() && !this.authUser.isDirector() && !this.authUser.isBdcDirector();
            },

            showTaskAccessField() {
                return this.currentForm.role && (Role.hasAdvisorSkills(this.currentForm.role.id) ||
                    this.currentForm.role.id === Role.SERVICE_AGENT || this.currentForm.role.id === Role.SERVICE_ADVISOR);
            },

            showCalculatorValueVisible() {
                return !empty(this.currentForm.role) && [Role.ADVISOR, Role.ANALYST, Role.AGENT].includes(this.currentForm.role.id);
            },

            calculatorValueVisibleDisabled() {
                return !this.authUser.isAdmin() && !this.authUser.isDirector() && !this.authUser.isBdcDirector();
            },
            hideInUserSelectValueDisabled() {
                return !empty(this.currentForm.parent_user_id) || !this.authUser.isAdmin() && !this.authUser.isDirector();
            },
            noReassignationNotificationValueDisabled() {
                return !empty(this.currentForm.parent_user_id) || !this.authUser.isAdmin() && !this.authUser.isDirector();
            },
            showAccessRenewalDirector() {
                return this.currentForm.module_access.renewal && this.hasLimitedRenewalAccess;
            },

            webOrderOptionDisabled() {
                return (
                    (!this.authUser.isAdmin() && !this.authUser.isDirector()) ||
                    !this.currentForm.module_access.leadxpress
                );
            },

            optimizelyUser() {
                return {
                    id: this.currentForm.id,
                    account_id: this.currentForm.account.id,
                    role_id: this.currentForm.role && this.currentForm.role.id,
                };
            },

            userActiveTooltip() {
                return this.contextUser.api_user ? this.$t('accounts.edit.activeDisabledApiUser') : this.$t('accounts.edit.activeInactive');
            },
        },

        watch: {
            profilePictureSrc() {
                $(this.$refs.profile_picture_img).width('auto').height(150);
            },

            profilePicturePopoverVisible() {
                if (
                    this.profilePicturePopoverVisible &&
                    (!this.currentForm.profile_picture || !this.currentForm.profilePicturePath)
                ) {
                    this.uploadPicture();
                }
            },

            'currentForm.next_step'(newValue) {
                if (!newValue) {
                    this.updateUserProperties({ next_step_mandatory: false });
                }
            },

            'currentForm.role'(newValue, oldValue) {
                if (
                    !empty(newValue) &&
                    (Role.hasAdminRights(newValue.id) || [Role.DIRECTOR, Role.COMMERCIAL].includes(newValue.id))
                ) {
                    this.currentForm.hide_fi_profits = false;
                    this.updateUserProperties({
                        hide_fi_profits: false,
                    });
                }

                if (!empty(newValue) && ![Role.DIRECTOR, Role.DIRECTOR_BDC].includes(newValue.id)) {
                    this.currentForm.custom_permissions.delete_lead = false;
                }

                if (!empty(newValue) && [Role.ADVISOR, Role.AGENT].includes(newValue.id)) {
                    this.currentForm.custom_permissions.limit_calendar_task_access = true;
                }

                if (!empty(newValue) && !empty(oldValue) && ![Role.ADVISOR, Role.AGENT].includes(newValue.id)) {
                    this.currentForm.custom_permissions.limit_calendar_task_access = false;
                }
            },

            'currentForm.divisions'(divisions) {
                if (divisions.length == 1 && divisions[0].name == 'service') {
                    this.updateUserProperties({
                        default_dashboard: 'dashboards.service',
                    });
                }

                if (
                    this.currentForm.role &&
                    ['service_advisor', 'service_agent'].includes(this.currentForm.role.name) &&
                    !this.currentForm.divisions.some(d => d.name == 'service')
                ) {
                    this.updateUserProperties({
                        role: '',
                    });
                }
            },

            'currentForm.module_access.service'(newValue) {
                if (!newValue) {
                    this.syncService(false);
                }
            },

            'contextAccount.locale'(newValue) {
                if (this.$route.name == 'users.store') {
                    this.updateUserProperties({
                        locale: newValue,
                    });
                }
            },

            'contextAccount.niotext'(newValue) {
                if (this.$route.name == 'users.store') {
                    this.updateUserProperties({
                        niotext: newValue,
                    });
                }
            },

            'contextAccount.sale_table'(newValue) {
                if (this.$route.name == 'users.store') {
                    const moduleAccessRights = this.currentForm.module_access;
                    moduleAccessRights.sale_table = newValue;

                    useUserCardStore().currentForm.module_access = moduleAccessRights;
                }
            },

            'currentForm.module_access.event'(newValue) {
                if (newValue) {
                    return;
                }

                this.updateUserProperties({ allow_event_creation: false });
            },
        },

        methods: {
            ...mapActions(useUserCardStore, ['updateUserProperties']),

            toggleActive() {
                if (this.userHasPhoneProvider && this.currentForm.active) {
                    this.phoneProviderWarning = true;
                } else {
                    if (this.currentForm.active && this.$feature.isEnabled('parent-and-childs-improvements')) {
                        this.transferDataOpened = true;
                    }

                    this.toggleUserIsActive();
                }
            },

            toggleUserIsActive() {
                this.updateUserProperties({ active: !this.currentForm.active });
            },

            roleIsAvailable(role) {
                if (!this.contextAccount.service && ['service_advisor', 'service_agent'].includes(role.name)) {
                    return false;
                }

                if (['programmer'].includes(role.name) && !this.authUser.isProgrammer() && this.currentForm.role.id !== Role.PROGRAMMER) {
                    return false;
                }

                if (
                    ['service_advisor', 'service_agent'].includes(role.name) &&
                    !this.currentForm.divisions.some(d => d.name == 'service')
                ) {
                    return false;
                }

                return true;
            },

            syncService(divisionEdited = true) {
                const divisionServiceEnabled = this.currentForm.divisions.some(division => division.name == 'service');

                if (divisionEdited) {
                    const moduleAccessRights = this.currentForm.module_access;
                    moduleAccessRights.service = true;

                    useUserCardStore().currentForm.module_access = moduleAccessRights;
                } else if (this.currentForm.divisions.length > 1 && divisionServiceEnabled) {
                    this.updateUserProperties(
                        this.currentForm.divisions.filter(division => {
                            return division.name != 'service';
                        }),
                    );
                }
            },

            deletePicture() {
                this.profilePicturePopoverVisible = false;

                this.updateUserProperties({
                    profile_picture: 'delete',
                    profilePicturePath: null,
                });
            },

            uploadPicture() {
                this.profilePicturePopoverVisible = false;

                $(this.$refs.profile_picture).click();
            },

            updatePicture(event) {
                if (event.target.files && event.target.files[0]) {
                    const reader = new FileReader();

                    reader.onload = e => {
                        this.profilePictureData = e.target.result;

                        const img = new Image();

                        img.src = window.URL.createObjectURL(event.target.files[0]);

                        img.onload = () => {
                            const width = img.naturalWidth;
                            const height = img.naturalHeight;

                            window.URL.revokeObjectURL(img.src);

                            this.$emit('update:imgResolutionToLarge', width > 2000 || height > 2000);
                        };
                    };

                    reader.readAsDataURL(event.target.files[0]);

                    this.updateUserProperties({
                        profile_picture: event.target.files[0],
                    });
                }
            },

            addPhone() {
                const phones = cloneDeep(this.currentForm.user_phones);

                phones.push(defaultPhone);

                this.updateUserProperties({ user_phones: phones });
            },

            deletePhoneAtIndex(index) {
                if (index < 0) {
                    return;
                }

                const phones = cloneDeep(this.currentForm.user_phones);

                phones.splice(index, 1);

                this.updateUserProperties({ user_phones: phones });
            },

            formatUser(user) {
                return `${user.first_name} ${user.last_name}`;
            },

            formatPost({ name }) {
                return this.$t(`database.post_${name}`);
            },

            formatRole({ name }) {
                return this.$t(`database.role_${name}`);
            },

            formatDivision({ name }) {
                if (empty(name)) {
                    return '';
                }

                return this.$t(`divisions.${name}`);
            },

            updateRole(value) {
                this.updateUserProperties({
                    role: value,
                    post: null,
                });
            },

            updateGender(gender) {
                this.updateUserProperties({
                    sex: gender.value,
                    post: null,
                });
            },

            updatePost(value) {
                this.updateUserProperties({ post: value });
            },

            updateLang(lang) {
                this.updateUserProperties({ locale: lang.value });
            },

            updateDivisions(divisions) {
                this.updateUserProperties({ divisions });
            },

            updateTimezone(newValue) {
                this.updateUserProperties({ timezone: newValue.value });
            },

            updateTransferUser(user) {
                this.updateUserProperties({ director_inactive: user?.id });
            },

            updateGroups(groups) {
                this.updateUserProperties({ groups });
            },

            updateChildAccounts(accounts) {
                this.updateUserProperties({ child_accounts: accounts });
            },

            updateTeam(value) {
                this.updateUserProperties({ team: value });
            },

            updateSupplier(value) {
                this.updateUserProperties({ supplier: value });
            },

            updateDefaultDashboard(dashboard) {
                this.updateUserProperties({ default_dashboard: dashboard.value });
            },

            updateModuleAccess(property, value) {
                const moduleAccessRights = this.currentForm.module_access;

                moduleAccessRights[property] = value;

                useUserCardStore().currentForm.module_access = moduleAccessRights;
            },

            updateCustomPermission(property, value) {
                const customPermissions = this.currentForm.custom_permissions;

                customPermissions[property] = value;

                useUserCardStore().currentForm.custom_permissions = customPermissions;
            },

            hasAccessToModule(module) {
                return this.contextAccount.hasOption(module) && this.currentForm.module_access[module];
            },

            disabledTooltip(option) {
                if (!this.authUser.isAdmin() && !this.authUser.isDirector()) {
                    return this.$t('users.edit.disabledTooltips.onlyDirectors');
                }

                switch (option) {
                    case 'crmMax':
                        if (this.crmCheckbox && this.hasWebOrderAccess) {
                            return this.$t('users.edit.disabledTooltips.mandatoryOptions');
                        }
                        break;
                    case 'leadxpress':
                        if (this.hasWebOrderAccess && this.currentForm.module_access.leadxpress) {
                            return this.$t('users.edit.disabledTooltips.webOrderRequiresIt');
                        }
                        break;
                    case 'web_boost':
                        if (this.hasWebOrderAccess && this.currentForm.module_access.web_boost) {
                            return this.$t('users.edit.disabledTooltips.webOrderRequiresIt');
                        }
                        break;
                    case 'web_order':
                        if (!this.currentForm.module_access.leadxpress) {
                            return this.$t('users.edit.disabledTooltips.webCrmRequired');
                        }
                        break;
                }

                return '';
            },
        },

        async mounted() {
            await this.$nextTick();

            if (this.$route.name == 'users.store') {
                this.updateUserProperties({
                    default_dashboard: 'dashboards.allLead',
                    timezone: this.contextAccount.timezone,
                });

                if (this.contextAccount.sale_table) {
                    this.currentForm.module_access.sale_table = true;
                }

                if (this.contextAccount.niotext) {
                    this.currentForm.niotext = true;
                }
            }
        },
    };
</script>
