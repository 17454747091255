<template>
    <div>
        <personal-access-tokens class="mb-6" />
    </div>
</template>

<script>
    import PersonalAccessTokens from '../passport/PersonalAccessTokens.vue';

    export default {
        components: { PersonalAccessTokens },
    };
</script>
