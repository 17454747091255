<template>
    <activix-modal
        size="md"
        :opened="opened"
        @close="close"
        @closed="onClosed"
    >
        <template slot="header">
            <h4 class="modal-title">
                {{ $t('users.edit.personalAccessToken.createToken') }}
            </h4>
        </template>

        <template slot="body">
            <div class="alert alert-danger" v-if="error">
                <p>{{ $t('error.generic') }}</p>
            </div>

            <div class="row" :class="{ 'form-group': scopesAreVisible }">
                <label class="col-xs-4">{{ $t('users.edit.name') }}</label>

                <div class="col-xs-6">
                    <activix-multiselect
                        :selected="form.name"
                        :options="names"
                        :searchable="false"
                        @update="updateName"
                    />
                </div>
            </div>

            <div class="row form-group" v-if="customNameIsVisible">
                <div class="col-xs-offset-4 col-xs-6 space-y-2">
                    <auto-complete
                        input-class="input-white"
                        :suggestions="autoCompleteNames"
                        :selection="form.customName"
                        @update:selection="val => (form.customName = val)"
                    />
                    <div class="alert alert-danger" v-if="errorTitle">
                        <p>{{ $t('error.noName') }}</p>
                    </div>
                </div>
            </div>

            <div class="row" v-if="scopesAreVisible">
                <label class="col-xs-4">{{ $t('users.edit.personalAccessToken.scopes') }}</label>

                <div class="col-xs-6">
                    <div :key="scope.id" v-for="scope in filteredScopes">
                        <div class="checkbox">
                            <label>
                                <input
                                    type="checkbox"
                                    :checked="scopeIsAssigned(scope.id)"
                                    :disabled="scopeIsDisabled(scope.id)"
                                    @click="toggleScope(scope.id)"
                                />

                                {{ scope.id }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <template slot="footer">
            <activix-button @click="close">
                {{ $t('general.close') }}
            </activix-button>

            <activix-button type="primary" :loading="loading" @click="store">
                {{ $t('general.create') }}
            </activix-button>
        </template>
    </activix-modal>
</template>

<script>
    import { mapState } from 'pinia';
    import { indexOf } from 'lodash-es';
    import Role from '@/entities/Role.js';
    import { useUserCardStore } from '@/store/modules/userCard/store.js';
    import PersonalTokenPermissions from '@/entities/PersonalTokenPermissions.js';
    import AutoComplete from '../inputs/AutoComplete.vue';

    export default {
        components: { AutoComplete },

        props: {
            opened: {
                type: Boolean,
                required: true,
            },
            scopes: {
                type: Array,
                default: () => [],
            },
        },

        data() {
            return {
                error: false,
                errorTitle: false,
                token: '',
                loading: false,
                form: {
                    name: '',
                    customName: '',
                    scopes: [],
                },
            };
        },

        computed: {
            ...mapState(useUserCardStore, ['currentForm']),

            names() {
                const names = ['EasyDeal', 'Lautopak'];

                if (Role.hasAdminRights(this.currentForm.role_id)) {
                    names.push('FordDirect');
                    names.push('NCI Digital');
                    names.push('Porsche Digital');
                    names.push('FCA Digital');
                }

                names.push(this.$t('users.edit.personalAccessToken.custom'));

                return names;
            },

            autoCompleteNames() {
                return ['Autopropulsion', 'Torque'];
            },

            filteredScopes() {
                if (!this.form.name) {
                    return [];
                }

                let scopes = this.scopes;

                scopes = scopes.filter(scope => {
                    return Object.values(PersonalTokenPermissions.entries).includes(scope.id);
                });

                return scopes;
            },

            customNameIsVisible() {
                return this.form.name == this.$t('users.edit.personalAccessToken.custom');
            },

            scopesAreVisible() {
                return this.scopes.length > 0 && this.form.name;
            },

            isValid() {
                return !empty(this.form.name) && (!this.customNameIsVisible || !empty(this.form.customName));
            },
        },

        methods: {
            updateName(name) {
                this.form.name = name;
            },

            store() {
                this.errorTitle = this.customNameIsVisible && this.form.customName === '';

                if (!this.isValid) {
                    return;
                }

                this.error = false;

                const payload = {
                    userId: this.$route.params.id,
                    name: this.customNameIsVisible ? this.form.customName : this.form.name,
                    scopes: this.form.scopes,
                };

                this.loading = true;

                this.$axios
                    .post(`v1/users/${this.$route.params.id}/personal-access-tokens`, payload)
                    .then(response => {
                        this.form.customName = '';
                        this.form.name = '';
                        this.form.scopes = [];

                        this.currentForm.personal_tokens.push(response.data.data.token);
                        this.token = response.data.data.accessToken;

                        this.loading = false;
                        this.close();
                    })
                    .catch(() => {
                        this.loading = false;
                        this.error = true;
                    });
            },

            toggleScope(scope) {
                if (this.scopeIsAssigned(scope)) {
                    this.form.scopes = reject(this.form.scopes, s => s == scope);
                } else {
                    this.form.scopes.push(scope);

                    if (['trigger-flow', 'notes:update', 'notes:create', 'notes:view'].includes(scope)) {
                        this.form.scopes.push('manage-leads');
                    }
                }
            },

            scopeIsDisabled(scope) {
                if (scope == 'manage-leads' && (
                    this.scopeIsAssigned('trigger-flow') ||
                    this.scopeIsAssigned('notes:update') ||
                    this.scopeIsAssigned('notes:create') ||
                    this.scopeIsAssigned('notes:view'))
                ) {
                    return true;
                }

                return false;
            },

            scopeIsAssigned(scope) {
                return indexOf(this.form.scopes, scope) >= 0;
            },

            close() {
                this.$emit('update:opened', false);
            },

            onClosed() {
                this.form.customName = '';
                this.form.name = '';
                this.errorTitle = false;
                this.form.scopes = [];
                this.error = false;
                this.loading = false;

                this.$emit('created', this.token);
            },
        },
    };
</script>
