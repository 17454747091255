<template>
    <tr>
        <td>
            <activix-masked-input
                mask-type="phoneWithCountry"
                :disabled="disabled"
                :value="phone.phone"
                @input="updatePhone('phone', $event)"
            />
        </td>

        <td>
            <activix-masked-input
                mask-type="phoneExtension"
                :disabled="disabled"
                :value="phone.extension"
                @input="updatePhone('extension', $event)"
            />
        </td>

        <td>
            <activix-masked-input
                mask-type="number"
                :disabled="disabled"
                :value="phone.timeout_ringing"
                @input="updatePhone('timeout_ringing', $event)"
            />
        </td>

        <td>
            <activix-masked-input
                mask-type="number"
                :disabled="disabled"
                :value="phone.timeout_keypress"
                @input="updatePhone('timeout_keypress', $event)"
            />
        </td>

        <td class="collapsing">
            <activix-checkbox :value="phone.cellphone" :disabled="disabled" @input="updatePhone('cellphone', $event)" />
        </td>

        <td class="collapsing" v-if="phones.length > 1">
            <activix-checkbox :value="phone.preferred" :disabled="disabled" @input="updatePhone('preferred', $event)" />
        </td>

        <td
            class="collapsing"
            v-if="phones.length > 1 && phone.preferred_service && contextAccount.hasOption('webboost')"
        >
            <activix-checkbox
                :value="phone.preferred_service.web_boost"
                :disabled="disabled"
                @input="updatePhone('preferred_service.web_boost', $event)"
            />
        </td>

        <td
            class="collapsing"
            v-if="phones.length > 1 && !empty(phone.preferred_service) && contextAccount.hasOption('phone_up')"
        >
            <activix-checkbox
                :value="phone.preferred_service.phone_up"
                :disabled="disabled"
                @input="updatePhone('preferred_service.phone_up', $event)"
            />
        </td>

        <td class="collapsing">
            <activix-checkbox
                :disabled="disabledPressToTalk"
                :value="phone.press_to_talk"
                @click="warningPressToTalk"
            />
        </td>

        <td class="collapsing">
            <div class="flex items-center">
                <icon
                    :name="$icons.trash"
                    class="link-grey-light"
                    :class="disabled ? 'disabled' : 'hover:text-red-500'"
                    @click="$emit('delete')"
                />
            </div>
            <activix-confirm-modal
                type="warning"
                :title="$t('general.areYouSure')"
                :content="$t('phoneProvider.deactivatePressToTalk')"
                :approve-text="$t('phoneProvider.deactivateIt')"
                :deny-text="$t('general.cancel')"
                :opened.sync="warningPressToTalkModalOpened"
                @deny="updatePhone('press_to_talk', true)"
                @approve="updatePhone('press_to_talk', false)"
            />
        </td>
    </tr>
</template>

<script>
    import { mapActions, mapState } from 'pinia';
    import { cloneDeep, isEqual, set } from 'lodash-es';
    import { merge } from '../../utils/index.js';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useUserCardStore } from '../../store/modules/userCard/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        props: {
            defaultPhone: {
                type: Object,
                required: true,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            identifier: {
                type: Number,
                required: true,
            },
            phoneData: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                phone: cloneDeep(this.defaultPhone),
                warningPressToTalkModalOpened: false,
            };
        },

        computed: {
            ...mapState(useUserCardStore, {
                phones: store => store.currentForm.user_phones,
            }),
            ...mapState(useGlobalStore, ['authUser', 'activeUsers']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            disabledPressToTalk() {
                return !this.authUser.isAdmin() && !this.authUser.isDirector();
            },
        },

        watch: {
            phoneData: {
                immediate: true,
                handler(phoneData) {
                    merge(this.phone, phoneData);
                },
            },

            phones(phones) {
                if (phones.length === 1 && !phones[0].preferred) {
                    this.updatePhone('preferred', true);
                }
            },

            'phone.preferred'(preferred) {
                if (preferred) {
                    const phones = cloneDeep(this.phones);

                    phones.forEach((phone, index) => {
                        if (index != this.identifier && phone.preferred) {
                            phone.preferred = false;
                        }
                    });

                    if (!isEqual(this.phones, phones)) {
                        this.updateUserProperties({ user_phones: phones });
                    }
                }
            },
        },

        methods: {
            ...mapActions(useUserCardStore, ['updateUserProperties']),

            warningPressToTalk(event) {
                if (this.phone.press_to_talk) {
                    this.warningPressToTalkModalOpened = true;

                    event.preventDefault();
                    return;
                }

                this.updatePhone('press_to_talk', true);
            },

            updatePhone(attribute, value) {
                const phones = cloneDeep(this.phones);

                set(phones[this.identifier], attribute, value);

                this.updateUserProperties({ user_phones: phones });
            },
        },

        mounted() {
            merge(this.phone, this.phoneData);
        },
    };
</script>
