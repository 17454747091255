<!-- eslint-disable vue/no-mutating-props -->
<template>
    <div class="flex w-full items-center pt-4">
        <div class="flex-1">
            <activix-multiselect
                identifier="value"
                label="label"
                :disabled="disabled"
                :selected="selectedDateRange"
                :options="dateRangeOptions"
                :searchable="false"
                :max-height="200"
                :allow-empty="false"
                @update="updateDateRange"
            />
        </div>
        <div class="flex items-center mx-6">
            <activix-checkbox :disabled="disabled" v-model="reminder.notification">
                <icon class="text-2xl text-grey-600" name="regular/screen-1" />
            </activix-checkbox>

            <activix-checkbox class="ml-4" :disabled="disabled" v-model="reminder.sms">
                <icon class="text-2xl text-grey-600" :name="$icons.sms" />
            </activix-checkbox>

            <activix-checkbox class="ml-4" :disabled="disabled" v-model="reminder.push">
                <icon class="text-2xl text-grey-600" name="regular/mobile-phone" />
            </activix-checkbox>

            <activix-checkbox class="ml-4" :disabled="disabled" v-model="reminder.email">
                <icon class="text-2xl text-grey-600" :name="$icons.email" />
            </activix-checkbox>
        </div>
        <icon
            class="link-grey-light | hover:text-red-500"
            :class="{ disabled: disabled }"
            :name="$icons.trash"
            @click="!disabled ? $emit('delete-reminder', reminder) : null"
        />
    </div>
</template>

<script>
    /* eslint-disable vue/no-mutating-props */
    import { cloneDeep } from 'lodash-es';
    import Reminder from '../../entities/Reminder.js';

    export default {
        props: {
            reminder: {
                type: Object,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            compact: {
                type: Boolean,
                default: false,
            },
            additionalOptions: {
                type: Array,
                default: () => [],
            },
        },

        data() {
            return {
                baseDateRangeOptions: [
                    {
                        value: '0',
                        label: `${this.$t('calendar.atTaskEventTime')}`,
                    },
                    {
                        value: '5',
                        label: `5 ${this.$t('calendar.minutesBefore')}`,
                    },
                    {
                        value: '10',
                        label: `10 ${this.$t('calendar.minutesBefore')}`,
                    },
                    {
                        value: '15',
                        label: `15 ${this.$t('calendar.minutesBefore')}`,
                    },
                    {
                        value: '30',
                        label: `30 ${this.$t('calendar.minutesBefore')}`,
                    },
                    {
                        value: '45',
                        label: `45 ${this.$t('calendar.minutesBefore')}`,
                    },
                    {
                        value: '60',
                        label: `1h00m ${this.$t('calendar.before')}`,
                    },
                    {
                        value: '75',
                        label: `1h15m ${this.$t('calendar.before')}`,
                    },
                    {
                        value: '90',
                        label: `1h30m ${this.$t('calendar.before')}`,
                    },
                    {
                        value: '105',
                        label: `1h45m ${this.$t('calendar.before')}`,
                    },
                    {
                        value: '120',
                        label: `2h00m ${this.$t('calendar.before')}`,
                    },
                    {
                        value: '360',
                        label: `6h00m ${this.$t('calendar.before')}`,
                    },
                    {
                        value: Reminder.NINE_AM,
                        label: this.$t('calendar.nineAm'),
                    },
                    {
                        value: Reminder.NINE_PM,
                        label: this.$t('calendar.ninePm'),
                    },
                ],
            };
        },

        computed: {
            dateRangeOptions() {
                const options = cloneDeep(this.baseDateRangeOptions);
                this.additionalOptions.forEach(option => {
                    options.unshift(option);
                });
                return options;
            },
            selectedDateRange() {
                return this.dateRangeOptions.filter(dateRange => {
                    return dateRange.value == this.reminder.date_range;
                })[0];
            },
        },

        watch: {
            'reminder.notification'() {
                this.$emit('update-reminder', this.reminder);
            },
            'reminder.sms'() {
                this.$emit('update-reminder', this.reminder);
            },
            'reminder.email'() {
                this.$emit('update-reminder', this.reminder);
            },
            'reminder.date_range'() {
                this.$emit('update-reminder', this.reminder);
            },
        },

        methods: {
            updateDateRange(dateRange) {
                this.reminder.date_range = dateRange.value;
            },
        },
    };
</script>
