<template>
    <div>
        <div class="flex items-center justify-center absolute h-full w-full top-0" v-if="$wait.is('fetching.user')">
            <activix-spinner :message="$t('general.loading')" />
        </div>
        <div v-else>
            <portal to="content-header-left">
                <activix-tooltip :content="$t('users.global.goToAccount')">
                    <router-link
                        class="flex ml-2 link-grey"
                        :to="{ name: 'accounts.update', params: { id: contextAccount.id } }"
                        v-if="canAccessAccountCard"
                    >
                        <icon name="regular/shop" />
                    </router-link>
                </activix-tooltip>

                <activix-tooltip :content="$t('users.global.impersonate')" v-if="canImpersonate">
                    <icon class="link-grey ml-2" name="regular/login-1" @click="impersonate" />
                </activix-tooltip>
            </portal>

            <div class="alert alert-danger | mb-4" v-if="errors.length">
                <div class="font-bold">
                    {{ $t('toastr.error') }}
                </div>
                <ul>
                    <li :key="error" v-html="error" v-for="error in errors" />
                </ul>
            </div>
            <div class="alert alert-info | mb-4" v-if="currentForm.child_accounts.length">
                <div class="font-bold">
                    {{ $t('error.warning') }}
                </div>
                <p v-html="$t('users.edit.masterAccountPreferences')" />
            </div>

            <div class="flex justify-between items-center mb-6">
                <ul class="nav nav-pills">
                    <li :class="{ active: selectedTab == 'general' }">
                        <a @click="setTab('general')">
                            {{ $t('users.edit.general') }}
                        </a>
                    </li>
                    <li :class="{ active: selectedTab == 'preferences' }">
                        <a @click="setTab('preferences')">
                            {{ $t('users.edit.preferences') }}
                        </a>
                    </li>
                    <li :class="{ active: selectedTab == 'activix' }" v-if="showActivixOptions">
                        <a @click="setTab('activix')">
                            Activix
                        </a>
                    </li>
                </ul>

                <user-buttons @save="save" />
            </div>

            <general-settings
                :related-data="relatedData"
                :img-resolution-to-large.sync="imgResolutionToLarge"
                :crm-checkbox.sync="crmCheckbox"
                :department-checkbox.sync="departmentCheckbox"
                v-if="selectedTab == 'general'"
            />

            <preferences-settings v-if="selectedTab == 'preferences'" />

            <activix-settings v-if="selectedTab == 'activix' && authUser.isAdmin()" />

            <div class="flex justify-end">
                <user-buttons @save="save" />
            </div>
        </div>

        <sync-child-user-modal :opened="showSyncChildUserModalOpened" :changes="changes" @closed="showSyncChildUserModalOpened=false" />
    </div>

</template>

<script>
    import { cloneDeep, get, isEmpty, isEqual, isObjectLike } from 'lodash-es';
    import { mapActions, mapState } from 'pinia';
    import Account from '../../entities/Account.js';

    import ActivixSettings from '../../components/users/ActivixSettings.vue';
    import GeneralSettings from '../../components/users/GeneralSettings.vue';
    import PreferencesSettings from '../../components/users/PreferencesSettings.vue';
    import UserButtons from '../../components/users/UserButtons.vue';
    import TrackView from '../../mixins/TrackView.js';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';
    import { useUserCardStore } from '../../store/modules/userCard/store.js';
    import SyncChildUserModal from '../../components/modals/SyncChildUserModal.vue';

    export default {
        name: 'UsersEdit',

        components: {
            SyncChildUserModal,
            ActivixSettings,
            GeneralSettings,
            PreferencesSettings,
            UserButtons,
        },

        mixins: [TrackView],

        data() {
            return {
                isDuplicate: false,
                selectedTab: 'general',
                showSyncChildUserModalOpened: false,
                imgResolutionToLarge: false,
                errors: [],
                crmCheckbox: false,
                departmentCheckbox: false,
                relatedData: {
                    posts: [],
                    roles: [],
                    teams: [],
                    divisions: [],
                    groups: [],
                    suppliers: [],
                    directors: [],
                    dashboards: [],
                    users: [],
                },
                changes: {},
                originalForm: {},
            };
        },

        computed: {
            ...mapState(useUserCardStore, ['currentForm', 'defaultForm']),
            ...mapState(useGlobalStore, ['authUser', 'parentAuthUser']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            showActivixOptions() {
                return this.authUser.isProgrammer() && !this.contextAccount.restrict_to_activix;
            },

            crmCheckboxActive() {
                return (
                    (this.currentForm.module_access.leadxpress || !this.contextAccount.hasOption('leadxpress')) &&
                    (this.currentForm.module_access.web_boost || !this.contextAccount.hasOption('webboost')) &&
                    (this.currentForm.module_access.walk_in || !this.contextAccount.hasOption('walk_in')) &&
                    (this.currentForm.module_access.phone_up || !this.contextAccount.hasOption('phone_up'))
                );
            },

            departmentCheckboxActive() {
                return (
                    (this.currentForm.module_access.web_order || !this.contextAccount.hasOption('web_order')) &&
                    (this.currentForm.module_access.loyalty || !this.contextAccount.hasOption('loyalty')) &&
                    (this.currentForm.module_access.renewal || !this.contextAccount.hasOption('renewal')) &&
                    (this.currentForm.module_access.commercial || !this.contextAccount.hasOption('commercial')) &&
                    (this.currentForm.module_access.service || !this.contextAccount.hasOption('service')) &&
                    (this.currentForm.module_access.event || !this.contextAccount.hasOption('event')) &&
                    (this.currentForm.module_access.activity_report ||
                        !this.contextAccount.hasOption('activity_report'))
                );
            },

            // @TODO Refactor
            authorized() {
                if (this.authUser.isAdmin()) {
                    return true;
                }

                if (this.authUser.isDirector()) {
                    return true;
                }

                if (this.authUser.isBdcDirector()) {
                    return true;
                }

                if (this.$route.params.id == this.authUser.id) {
                    return true;
                }

                if (this.parentAuthUser.children.some(child => child.id == this.$route.params.id)) {
                    return true;
                }

                return false;
            },

            canAccessAccountCard() {
                return this.authUser.hasAccessTo('accounts.update');
            },

            canImpersonate() {
                return this.authUser.isAdmin() && this.contextAccount.id != Account.ACTIVIX && this.currentForm.id;
            },

            optimizelyUser() {
                return {
                    id: this.currentForm.id,
                    account_id: this.currentForm.account.id,
                    role_id: this.currentForm.role && this.currentForm.role.id,
                };
            },

            formDuplicated() {
                const formDuplicated = cloneDeep(this.currentForm);
                formDuplicated.id = null;
                formDuplicated.access_token = '';
                formDuplicated.first_name = '';
                formDuplicated.last_name = '';
                formDuplicated.email = '';
                formDuplicated.post = null;
                formDuplicated.role = {};
                formDuplicated.password = '';
                formDuplicated.password_confirmation = '';
                formDuplicated.profilePicturePath = '';
                formDuplicated.profile_picture = '';
                formDuplicated.user_phones = [];
                formDuplicated.email_config = {
                    access_token: '',
                    active: false,
                    signature: '',
                };
                formDuplicated.parent = {};
                formDuplicated.parent_user_id = null;
                formDuplicated.supplier = null;
                formDuplicated.suppliers = [];
                formDuplicated.children = [];
                formDuplicated.child_accounts = [];
                formDuplicated.flows = [];
                formDuplicated.personal_tokens = [];
                Object.entries(formDuplicated.options).map((option) => {
                    delete formDuplicated.options[option[0]].id;
                    delete formDuplicated.options[option[0]].user_id;
                    return option;
                });

                return formDuplicated;
            },
        },

        watch: {
            '$route.params.id': {
                immediate: true,
                handler(newId, oldId) {
                    if (newId && newId == oldId) {
                        return;
                    }

                    this.reset();
                    this.fetchData();
                },
            },

            crmCheckbox(val) {
                if (!val && !this.crmCheckboxActive) {
                    return;
                }

                const moduleAccess = cloneDeep(this.currentForm.module_access);

                if (this.contextAccount.hasOption('leadxpress')) {
                    moduleAccess.leadxpress = val;
                }

                if (this.contextAccount.hasOption('webboost')) {
                    moduleAccess.web_boost = val;
                }

                if (this.contextAccount.hasOption('walk_in')) {
                    moduleAccess.walk_in = val;
                }

                if (this.contextAccount.hasOption('phone_up')) {
                    moduleAccess.phone_up = val;
                }

                useUserCardStore().currentForm.module_access = moduleAccess;
            },

            departmentCheckbox(val) {
                if (!val && !this.departmentCheckboxActive) {
                    return;
                }

                const moduleAccess = cloneDeep(this.currentForm.module_access);

                if (this.contextAccount.hasOption('web_order')) {
                    moduleAccess.web_order = val;
                }

                if (this.contextAccount.hasOption('loyalty')) {
                    moduleAccess.loyalty = val;
                }

                if (this.contextAccount.hasOption('renewal')) {
                    moduleAccess.renewal = val;
                }

                if (this.contextAccount.hasOption('service')) {
                    moduleAccess.service = val;
                }

                if (this.contextAccount.hasOption('commercial')) {
                    moduleAccess.commercial = val;
                }

                if (this.contextAccount.hasOption('event')) {
                    moduleAccess.event = val;
                }

                if (this.contextAccount.hasOption('activity_report')) {
                    moduleAccess.activity_report = val;
                }

                useUserCardStore().currentForm.module_access = moduleAccess;
            },

            crmCheckboxActive(val) {
                this.crmCheckbox = val;
            },

            departmentCheckboxActive(val) {
                this.departmentCheckbox = val;
            },

            contextAccount(nexContextAccount) {
                if (nexContextAccount.webboost && !nexContextAccount.leadxpress && this.$route.params.id) {
                    this.updateUserProperties({
                        take_over_alert: true,
                        take_over_sms: true,
                    });
                }

                if (!this.$route.params.id) {
                    this.updateUserProperties({
                        locale: this.contextAccount.locale,
                    });
                }
            },

            errors(newValue) {
                if (!newValue.length) {
                    return;
                }

                window.scrollTo(0, 0);
            },
        },

        methods: {
            ...mapActions(useUserCardStore, ['updateUserProperties']),
            ...mapActions(useContextStore, [
                'setContextAccountAction',
                'setContextUserAction',
            ]),

            async fetchData() {
                if (!this.authorized) {
                    return;
                }

                this.$wait.start('fetching.user');

                try {
                    const payload = {};

                    if (this.$route.params.id) {
                        payload.params = { userId: this.$route.params.id };
                    } else {
                        payload.params = { accountId: this.contextAccount.id };
                    }

                    const response = await this.$axios.get('v1/legacy/users/data', payload);

                    const data = response.data.data;

                    if (this.contextAccount.id != data.account.id) {
                        await this.setContextAccountAction(data.account.id);
                    }

                    Object.keys(this.relatedData).forEach(propName => {
                        this.relatedData[propName] = data[propName];
                    });

                    if (get(data.user, 'id')) {
                        this.applyData(data.user);
                    } else {
                        this.applyDefaultData();
                    }

                    await this.$nextTick();

                    this.crmCheckbox = this.crmCheckboxActive;
                    this.departmentCheckbox = this.departmentCheckboxActive;

                    this.$wait.end('fetching.user');
                } catch (error) {
                    this.$wait.end('fetching.user');
                    this.$notify.error(this.$t('users.alerts.show.error'));

                    throw error;
                }
            },

            applyData(user) {
                if (!user) {
                    return;
                }

                const temporaryData = cloneDeep(this.defaultForm);

                // Child accounts
                for (const child of user.children) {
                    temporaryData.child_accounts.push(child.account);
                }

                Object.keys(user).forEach(propName => {
                    if (!temporaryData.hasOwnProperty(propName)) {
                        return;
                    }

                    if (propName == 'profile_picture') {
                        temporaryData[propName] = user[propName] == '/img/nobody.jpg' ? null : user[propName];
                        temporaryData.profilePicturePath = temporaryData[propName] || '/img/nobody.jpg';
                    } else if (propName == 'options') {
                        user.options.forEach(option => {
                            if (!temporaryData.options[option.name]) {
                                return;
                            }

                            temporaryData.options[option.name] = option;

                            if (
                                this.currentForm.options[option.name].hasOwnProperty('availability') &&
                                empty(temporaryData.options[option.name].availability)
                            ) {
                                temporaryData.options[option.name].availability = this.currentForm.options[
                                    option.name
                                ].availability;
                            }
                        });
                    } else if (propName == 'automation_options') {
                        user.automation_options.forEach(option => {
                            temporaryData.automation_options[option.id] = option;
                        });
                    } else if (propName == 'module_access') {
                        temporaryData[propName] = {
                            ...temporaryData[propName],
                            ...user[propName],
                        };
                    } else {
                        temporaryData[propName] = user[propName];
                    }
                });

                useUserCardStore().currentForm = temporaryData;
                this.originalForm = cloneDeep(temporaryData);
            },

            applyDefaultData() {
                this.$nextTick(() => {
                    this.updateUserProperties({
                        niotext: this.contextAccount.niotext && !this.contextAccount.account_manager,
                        locale: this.contextAccount.locale,
                    });

                    this.currentForm.options.leadWaitingInRoom.push = this.contextAccount.video_conference;
                    useUserCardStore().currentForm.options = this.currentForm.options;
                });
            },

            save(method = 'default') {
                this.errors = [];

                if (this.imgResolutionToLarge) {
                    this.errors.push(this.$t('users.edit.profilePictureTooLarge'));
                    return;
                }

                const temporaryData = cloneDeep(this.currentForm);
                const payload = new FormData();

                Object.keys(temporaryData).forEach(propName => {
                    if (propName != 'profile_picture' && isObjectLike(temporaryData[propName])) {
                        payload.append(propName, JSON.stringify(temporaryData[propName]));
                    } else {
                        payload.append(propName, temporaryData[propName]);
                    }
                });

                payload.append('account', this.contextAccount.id);

                // Put in place since we dont have a timezone select for the user and it will send the
                // default America/Montreal to the backend, we dont want individual timezone for now
                payload.timezone = this.contextAccount.timezone;

                if (this.$route.params.id) {
                    this.updateUser(payload, method);
                } else {
                    this.createUser(payload, method);
                }
            },

            async createUser(payload, method) {
                this.$wait.start(`creating.user.${method}`);

                try {
                    const response = await this.$axios.post('v1/legacy/users', payload);

                    this.$notify.success(this.$t('users.alerts.store.success'));
                    this.$wait.end(`creating.user.${method}`);

                    this.onSaveSuccess(response, method);
                } catch (error) {
                    this.$wait.end(`creating.user.${method}`);

                    const status = get(error.response, 'status');

                    if (status == 422) {
                        Object.values(error.response.data.errors).forEach(e => {
                            this.errors.push(Array.isArray(e) ? e[0] : e);
                        });
                        return;
                    }

                    this.$notify.error(this.$t('users.alerts.store.error'));

                    throw error;
                }
            },

            async updateUser(payload, method) {
                this.$wait.start(`updating.user.${method}`);

                payload.append('_method', 'PUT'); // Required since put request can't send FormData.

                try {
                    const response = await this.$axios.post(`v1/legacy/users/${this.$route.params.id}`, payload);
                    const changes = response.data.changes || {};

                    if (this.$feature.isEnabled('parent-and-childs-improvements') && this.currentForm.children.length && !isEmpty(changes)) {
                        if (isEmpty(changes.module_access) || isEqual(this.originalForm.module_access, changes.module_access)) {
                            delete changes.module_access;
                        }

                        if (isEmpty(changes.divisions) || (isEmpty(changes.divisions.attached) && isEmpty(changes.divisions.detached))) {
                            delete changes.divisions;
                        }

                        if (isEmpty(changes.options)) {
                            delete changes.options;
                        }

                        if (!isEmpty(changes)) {
                            this.changes = changes;
                            this.showSyncChildUserModalOpened = true;
                        }
                    }

                    this.$notify.success(this.$t('users.alerts.update.success'));
                    this.$wait.end(`updating.user.${method}`);

                    this.onSaveSuccess(response, method);
                } catch (error) {
                    this.$wait.end(`updating.user.${method}`);

                    const status = get(error.response, 'status');

                    if (status == 422) {
                        Object.values(error.response.data.errors).forEach(e => {
                            this.errors.push(Array.isArray(e) ? e[0] : e);
                        });
                        return;
                    }

                    this.$notify.error(this.$t('users.alerts.update.error'));

                    throw error;
                }
            },

            onSaveSuccess(response, method) {
                if (method == 'new') {
                    this.$router.push({ name: 'users.store' });
                } else if (method == 'duplicate') {
                    this.isDuplicate = true;
                    this.$router.push({ name: 'users.store' });
                } else if (method == 'close') {
                    this.$router.push({ name: 'users.index' });
                } else {
                    this.$router.push({ name: 'users.update', params: { id: response.data.user.id } });
                }
            },

            setTab(tab) {
                const validTabs = ['general', 'preferences', 'activix'];

                if (!validTabs.includes(tab)) {
                    tab = null;
                }

                if (tab) {
                    this.selectedTab = tab;
                }

                if (this.$route.query.tab != tab) {
                    this.updateQuery({ tab });
                }

                if (tab == 'preferences') {
                    this.$behavior.track('Users', { tabs: 'preferences' });
                }
            },

            reset() {
                if (this.isDuplicate) {
                    useUserCardStore().currentForm = cloneDeep(this.formDuplicated);
                    this.isDuplicate = false;
                } else {
                    useUserCardStore().currentForm = cloneDeep(this.defaultForm);
                }
            },

            async impersonate() {
                this.$ls.remove('context');

                await this.$nextTick();

                this.$auth.impersonate({
                    data: {
                        userId: this.$route.params.id,
                    },
                });
            },
        },

        created() {
            this.setTab(this.$route.query.tab);
        },

        async beforeDestroy() {
            this.reset();
            await this.setContextUserAction();
        },
    };
</script>
