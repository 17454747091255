<template>
    <activix-modal size="md" :opened="opened" @close="close">
        <template slot="header">
            <h4 class="modal-title">
                {{ $t('users.edit.personalAccessToken.title') }}
            </h4>
        </template>

        <template slot="body">
            <p>{{ $t('users.edit.personalAccessToken.newTokenMessage') }}</p>
            <pre>
                <code class="text-grey-800" ref="token">{{ accessToken }}</code>
            </pre>
        </template>

        <template slot="footer">
            <activix-button @click="copyToClipboard">
                {{ $t('general.copy') }}
            </activix-button>
        </template>
    </activix-modal>
</template>

<script>
    export default {
        props: {
            opened: {
                type: Boolean,
                default: false,
                required: true,
            },
            accessToken: {
                type: String,
                default: '',
            },
        },

        methods: {
            copyToClipboard() {
                let range;

                if (document.selection) {
                    // IE
                    range = document.body.createTextRange();
                    range.moveToElementText(this.$refs.token);
                    range.select();
                } else if (window.getSelection) {
                    range = document.createRange();
                    range.selectNode(this.$refs.token);
                    window.getSelection().removeAllRanges();
                    window.getSelection().addRange(range);
                }

                try {
                    const successful = document.execCommand('copy');
                    if (successful) {
                        this.$notify.success(this.$t('general.copiedSuccessfully'));
                    }
                } catch (err) {
                    this.$notify.warning(this.$t('general.copiedUnsuccessfully'));
                }
            },
            close() {
                this.$emit('update:opened', false);
            },
        },
    };
</script>
