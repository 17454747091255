<template>
    <activix-multiselect
        label="value"
        :options="divisions"
        :disabled="disabled"
        :searchable="false"
        :multiple="true"
        :allow-empty="true"
        :auto-apply="true"
        :selected="selectedOptions"
        :select-all="false"
        @update="updateOptions"
    />
</template>

<script>
    import { mapState } from 'pinia';
    import Division from '../../entities/Division.js';
    import { useUserCardStore } from '../../store/modules/userCard/store.js';

    export default {
        name: 'NotificationDivisions',

        props: {
            notificationName: {
                type: String,
                default: '',
            },
            automationOption: {
                type: String,
                default: '',
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            ...mapState(useUserCardStore, {
                preferenceOptions: store => store.currentForm.options,
                automationOptions: store => store.currentForm.automation_options,
            }),

            divisions() {
                return [
                    {
                        id: 'default',
                        value: this.$t('users.edit.defaultNotification'),
                        disabled: true,
                    },
                    {
                        id: Division.USED,
                        value: this.$t('divisions.used'),
                        disabled: false,
                    },
                    {
                        id: Division.NEW,
                        value: this.$t('divisions.new'),
                        disabled: false,
                    },
                    {
                        id: Division.SERVICE,
                        value: this.$t('divisions.service'),
                        disabled: false,
                    },
                ];
            },

            selectedOptions() {
                return this.divisions.filter(division => this.notificationOptions.includes(division.id));
            },

            notificationOptions() {
                if (this.automationOption) {
                    return this.automationOptions[this.automationOption].divisions;
                }

                return this.preferenceOptions[this.notificationName].divisions;
            },
        },

        methods: {
            updateOptions(value) {
                let selected = value.map(division => division.id);

                if (!selected.length) {
                    selected = ['default'];
                } else if (selected.some(division => division != 'default')) {
                    selected = selected.filter(division => division != 'default');
                }

                if (this.automationOption) {
                    const options = this.automationOptions;
                    options[this.automationOption].divisions = selected;

                    useUserCardStore().currentForm.automation_options = options;
                } else {
                    const options = this.preferenceOptions;
                    options[this.notificationName].divisions = selected;

                    useUserCardStore().currentForm.options = options;
                }
            },
        },
    };
</script>
