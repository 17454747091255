<template>
    <activix-modal scrollable :opened="opened" :closable="false">
        <template slot="header">
            <h4 class="modal-title" v-text="$t('users.edit.transferModalTitle')" />
        </template>

        <template slot="body">
            <div class="pb-4 text-center text-xl font-medium" v-text="$t('users.edit.transferType')" />
            <div class="pb-4 flex flex-col items-center">
                <div class="flex items-center space-x-4 rounded py-2 px-3">
                    <activix-checkbox :value="transferTypes.lead" @click="transferTypes.lead = !transferTypes.lead" />
                    <div class="flex flex-col w-80 text-left" v-text="$t('users.edit.leadTransfer')" />
                </div>

                <div class="flex items-center space-x-4 rounded py-2 px-3">
                    <activix-checkbox :value="transferTypes.taskevent" @click="transferTypes.taskevent = !transferTypes.taskevent" />
                    <div class="flex flex-col w-80 text-left" v-text="$t('users.edit.taskEventTransfer')" />
                </div>

                <div class="flex items-center space-x-4 rounded py-2 px-3">
                    <activix-checkbox :value="transferTypes.reminder" @click="transferTypes.reminder = !transferTypes.reminder" />
                    <div class="flex flex-col w-80 text-left" v-text="$t('users.edit.remindersTransfer')" />
                </div>

                <div class="flex items-center space-x-4 rounded py-2 px-3" :disabled="true">
                    <activix-checkbox :disabled="true" :value="transferTypes.notifications" @click="transferTypes.notifications = !transferTypes.notifications" />
                    <activix-tooltip :content="$t('users.edit.notificationsTooltip')">
                        <div class="flex flex-col w-80 text-left" v-text="$t('users.edit.notificationsTransfer')" />
                    </activix-tooltip>
                </div>
            </div>

            <div class="pt-4 pb-4 text-center text-xl font-medium" v-text="$t('users.edit.selectUser')" />
            <div class="flex justify-center pb-4">
                <div class="w-1/2" v-if="!currentForm.children.length">
                    <activix-multiselect
                        :options="orderedUsers"
                        :selected="selectedUserInactive"
                        :searchable="false"
                        :custom-label="formatUser"
                        :disabled="!authUser.isAdmin() && !authUser.isDirector()"
                        @update="updateTransferUser"
                    />
                </div>
                <div v-else>
                    <div class="flex justify-between items-center mb-3">
                        <span v-text="currentForm.account.name" />
                        <activix-multiselect
                            clearable
                            class="ml-2 max-w-48"
                            :options="orderedUsers"
                            :selected="selectedUserInactive"
                            :searchable="false"
                            :custom-label="formatUser"
                            :disabled="!authUser.isAdmin() && !authUser.isDirector()"
                            @update="updateTransferUser"
                        />
                    </div>

                    <div class="flex justify-between items-center mb-3" :key="child.id" v-for="child in currentForm.children">
                        <span v-text="child.account.name" />
                        <activix-multiselect
                            clearable
                            class="ml-2 max-w-48"
                            :options="orderedUsersForAccount(child.account_id)"
                            :selected="selectedUserInactiveForAccount(child.account_id)"
                            :searchable="false"
                            :custom-label="formatUser"
                            :disabled="!authUser.isAdmin() && !authUser.isDirector()"
                            @update="updateTransferUserForAccount(child.account_id, $event)"
                        />
                    </div>
                </div>
            </div>
        </template>

        <template slot="footer">
            <div class="flex items-center justify-end space-x-2">
                <activix-button @click="cancel" v-text="$t('general.cancel')" />

                <activix-button
                    type="primary"
                    :disabled="disabled"
                    @click="update"
                    v-text="$t('users.edit.confirm')"
                />
            </div>
        </template>
    </activix-modal>
</template>

<script>
    import { orderBy, cloneDeep } from 'lodash-es';
    import { mapActions, mapState } from 'pinia';
    import { useContextStore } from '@/store/modules/context/store.js';
    import { useGlobalStore } from '@/store/store.js';
    import { useUserCardStore } from '@/store/modules/userCard/store.js';

    export default {
        props: {
            opened: {
                type: Boolean,
                default: false,
            },
            relatedData: {
                type: Object,
                default: null,
            },
            closable: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                isUserSelected: false,
                transferTypes: { lead: false, taskevent: false, reminder: false, notifications: true },
            };
        },

        computed: {
            ...mapState(useUserCardStore, {
                currentForm: 'currentForm',
                preferenceOptions: store => store.currentForm.options,
            }),
            ...mapState(useGlobalStore, ['authUser', 'timezones', 'configs']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            isAllSelected() {
                return this.transferTypes.lead && this.transferTypes.taskevent && this.transferTypes.reminder;
            },

            orderedUsers() {
                const relatedUsers = this.relatedData.users.filter(user => {
                    return user.id != this.currentForm.id;
                });

                return orderBy(relatedUsers, 'first_name');
            },

            selectedUserInactive() {
                return this.relatedData.users.find(user => user.id == this.currentForm.director_inactive);
            },

            disabled() {
                if (this.currentForm.children.length) {
                    return Object.keys(this.currentForm.director_inactive_children).length != this.currentForm.children.length ||
                        !this.currentForm.director_inactive;
                }

                return !this.currentForm.director_inactive;
            },
        },

        methods: {
            ...mapActions(useUserCardStore, ['updateUserProperties']),

            formatUser(user) {
                return `${user.first_name} ${user.last_name}`;
            },

            closeModal() {
                this.$emit('closed');
            },

            updateTransferUser(user) {
                this.updateUserProperties({ director_inactive: user.id });
            },

            updateTransferUserForAccount(accountId, user) {
                const directorInactiveChildren = cloneDeep(this.currentForm.director_inactive_children);

                directorInactiveChildren[accountId] = user?.id;

                this.updateUserProperties({ director_inactive_children: directorInactiveChildren });
            },

            selectedUserInactiveForAccount(accountId) {
                const userId = this.currentForm.director_inactive_children[accountId];

                return this.orderedUsersForAccount(accountId).find(user => user.id == userId);
            },

            orderedUsersForAccount(accountId) {
                const relatedUsers = this.contextAccount.children
                    .find(child => child.id == accountId)
                    .users
                    .filter(user => {
                        return user.active && user.parent_user_id != this.currentForm.id;
                    });

                return orderBy(relatedUsers, 'first_name');
            },

            async update() {
                const transferOptions = [];

                if (this.transferTypes.lead) {
                    transferOptions.push('lead');
                }

                if (this.transferTypes.taskevent) {
                    transferOptions.push('taskevent');
                }

                if (this.transferTypes.reminder) {
                    transferOptions.push('reminder');
                }

                this.updateUserProperties({ transfer_options: transferOptions });
                this.closeModal();
            },

            cancel() {
                this.updateUserProperties({ active: true });
                this.closeModal();
            },
        },
    };
</script>
