<template>
    <div>
        <template v-if="validAccount == 'activate'">
            <div class="alert alert-success">
                <icon name="regular/check-double" class="mr-1" />
                {{ $t('configMailClient.alreadyActive') }}
            </div>

            <div class="row">
                <div class="col-sm-6">
                    <a class="btn btn-primary btn-block" :class="{ disabled: loading }" @click="deactivate">
                        {{ $t('configMailClient.deactivate') }}
                    </a>
                </div>

                <activix-tooltip
                    :content="!contextAccount.email_client ? $t('users.edit.accountMustHaveImapEmail') : ''"
                >
                    <div class="col-sm-6">
                        <a
                            class="btn btn-primary btn-block"
                            target="_blank"
                            :class="{ disabled: !contextAccount.email_client || loading }"
                            :href="loginPath"
                        >
                            {{ $t('configMailClient.reActivate') }}
                        </a>
                    </div>
                </activix-tooltip>
            </div>
        </template>

        <activix-tooltip :content="!contextAccount.email_client ? $t('users.edit.accountMustHaveImapEmail') : ''">
            <div v-if="validAccount == 'deactivate'">
                <a
                    class="btn btn-primary btn-block"
                    :class="{ disabled: !contextAccount.email_client || loading }"
                    @click="reactivate"
                >
                    {{ $t('configMailClient.reActivate') }}
                </a>
            </div>
        </activix-tooltip>

        <activix-tooltip :content="!contextAccount.email_client ? $t('users.edit.accountMustHaveImapEmail') : ''">
            <div v-if="validAccount == 'invalid'">
                <a
                    class="btn btn-primary btn-block"
                    :class="{ disabled: !contextAccount.email_client || loading }"
                    target="_blank"
                    :href="loginPath"
                >
                    {{ $t('configMailClient.activate') }}
                </a>
            </div>
        </activix-tooltip>
    </div>
</template>

<script>
    import qs from 'qs';
    import { mapState } from 'pinia';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';
    import { useUserCardStore } from '../../store/modules/userCard/store.js';

    export default {
        data() {
            return {
                validAccount: 'invalid',
                loading: false,
            };
        },

        computed: {
            ...mapState(useUserCardStore, ['currentForm']),
            ...mapState(useGlobalStore, ['configs']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            loginPath() {
                if (!this.$route.params.id) {
                    return '';
                }

                const payload = {
                    response_type: 'code',
                    client_id: process.env.VUE_APP_NYLAS_APP_ID,
                    redirect_uri: `${process.env.VUE_APP_API_URL}/mail-config/result?locale=${this.$i18n.locale}`,
                    state: this.currentForm.access_token,
                    scopes: 'email.send,email.read_only',
                };

                const params = qs.stringify(payload, { encode: false });

                return `${process.env.VUE_APP_NYLAS_URL}/oauth/authorize?${params}`;
            },
        },

        watch: {
            '$route.params.id': {
                immediate: true,
                handler() {
                    this.getAccountState();
                },
            },

            validAccount(newValue) {
                this.$emit('state-changed', newValue);
            },
        },

        methods: {
            async getAccountState() {
                if (!this.$route.params.id || !this.currentForm.email_config.access_token) {
                    return;
                }

                this.loading = true;

                try {
                    const response = await this.$axios.get(`v1/users/nylas/state/${this.$route.params.id}`);

                    if (!empty(response) && response.data.data == 'running') {
                        if (this.currentForm.email_config.active) {
                            this.validAccount = 'activate';
                        } else {
                            this.validAccount = 'deactivate';
                        }
                    } else if (!empty(response) && response.data.data == 'stopped') {
                        this.validAccount = 'deactivate';
                    }

                    this.loading = false;
                } catch (error) {
                    this.loading = false;
                }
            },

            async deactivate() {
                if (!this.$route.params.id) {
                    return;
                }

                this.loading = true;

                try {
                    await this.$axios.put(`v1/users/nylas/${this.$route.params.id}`, { type: 'deactivate' });

                    const currentFormCopied = JSON.parse(JSON.stringify(this.currentForm));
                    currentFormCopied.email_config.active = false;

                    useUserCardStore().currentForm = currentFormCopied;
                    this.getAccountState();

                    this.$notify.success(this.$t('configMailClient.deactivateSuccess'));

                    this.loading = false;
                } catch (error) {
                    this.loading = false;
                }
            },

            async reactivate() {
                if (!this.$route.params.id) {
                    return;
                }

                this.loading = true;

                try {
                    const response = await this.$axios.put(`v1/users/nylas/${this.$route.params.id}`, {
                        type: 'reactivate',
                    });

                    if (response.data.data == 'error') {
                        this.$notify.warning(this.$t('configMailClient.error'));
                        this.validAccount = 'invalid';
                    } else {
                        const currentFormCopied = JSON.parse(JSON.stringify(this.currentForm));
                        currentFormCopied.email_config.active = true;

                        useUserCardStore().currentForm = currentFormCopied;
                        this.getAccountState();

                        this.$notify.success(this.$t('configMailClient.reactivateSuccess'));
                    }

                    this.loading = false;
                } catch (error) {
                    this.$notify.warning(this.$t('configMailClient.error'));
                    this.validAccount = 'invalid';
                    this.loading = false;
                }
            },
        },
    };
</script>
