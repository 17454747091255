<template>
    <div class="box | mb-6" v-if="hasSuppliers && !currentForm.child_accounts.length">
        <div class="box-header | border-b flex items-baseline">
            <h4 class="box-title">
                {{ $t('users.edit.supplierInformation') }}
            </h4>
            <icon
                name="bold/add"
                class="link-grey-light ml-2"
                :class="{'disabled': !canAddSupplier}"
                @click="add"
            />
        </div>
        <div class="box-body -mb-4">
            <div class="flex">
                <div class="flex-1">
                    <label>
                        {{ $t('users.edit.supplier') }}
                    </label>
                </div>
                <div class="flex-1 ml-8">
                    <label>
                        {{ $t('users.edit.username') }}
                    </label>
                </div>
                <div class="w-4 ml-4" />
            </div>

            <suppliers-user-row
                class="pb-4"
                :supplier="supplier"
                :identifier="index"
                :key="index"
                @delete="deleteAtIndex(index)"
                v-for="(supplier, index) in currentForm.suppliers"
            />
        </div>
    </div>
</template>

<script>
    import { cloneDeep } from 'lodash-es';
    import { mapActions, mapState } from 'pinia';
    import SuppliersUserRow from './SuppliersUserRow.vue';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useUserCardStore } from '../../store/modules/userCard/store.js';
    import { useGlobalStore } from '../../store/store.js';

    const defaultSupplier = {
        id: 0,
        state: {
            related_id: '',
        },
    };

    Object.freeze(defaultSupplier);

    export default {
        components: {
            SuppliersUserRow,
        },

        data() {
            return {
                defaultSupplier,
            };
        },

        computed: {
            ...mapState(useUserCardStore, ['currentForm']),
            ...mapState(useGlobalStore, ['authUser']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            hasSuppliers() {
                return this.contextAccount.hasSuppliersUserSetting();
            },

            canAddSupplier() {
                return this.contextAccount.suppliers.filter(supplier => {
                    return supplier.user_setting && !this.currentForm.suppliers.map(supplierUser => supplierUser.id).includes(supplier.id);
                }).length > 0;
            },
        },

        methods: {
            ...mapActions(useUserCardStore, ['updateUserProperties']),

            add() {
                if (!this.canAddSupplier) {
                    return;
                }

                const suppliers = cloneDeep(this.currentForm.suppliers);

                suppliers.push(defaultSupplier);

                this.updateUserProperties({ suppliers });
            },

            deleteAtIndex(index) {
                if (index < 0) {
                    return;
                }

                const suppliers = cloneDeep(this.currentForm.suppliers);

                suppliers.splice(index, 1);

                this.updateUserProperties({ suppliers });
            },
        },
    };
</script>
