<!-- eslint-disable vue/valid-v-bind-sync -->
<template>
    <div>
        <div class="box | mb-6">
            <div class="box-header">
                <h4 class="box-title">
                    {{ $t('users.edit.notifications') }}
                </h4>
            </div>
            <div class="box-body | p-0">
                <table class="table">
                    <tbody>
                        <!-- General -->
                        <tr class="bg-blue-500 text-white">
                            <th class="text-center">
                                {{ $t('users.edit.general') }}
                            </th>
                            <th class="text-center">
                                {{ $t('users.edit.screen') }}
                            </th>
                            <th class="text-center">
                                {{ $t('users.edit.sms') }}
                            </th>
                            <th class="text-center">
                                {{ $t('users.edit.push') }}
                            </th>
                            <th class="text-center">
                                {{ $t('users.edit.email') }}
                            </th>
                            <th class="text-center">
                                {{ $t('users.edit.frequencySituation') }}
                            </th>
                            <th class="text-center">
                                {{ $t('users.edit.notificationByDivision') }}
                            </th>
                        </tr>
                        <tr>
                            <td>
                                <div class="flex items-center">
                                    {{ $t('users.edit.assignedLeads') }}
                                    <activix-tooltip :content="$t('users.edit.assignedLeadsTooltip')">
                                        <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                    </activix-tooltip>
                                </div>
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.assignUser.screen"
                                    @input="updateUserPreferenceOptions('assignUser', 'screen', $event)"
                                />
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="restrictedNotificationsTooltip">
                                    <activix-checkbox
                                        :value="preferenceOptions.assignUser.sms"
                                        :disabled="hasRestrictedNotifications"
                                        @input="updateUserPreferenceOptions('assignUser', 'sms', $event)"
                                    />
                                </activix-tooltip>
                            </td>

                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.assignUser.push"
                                    @input="updateUserPreferenceOptions('assignUser', 'push', $event)"
                                />
                            </td>

                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.assignUser.email"
                                    @input="updateUserPreferenceOptions('assignUser', 'email', $event)"
                                />
                            </td>
                            <td class="text-center">
                                {{ $t('users.edit.anytime') }}
                            </td>
                            <td class="text-center w-56">
                                <notification-divisions notification-name="assignUser" />
                            </td>
                        </tr>
                        <tr v-if="isDirectorOrBdcDirector">
                            <td>
                                <div class="flex items-center">
                                    {{ $t('users.edit.taskEventUnassigned') }}
                                    <activix-tooltip :content="$t('users.edit.taskEventUnassignedToolTip')">
                                        <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                    </activix-tooltip>
                                </div>
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.taskEventUnassigned.screen"
                                    @input="updateUserPreferenceOptions('taskEventUnassigned', 'screen', $event)"
                                />
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="restrictedNotificationsTooltip">
                                    <activix-checkbox
                                        :value="preferenceOptions.taskEventUnassigned.sms"
                                        :disabled="hasRestrictedNotifications"
                                        @input="updateUserPreferenceOptions('taskEventUnassigned', 'sms', $event)"
                                    />
                                </activix-tooltip>
                            </td>

                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.taskEventUnassigned.push"
                                    @input="updateUserPreferenceOptions('taskEventUnassigned', 'push', $event)"
                                />
                            </td>

                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.taskEventUnassigned.email"
                                    @input="updateUserPreferenceOptions('taskEventUnassigned', 'email', $event)"
                                />
                            </td>
                            <td class="text-center">
                                {{ $t('users.edit.anytime') }}
                            </td>
                            <td class="text-center w-56">
                                <notification-divisions notification-name="taskEventUnassigned" />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="flex items-center">
                                    {{ $t('users.edit.assignationLeadFollow') }}
                                    <activix-tooltip :content="$t('users.edit.assignationLeadFollowTooltip')">
                                        <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                    </activix-tooltip>
                                </div>
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.assignLeadFollow.screen"
                                    @input="updateUserPreferenceOptions('assignLeadFollow', 'screen', $event)"
                                />
                            </td>
                            <td />
                            <td />
                            <td />
                            <td class="text-center">
                                {{ $t('users.edit.anytime') }}
                            </td>
                            <td class="text-center w-56">
                                <notification-divisions notification-name="assignLeadFollow" />
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <div class="flex items-center">
                                    {{ $t('users.edit.assignedNotice') }}
                                    <activix-tooltip :content="$t('users.edit.assignedNoticeTooltip')">
                                        <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                    </activix-tooltip>
                                </div>
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.alertCommentReply.screen"
                                    @input="updateUserPreferenceOptions('alertCommentReply', 'screen', $event)"
                                />
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="restrictedNotificationsTooltip">
                                    <activix-checkbox
                                        :value="preferenceOptions.alertCommentReply.sms"
                                        :disabled="hasRestrictedNotifications"
                                        @input="updateUserPreferenceOptions('alertCommentReply', 'sms', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.alertCommentReply.push"
                                    @input="updateUserPreferenceOptions('alertCommentReply', 'push', $event)"
                                />
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.alertCommentReply.email"
                                    @input="updateUserPreferenceOptions('alertCommentReply', 'email', $event)"
                                />
                            </td>
                            <td class="text-center">
                                {{ $t('users.edit.anytime') }}
                            </td>
                            <td class="text-center w-56" />
                        </tr>
                        <tr>
                            <td>
                                <div class="flex items-center">
                                    {{ $t('users.edit.newNoteOnAssignedLead') }}
                                    <activix-tooltip :content="$t('users.edit.newNoteOnAssignedLeadTooltip')">
                                        <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                    </activix-tooltip>
                                </div>
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.newNoteOnAssignedLead.screen"
                                    @input="updateUserPreferenceOptions('newNoteOnAssignedLead', 'screen', $event)"
                                />
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="restrictedNotificationsTooltip">
                                    <activix-checkbox
                                        :value="preferenceOptions.newNoteOnAssignedLead.sms"
                                        :disabled="hasRestrictedNotifications"
                                        @input="updateUserPreferenceOptions('newNoteOnAssignedLead', 'sms', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.newNoteOnAssignedLead.push"
                                    @input="updateUserPreferenceOptions('newNoteOnAssignedLead', 'push', $event)"
                                />
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.newNoteOnAssignedLead.email"
                                    @input="updateUserPreferenceOptions('newNoteOnAssignedLead', 'email', $event)"
                                />
                            </td>
                            <td class="text-center">
                                {{ $t('users.edit.anytime') }}
                            </td>
                            <td class="text-center w-56" />
                        </tr>
                        <tr>
                            <td>
                                <div class="flex items-center">
                                    {{ $t('users.edit.clientAnswer') }}
                                    <activix-tooltip :content="$t('users.edit.clientAnswerTooltip')">
                                        <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                    </activix-tooltip>
                                </div>
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.clientResponse.screen"
                                    @input="updateUserPreferenceOptions('clientResponse', 'screen', $event)"
                                />
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="restrictedNotificationsTooltip">
                                    <activix-checkbox
                                        :value="preferenceOptions.clientResponse.sms"
                                        :disabled="hasRestrictedNotifications"
                                        @input="updateUserPreferenceOptions('clientResponse', 'sms', $event)"
                                    />
                                </activix-tooltip>
                            </td>

                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.clientResponse.push"
                                    @input="updateUserPreferenceOptions('clientResponse', 'push', $event)"
                                />
                            </td>

                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.clientResponse.email"
                                    @input="updateUserPreferenceOptions('clientResponse', 'email', $event)"
                                    v-if="!currentForm.email_config.active"
                                />
                            </td>
                            <td class="text-center">
                                {{ $t('users.edit.anytime') }}
                            </td>
                            <td class="text-center w-56" />
                        </tr>
                        <tr v-if="!empty(currentForm.role) && !Role.hasAdvisorSkills(currentForm.role.id)">
                            <td>
                                <div class="flex items-center">
                                    {{ $t('users.edit.obtainedMeeting') }}
                                    <activix-tooltip :content="$t('users.edit.obtainedMeetingTooltip')">
                                        <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                    </activix-tooltip>
                                </div>
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.appointment.screen"
                                    @input="updateUserPreferenceOptions('appointment', 'screen', $event)"
                                />
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="restrictedNotificationsTooltip">
                                    <activix-checkbox
                                        :value="preferenceOptions.appointment.sms"
                                        :disabled="hasRestrictedNotifications"
                                        @input="updateUserPreferenceOptions('appointment', 'sms', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.appointment.push"
                                    @input="updateUserPreferenceOptions('appointment', 'push', $event)"
                                />
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.appointment.email"
                                    @input="updateUserPreferenceOptions('appointment', 'email', $event)"
                                />
                            </td>
                            <td class="text-center">
                                {{ $t('users.edit.anytime') }}
                            </td>
                            <td class="text-center w-56">
                                <notification-divisions notification-name="appointment" />
                            </td>
                        </tr>
                        <tr v-if="!hasOnlyServiceDivision">
                            <td>
                                <div class="flex items-center">
                                    {{ $t('users.edit.obtainedSale') }}
                                    <activix-tooltip :content="generateTooltip('obtainedSale')">
                                        <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                    </activix-tooltip>
                                </div>
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.sale.screen"
                                    @input="updateUserPreferenceOptions('sale', 'screen', $event)"
                                />
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="restrictedNotificationsTooltip">
                                    <activix-checkbox
                                        :value="preferenceOptions.sale.sms"
                                        :disabled="hasRestrictedNotifications"
                                        @input="updateUserPreferenceOptions('sale', 'sms', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.sale.push"
                                    @input="updateUserPreferenceOptions('sale', 'push', $event)"
                                />
                            </td>

                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.sale.email"
                                    @input="updateUserPreferenceOptions('sale', 'email', $event)"
                                />
                            </td>
                            <td class="text-center">
                                {{ $t('users.edit.anytime') }}
                            </td>
                            <td class="text-center w-56">
                                <notification-divisions notification-name="sale" />
                            </td>
                        </tr>
                        <tr v-if="!hasOnlyServiceDivision">
                            <td>
                                <div class="flex items-center">
                                    {{ $t('users.edit.deposit') }}
                                    <activix-tooltip :content="generateTooltip('deposit')">
                                        <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                    </activix-tooltip>
                                </div>
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.deposit.screen"
                                    @input="updateUserPreferenceOptions('deposit', 'screen', $event)"
                                />
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="restrictedNotificationsTooltip">
                                    <activix-checkbox
                                        :value="preferenceOptions.deposit.sms"
                                        :disabled="hasRestrictedNotifications"
                                        @input="updateUserPreferenceOptions('deposit', 'sms', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.deposit.push"
                                    @input="updateUserPreferenceOptions('deposit', 'push', $event)"
                                />
                            </td>

                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.deposit.email"
                                    @input="updateUserPreferenceOptions('deposit', 'email', $event)"
                                />
                            </td>
                            <td class="text-center">
                                {{ $t('users.edit.anytime') }}
                            </td>
                            <td class="text-center w-56">
                                <notification-divisions notification-name="deposit" />
                            </td>
                        </tr>
                        <tr v-if="!hasOnlyServiceDivision">
                            <td>
                                <div class="flex items-center">
                                    {{ $t('users.edit.stateChanged') }}
                                    <activix-tooltip :content="$t('users.edit.stateChangedTooltip')">
                                        <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                    </activix-tooltip>
                                </div>
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.stateChanged.screen"
                                    @input="updateUserPreferenceOptions('stateChanged', 'screen', $event)"
                                />
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="restrictedNotificationsTooltip">
                                    <activix-checkbox
                                        :value="preferenceOptions.stateChanged.sms"
                                        :disabled="hasRestrictedNotifications"
                                        @input="updateUserPreferenceOptions('stateChanged', 'sms', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.stateChanged.push"
                                    @input="updateUserPreferenceOptions('stateChanged', 'push', $event)"
                                />
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.stateChanged.email"
                                    @input="updateUserPreferenceOptions('stateChanged', 'email', $event)"
                                />
                            </td>
                            <td class="text-center">
                                {{ $t('users.edit.anytime') }}
                            </td>
                            <td class="text-center w-56">
                                <notification-divisions notification-name="stateChanged" />
                            </td>
                        </tr>
                        <tr v-if="!hasOnlyServiceDivision">
                            <td>
                                <div class="flex items-center">
                                    {{ $t('users.edit.approved') }}
                                    <activix-tooltip :content="generateTooltip('approved')">
                                        <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                    </activix-tooltip>
                                </div>
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.approved.screen"
                                    @input="updateUserPreferenceOptions('approved', 'screen', $event)"
                                />
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="restrictedNotificationsTooltip">
                                    <activix-checkbox
                                        :value="preferenceOptions.approved.sms"
                                        :disabled="hasRestrictedNotifications"
                                        @input="updateUserPreferenceOptions('approved', 'sms', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.approved.push"
                                    @input="updateUserPreferenceOptions('approved', 'push', $event)"
                                />
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.approved.email"
                                    @input="updateUserPreferenceOptions('approved', 'email', $event)"
                                />
                            </td>
                            <td class="text-center">
                                {{ $t('users.edit.anytime') }}
                            </td>
                            <td class="text-center w-56">
                                <notification-divisions notification-name="approved" />
                            </td>
                        </tr>
                        <tr v-if="!hasOnlyServiceDivision">
                            <td>
                                <div class="flex items-center">
                                    {{ $t('users.edit.availableDate') }}
                                    <activix-tooltip :content="generateTooltip('availableDate')">
                                        <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                    </activix-tooltip>
                                </div>
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.availableDate.screen"
                                    @input="updateUserPreferenceOptions('availableDate', 'screen', $event)"
                                />
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="restrictedNotificationsTooltip">
                                    <activix-checkbox
                                        :value="preferenceOptions.availableDate.sms"
                                        :disabled="hasRestrictedNotifications"
                                        @input="updateUserPreferenceOptions('availableDate', 'sms', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.availableDate.push"
                                    @input="updateUserPreferenceOptions('availableDate', 'push', $event)"
                                />
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.availableDate.email"
                                    @input="updateUserPreferenceOptions('availableDate', 'email', $event)"
                                />
                            </td>
                            <td class="text-center">
                                {{ $t('users.edit.anytime') }}
                            </td>
                            <td class="text-center w-56">
                                <notification-divisions notification-name="availableDate" />
                            </td>
                        </tr>
                        <tr v-if="!hasOnlyServiceDivision">
                            <td>
                                <div class="flex items-center">
                                    {{ $t('users.edit.paperwork') }}
                                    <activix-tooltip :content="generateTooltip('paperworkDate')">
                                        <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                    </activix-tooltip>
                                </div>
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.paperworkDate.screen"
                                    @input="updateUserPreferenceOptions('paperworkDate', 'screen', $event)"
                                />
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="restrictedNotificationsTooltip">
                                    <activix-checkbox
                                        :value="preferenceOptions.paperworkDate.sms"
                                        :disabled="hasRestrictedNotifications"
                                        @input="updateUserPreferenceOptions('paperworkDate', 'sms', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.paperworkDate.push"
                                    @input="updateUserPreferenceOptions('paperworkDate', 'push', $event)"
                                />
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.paperworkDate.email"
                                    @input="updateUserPreferenceOptions('paperworkDate', 'email', $event)"
                                />
                            </td>
                            <td class="text-center">
                                {{ $t('users.edit.anytime') }}
                            </td>
                            <td class="text-center w-56">
                                <notification-divisions notification-name="paperworkDate" />
                            </td>
                        </tr>

                        <tr v-if="!hasOnlyServiceDivision">
                            <td>
                                <div class="flex items-center">
                                    {{ $t('users.edit.prepared') }}
                                    <activix-tooltip :content="generateTooltip('prepared')">
                                        <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                    </activix-tooltip>
                                </div>
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.prepared.screen"
                                    @input="updateUserPreferenceOptions('prepared', 'screen', $event)"
                                />
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="restrictedNotificationsTooltip">
                                    <activix-checkbox
                                        :value="preferenceOptions.prepared.sms"
                                        :disabled="hasRestrictedNotifications"
                                        @input="updateUserPreferenceOptions('prepared', 'sms', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.prepared.push"
                                    @input="updateUserPreferenceOptions('prepared', 'push', $event)"
                                />
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.prepared.email"
                                    @input="updateUserPreferenceOptions('prepared', 'email', $event)"
                                />
                            </td>
                            <td class="text-center">
                                {{ $t('users.edit.anytime') }}
                            </td>
                            <td class="text-center w-56">
                                <notification-divisions notification-name="prepared" />
                            </td>
                        </tr>
                        <tr v-if="!hasOnlyServiceDivision">
                            <td>
                                <div class="flex items-center">
                                    {{ $t('users.edit.inspected') }}
                                    <activix-tooltip :content="generateTooltip('inspected')">
                                        <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                    </activix-tooltip>
                                </div>
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.inspected.screen"
                                    @input="updateUserPreferenceOptions('inspected', 'screen', $event)"
                                />
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="restrictedNotificationsTooltip">
                                    <activix-checkbox
                                        :value="preferenceOptions.inspected.sms"
                                        :disabled="hasRestrictedNotifications"
                                        @input="updateUserPreferenceOptions('inspected', 'sms', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.inspected.push"
                                    @input="updateUserPreferenceOptions('inspected', 'push', $event)"
                                />
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.inspected.email"
                                    @input="updateUserPreferenceOptions('inspected', 'email', $event)"
                                />
                            </td>
                            <td class="text-center">
                                {{ $t('users.edit.anytime') }}
                            </td>
                            <td class="text-center w-56">
                                <notification-divisions notification-name="inspected" />
                            </td>
                        </tr>
                        <tr v-if="!hasOnlyServiceDivision">
                            <td>
                                <div class="flex items-center">
                                    {{ $t('users.edit.gas') }}
                                    <activix-tooltip :content="generateTooltip('gas')">
                                        <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                    </activix-tooltip>
                                </div>
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.gas.screen"
                                    @input="updateUserPreferenceOptions('gas', 'screen', $event)"
                                />
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="restrictedNotificationsTooltip">
                                    <activix-checkbox
                                        :value="preferenceOptions.gas.sms"
                                        :disabled="hasRestrictedNotifications"
                                        @input="updateUserPreferenceOptions('gas', 'sms', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.gas.push"
                                    @input="updateUserPreferenceOptions('gas', 'push', $event)"
                                />
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.gas.email"
                                    @input="updateUserPreferenceOptions('gas', 'email', $event)"
                                />
                            </td>
                            <td class="text-center">
                                {{ $t('users.edit.anytime') }}
                            </td>
                            <td class="text-center w-56">
                                <notification-divisions notification-name="gas" />
                            </td>
                        </tr>
                        <tr v-if="!hasOnlyServiceDivision">
                            <td>
                                <div class="flex items-center">
                                    {{ $t('users.edit.delivered') }}
                                    <activix-tooltip :content="generateTooltip('delivered')">
                                        <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                    </activix-tooltip>
                                </div>
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.delivered.screen"
                                    @input="updateUserPreferenceOptions('delivered', 'screen', $event)"
                                />
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="restrictedNotificationsTooltip">
                                    <activix-checkbox
                                        :value="preferenceOptions.delivered.sms"
                                        :disabled="hasRestrictedNotifications"
                                        @input="updateUserPreferenceOptions('delivered', 'sms', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.delivered.push"
                                    @input="updateUserPreferenceOptions('delivered', 'push', $event)"
                                />
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.delivered.email"
                                    @input="updateUserPreferenceOptions('delivered', 'email', $event)"
                                />
                            </td>
                            <td class="text-center">
                                {{ $t('users.edit.anytime') }}
                            </td>
                            <td class="text-center w-56">
                                <notification-divisions notification-name="delivered" />
                            </td>
                        </tr>
                        <tr v-if="!hasOnlyServiceDivision">
                            <td>
                                <div class="flex items-center">
                                    {{ $t('users.edit.deliveryDate') }}
                                    <activix-tooltip :content="generateTooltip('deliveryDate')">
                                        <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                    </activix-tooltip>
                                </div>
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.deliveryDate.screen"
                                    @input="updateUserPreferenceOptions('deliveryDate', 'screen', $event)"
                                />
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="restrictedNotificationsTooltip">
                                    <activix-checkbox
                                        :value="preferenceOptions.deliveryDate.sms"
                                        :disabled="hasRestrictedNotifications"
                                        @input="updateUserPreferenceOptions('deliveryDate', 'sms', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.deliveryDate.push"
                                    @input="updateUserPreferenceOptions('deliveryDate', 'push', $event)"
                                />
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.deliveryDate.email"
                                    @input="updateUserPreferenceOptions('deliveryDate', 'email', $event)"
                                />
                            </td>
                            <td class="text-center">
                                {{ $t('users.edit.anytime') }}
                            </td>
                            <td class="text-center w-56">
                                <notification-divisions notification-name="deliveryDate" />
                            </td>
                        </tr>
                        <tr v-if="!empty(currentForm.role) && !Role.hasAdvisorSkills(currentForm.role.id)">
                            <td>
                                <div class="flex items-center">
                                    {{ $t('users.edit.lostAlert') }}
                                    <activix-tooltip :content="$t('users.edit.lostAlertTooltip')">
                                        <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                    </activix-tooltip>
                                </div>
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.lostAlert.screen"
                                    @input="updateUserPreferenceOptions('lostAlert', 'screen', $event)"
                                />
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="restrictedNotificationsTooltip">
                                    <activix-checkbox
                                        :value="preferenceOptions.lostAlert.sms"
                                        :disabled="hasRestrictedNotifications"
                                        @input="updateUserPreferenceOptions('lostAlert', 'sms', $event)"
                                    />
                                </activix-tooltip>
                            </td>

                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.lostAlert.push"
                                    @input="updateUserPreferenceOptions('lostAlert', 'push', $event)"
                                />
                            </td>

                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.lostAlert.email"
                                    @input="updateUserPreferenceOptions('lostAlert', 'email', $event)"
                                />
                            </td>
                            <td class="text-center">
                                {{ $t('users.edit.anytime') }}
                            </td>
                            <td class="text-center w-56">
                                <notification-divisions notification-name="lostAlert" />
                            </td>
                        </tr>
                        <tr v-if="!empty(currentForm.role) && !Role.hasAdvisorSkills(currentForm.role.id)">
                            <td>
                                <div class="flex items-center">
                                    {{ $t('users.edit.invalidAlert') }}
                                    <activix-tooltip :content="$t('users.edit.invalidAlertTooltip')">
                                        <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                    </activix-tooltip>
                                </div>
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.invalidAlert.screen"
                                    @input="updateUserPreferenceOptions('invalidAlert', 'screen', $event)"
                                />
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="restrictedNotificationsTooltip">
                                    <activix-checkbox
                                        :value="preferenceOptions.invalidAlert.sms"
                                        :disabled="hasRestrictedNotifications"
                                        @input="updateUserPreferenceOptions('invalidAlert', 'sms', $event)"
                                    />
                                </activix-tooltip>
                            </td>

                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.invalidAlert.push"
                                    @input="updateUserPreferenceOptions('invalidAlert', 'push', $event)"
                                />
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.invalidAlert.email"
                                    @input="updateUserPreferenceOptions('invalidAlert', 'email', $event)"
                                />
                            </td>
                            <td class="text-center">
                                {{ $t('users.edit.anytime') }}
                            </td>
                            <td class="text-center w-56">
                                <notification-divisions notification-name="invalidAlert" />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="flex items-center">
                                    {{ $t('users.edit.commentFollow') }}
                                    <activix-tooltip :content="$t('users.edit.commentFollowTooltip')">
                                        <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                    </activix-tooltip>
                                </div>
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.commentFollow.screen"
                                    @input="updateUserPreferenceOptions('commentFollow', 'screen', $event)"
                                />
                            </td>
                            <td />
                            <td />
                            <td />
                            <td class="text-center">
                                {{ $t('users.edit.anytime') }}
                            </td>
                            <td class="text-center w-56">
                                <notification-divisions notification-name="commentFollow" />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="flex items-center">
                                    {{ $t('users.edit.emailReadAlert') }}
                                    <activix-tooltip
                                        :content="
                                            imapIsActive
                                                ? $t('users.edit.emailReadDisabled')
                                                : $t('users.edit.emailReadDescription')
                                        "
                                    >
                                        <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                    </activix-tooltip>
                                </div>
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.emailRead.screen"
                                    :disabled="imapIsActive"
                                    @input="updateUserPreferenceOptions('emailRead', 'screen', $event)"
                                />
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="restrictedNotificationsTooltip">
                                    <activix-checkbox
                                        :value="preferenceOptions.emailRead.sms"
                                        :disabled="imapIsActive || hasRestrictedNotifications"
                                        @input="updateUserPreferenceOptions('emailRead', 'sms', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.emailRead.push"
                                    :disabled="imapIsActive"
                                    @input="updateUserPreferenceOptions('emailRead', 'push', $event)"
                                />
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.emailRead.email"
                                    :disabled="imapIsActive"
                                    @input="updateUserPreferenceOptions('emailRead', 'email', $event)"
                                />
                            </td>
                            <td class="text-center">
                                {{ $t('users.edit.anytime') }}
                            </td>
                            <td class="text-center w-56" />
                        </tr>
                        <tr>
                            <td>
                                <div class="flex items-center">
                                    {{ $t('users.edit.assignedLeadUpdated') }}
                                    <activix-tooltip :content="$t('users.edit.assignedLeadUpdatedDescription')">
                                        <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                    </activix-tooltip>
                                </div>
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.assignedLeadUpdated.screen"
                                    @input="updateUserPreferenceOptions('assignedLeadUpdated', 'screen', $event)"
                                />
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="restrictedNotificationsTooltip">
                                    <activix-checkbox
                                        :value="preferenceOptions.assignedLeadUpdated.sms"
                                        :disabled="hasRestrictedNotifications"
                                        @input="updateUserPreferenceOptions('assignedLeadUpdated', 'sms', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.assignedLeadUpdated.push"
                                    @input="updateUserPreferenceOptions('assignedLeadUpdated', 'push', $event)"
                                />
                            </td>
                            <td class="text-center" data-toggle="tooltip" data-container="body">
                                <activix-checkbox
                                    :value="preferenceOptions.assignedLeadUpdated.email"
                                    @input="updateUserPreferenceOptions('assignedLeadUpdated', 'email', $event)"
                                />
                            </td>
                            <td class="text-center">
                                {{ $t('users.edit.anytime') }}
                            </td>
                            <td class="text-center w-56">
                                <notification-divisions notification-name="assignedLeadUpdated" />
                            </td>
                        </tr>
                        <tr v-if="isDirectorOrBdcDirector || isAdvisorOrBdcAgent">
                            <td>
                                <div class="flex items-center">
                                    {{ $t('users.edit.reassignationLeadDemand') }}
                                    <activix-tooltip :content="$t('users.edit.reassignationLeadDemandTooltip')">
                                        <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                    </activix-tooltip>
                                </div>
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.assignLeadDemand.screen"
                                    @input="updateUserPreferenceOptions('assignLeadDemand', 'screen', $event)"
                                />
                            </td>

                            <td class="text-center">
                                <activix-tooltip :content="restrictedNotificationsTooltip">
                                    <activix-checkbox
                                        :value="preferenceOptions.assignLeadDemand.sms"
                                        :disabled="hasRestrictedNotifications"
                                        @input="updateUserPreferenceOptions('assignLeadDemand', 'sms', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.assignLeadDemand.push"
                                    @input="updateUserPreferenceOptions('assignLeadDemand', 'push', $event)"
                                />
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.assignLeadDemand.email"
                                    @input="updateUserPreferenceOptions('assignLeadDemand', 'email', $event)"
                                />
                            </td>
                            <td class="text-center">
                                {{ $t('users.edit.anytime') }}
                            </td>
                            <td class="text-center w-56">
                                <notification-divisions notification-name="assignLeadDemand" />
                            </td>
                        </tr>
                        <tr v-if="isAdvisorOrBdcAgent">
                            <td>
                                <div class="flex items-center">
                                    {{ $t('users.edit.declinedAssignationLead') }}
                                    <activix-tooltip :content="$t('users.edit.declinedAssignationLeadTooltip')">
                                        <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                    </activix-tooltip>
                                </div>
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.declinedLeadDemand.screen"
                                    @input="updateUserPreferenceOptions('declinedLeadDemand', 'screen', $event)"
                                />
                            </td>

                            <td class="text-center">
                                <activix-tooltip :content="restrictedNotificationsTooltip">
                                    <activix-checkbox
                                        :value="preferenceOptions.declinedLeadDemand.sms"
                                        :disabled="hasRestrictedNotifications"
                                        @input="updateUserPreferenceOptions('declinedLeadDemand', 'sms', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.declinedLeadDemand.push"
                                    @input="updateUserPreferenceOptions('declinedLeadDemand', 'push', $event)"
                                />
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.declinedLeadDemand.email"
                                    @input="updateUserPreferenceOptions('declinedLeadDemand', 'email', $event)"
                                />
                            </td>

                            <td class="text-center">
                                {{ $t('users.edit.anytime') }}
                            </td>
                            <td class="text-center w-56">
                                <notification-divisions notification-name="declinedLeadDemand" />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="flex items-center">
                                    {{ $t('users.edit.taskEventDatesUpdated') }}
                                </div>
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.taskEventDatesUpdated.screen"
                                    @input="updateUserPreferenceOptions('taskEventDatesUpdated', 'screen', $event)"
                                />
                            </td>

                            <td class="text-center">
                                <activix-tooltip :content="restrictedNotificationsTooltip">
                                    <activix-checkbox
                                        :value="preferenceOptions.taskEventDatesUpdated.sms"
                                        :disabled="hasRestrictedNotifications"
                                        @input="updateUserPreferenceOptions('taskEventDatesUpdated', 'sms', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.taskEventDatesUpdated.push"
                                    @input="updateUserPreferenceOptions('taskEventDatesUpdated', 'push', $event)"
                                />
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.taskEventDatesUpdated.email"
                                    @input="updateUserPreferenceOptions('taskEventDatesUpdated', 'email', $event)"
                                />
                            </td>

                            <td class="text-center">
                                {{ $t('users.edit.anytime') }}
                            </td>

                            <td class="text-center w-56">
                                <notification-divisions notification-name="taskEventDatesUpdated" />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="flex items-center">
                                    {{ $t('users.edit.taskEventStatusUpdated') }}
                                </div>
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.taskEventStatusUpdated.screen"
                                    @input="updateUserPreferenceOptions('taskEventStatusUpdated', 'screen', $event)"
                                />
                            </td>

                            <td class="text-center">
                                <activix-tooltip :content="restrictedNotificationsTooltip">
                                    <activix-checkbox
                                        :value="preferenceOptions.taskEventStatusUpdated.sms"
                                        :disabled="hasRestrictedNotifications"
                                        @input="updateUserPreferenceOptions('taskEventStatusUpdated', 'sms', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.taskEventStatusUpdated.push"
                                    @input="updateUserPreferenceOptions('taskEventStatusUpdated', 'push', $event)"
                                />
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.taskEventStatusUpdated.email"
                                    @input="updateUserPreferenceOptions('taskEventStatusUpdated', 'email', $event)"
                                />
                            </td>

                            <td class="text-center">
                                {{ $t('users.edit.anytime') }}
                            </td>

                            <td class="text-center w-56">
                                <notification-divisions notification-name="taskEventStatusUpdated" />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="flex items-center">
                                    {{ $t('users.edit.taskDeleted') }}
                                </div>
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.taskDeleted.screen"
                                    @input="updateUserPreferenceOptions('taskDeleted', 'screen', $event)"
                                />
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="restrictedNotificationsTooltip">
                                    <activix-checkbox
                                        :value="preferenceOptions.taskDeleted.sms"
                                        :disabled="hasRestrictedNotifications"
                                        @input="updateUserPreferenceOptions('taskDeleted', 'sms', $event)"
                                    />
                                </activix-tooltip>
                            </td>

                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.taskDeleted.push"
                                    @input="updateUserPreferenceOptions('taskDeleted', 'push', $event)"
                                />
                            </td>

                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.taskDeleted.email"
                                    @input="updateUserPreferenceOptions('taskDeleted', 'email', $event)"
                                />
                            </td>
                            <td class="text-center">
                                {{ $t('users.edit.anytime') }}
                            </td>
                            <td class="text-center w-56">
                                <notification-divisions notification-name="taskDeleted" />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="flex items-center">
                                    {{ $t('users.edit.mentions') }}
                                    <activix-tooltip :content="$t('users.edit.mentionsTooltip')">
                                        <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                    </activix-tooltip>
                                </div>
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.mentions.screen"
                                    @input="updateUserPreferenceOptions('mentions', 'screen', $event)"
                                />

                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="restrictedNotificationsTooltip || generateTooltip('mentions', 'sms')">
                                    <activix-checkbox
                                        :value="preferenceOptions.mentions.sms"
                                        :disabled="hasRestrictedNotifications || mustKeepOneExternalMedium('mentions', 'sms')"
                                        @input="updateUserPreferenceOptions('mentions', 'sms', $event)"
                                    />
                                </activix-tooltip>
                            </td>

                            <td class="text-center">
                                <activix-tooltip :content="generateTooltip('mentions', 'push')">
                                    <activix-checkbox
                                        :value="preferenceOptions.mentions.push"
                                        :disabled="!contextUser.last_mobile_heartbeat_at || mustKeepOneExternalMedium('mentions', 'push')"
                                        @input="updateUserPreferenceOptions('mentions', 'push', $event)"
                                    />
                                </activix-tooltip>
                            </td>

                            <td class="text-center">
                                <activix-tooltip :content="generateTooltip('mentions', 'email')">
                                    <activix-checkbox
                                        :value="preferenceOptions.mentions.email"
                                        :disabled="mustKeepOneExternalMedium('mentions', 'email')"
                                        @input="updateUserPreferenceOptions('mentions', 'email', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                {{ $t('users.edit.anytime') }}
                            </td>
                            <td class="text-center w-56">
                                <notification-divisions notification-name="mentions" />
                            </td>
                        </tr>
                        <tr v-if="isAuthDirectorOrBdcDirector">
                            <td>
                                <div class="flex items-center">
                                    {{ $t('users.edit.feedback') }}
                                    <activix-tooltip :content="$t('users.edit.feedbackTooltip')">
                                        <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                    </activix-tooltip>
                                </div>
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.feedbackCalculator.screen"
                                    @input="updateUserPreferenceOptions('feedbackCalculator', 'screen', $event)"
                                />

                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="restrictedNotificationsTooltip">
                                    <activix-checkbox
                                        :value="preferenceOptions.feedbackCalculator.sms"
                                        :disabled="hasRestrictedNotifications"
                                        @input="updateUserPreferenceOptions('feedbackCalculator', 'sms', $event)"
                                    />
                                </activix-tooltip>
                            </td>

                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.feedbackCalculator.push"
                                    @input="updateUserPreferenceOptions('feedbackCalculator', 'push', $event)"
                                />
                            </td>

                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.feedbackCalculator.email"
                                    @input="updateUserPreferenceOptions('feedbackCalculator', 'email', $event)"
                                />
                            </td>
                            <td class="text-center">
                                {{ $t('users.edit.anytime') }}
                            </td>
                            <td class="text-center w-56">
                                <notification-divisions notification-name="feedbackCalculator" />
                            </td>
                        </tr>

                        <!-- WebBoost -->
                        <tr
                            class="text-white"
                            :class="
                                !currentForm.module_access.web_boost || !contextAccount.hasOption('webboost')
                                    ? 'bg-blue-400'
                                    : 'bg-blue-500'
                            "
                        >
                            <th class="text-center">
                                {{ $t('users.edit.webBoost') }}
                            </th>
                            <th class="text-center">
                                {{ $t('users.edit.screen') }}
                            </th>
                            <th class="text-center">
                                {{ $t('users.edit.sms') }}
                            </th>
                            <th class="text-center">
                                {{ $t('users.edit.push') }}
                            </th>
                            <th class="text-center">
                                {{ $t('users.edit.email') }}
                            </th>
                            <th class="text-center">
                                {{ $t('users.edit.frequencySituation') }}
                            </th>
                            <th class="text-center">
                                {{ $t('users.edit.notificationByDivision') }}
                            </th>
                        </tr>

                        <tr
                            :class="{
                                'opacity-50':
                                    !currentForm.module_access.web_boost || !contextAccount.hasOption('webboost'),
                            }"
                        >
                            <td>
                                <div class="flex items-center">
                                    {{ $t('users.edit.leadsWithoutPhone') }}
                                    <activix-tooltip :content="$t('users.edit.leadsWithoutPhoneDescription')">
                                        <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                    </activix-tooltip>
                                </div>
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="generateTooltip('webBoost')">
                                    <activix-checkbox
                                        :value="preferenceOptions.noPhoneAlert.screen"
                                        :disabled="
                                            !currentForm.module_access.web_boost ||
                                                !contextAccount.hasOption('webboost') ||
                                                hasOnlyServiceDivision
                                        "
                                        @input="updateUserPreferenceOptions('noPhoneAlert', 'screen', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-tooltip
                                    :content="generateTooltip('webBoost') || restrictedNotificationsTooltip"
                                >
                                    <activix-checkbox
                                        :value="preferenceOptions.noPhoneAlert.sms"
                                        :disabled="
                                            !currentForm.module_access.web_boost ||
                                                !contextAccount.hasOption('webboost') ||
                                                hasOnlyServiceDivision ||
                                                hasRestrictedNotifications
                                        "
                                        @input="updateUserPreferenceOptions('noPhoneAlert', 'sms', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="generateTooltip('webBoost')">
                                    <activix-checkbox
                                        :value="preferenceOptions.noPhoneAlert.push"
                                        :disabled="
                                            !currentForm.module_access.web_boost ||
                                                !contextAccount.hasOption('webboost') ||
                                                hasOnlyServiceDivision
                                        "
                                        @input="updateUserPreferenceOptions('noPhoneAlert', 'push', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="generateTooltip('webBoost')">
                                    <activix-checkbox
                                        :value="preferenceOptions.noPhoneAlert.email"
                                        :disabled="
                                            !currentForm.module_access.web_boost ||
                                                !contextAccount.hasOption('webboost') ||
                                                hasOnlyServiceDivision
                                        "
                                        @input="updateUserPreferenceOptions('noPhoneAlert', 'email', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <div class="flex items-center justify-center">
                                    <activix-multiselect
                                        class="max-w-64"
                                        label="value"
                                        :disabled="
                                            !currentForm.module_access.web_boost ||
                                                !contextAccount.hasOption('webboost') ||
                                                hasOnlyServiceDivision
                                        "
                                        :selected="
                                            defaultScheduleOptions.find(
                                                option => option.id == preferenceOptions.noPhoneAlert.availability,
                                            )
                                        "
                                        :options="defaultScheduleOptions"
                                        :searchable="false"
                                        :allow-empty="false"
                                        @update="openFrequencyModal('noPhoneAlert', $event)"
                                    />
                                </div>
                            </td>
                            <td class="text-center w-56">
                                <notification-divisions
                                    notification-name="noPhoneAlert"
                                    :disabled="
                                        !currentForm.module_access.web_boost ||
                                            !contextAccount.hasOption('webboost') ||
                                            hasOnlyServiceDivision
                                    "
                                />
                            </td>
                        </tr>
                        <tr
                            :class="{
                                'opacity-50':
                                    !currentForm.module_access.web_boost ||
                                    !contextAccount.hasOption('webboost') ||
                                    hasOnlyServiceDivision,
                            }"
                        >
                            <td>
                                <div class="flex items-center">
                                    {{ $t('users.edit.answeredLeads') }}
                                    <activix-tooltip :content="$t('users.edit.answeredLeadsDesc')">
                                        <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                    </activix-tooltip>
                                </div>
                            </td>
                            <td />
                            <td class="text-center">
                                <activix-tooltip
                                    :content="generateTooltip('webBoost') || restrictedNotificationsTooltip"
                                >
                                    <activix-checkbox
                                        :value="preferenceOptions.takeOver.sms"
                                        :disabled="
                                            !currentForm.module_access.web_boost ||
                                                !contextAccount.hasOption('webboost') ||
                                                hasOnlyServiceDivision ||
                                                hasRestrictedNotifications
                                        "
                                        @input="updateUserPreferenceOptions('takeOver', 'sms', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="generateTooltip('webBoost')">
                                    <activix-checkbox
                                        :value="preferenceOptions.takeOver.push"
                                        :disabled="
                                            !currentForm.module_access.web_boost ||
                                                !contextAccount.hasOption('webboost') ||
                                                hasOnlyServiceDivision
                                        "
                                        @input="updateUserPreferenceOptions('takeOver', 'push', $event)"
                                    />
                                </activix-tooltip>
                            </td>

                            <activix-tooltip :content="generateTooltip('webBoost')">
                                <td class="text-center">
                                    <activix-checkbox
                                        :value="preferenceOptions.takeOver.email"
                                        :disabled="
                                            !currentForm.module_access.web_boost ||
                                                !contextAccount.hasOption('webboost') ||
                                                hasOnlyServiceDivision
                                        "
                                        @input="updateUserPreferenceOptions('takeOver', 'email', $event)"
                                    />
                                </td>
                            </activix-tooltip>
                            <td class="text-center">
                                <div class="flex items-center justify-center">
                                    <activix-multiselect
                                        class="max-w-64"
                                        label="value"
                                        :disabled="
                                            !currentForm.module_access.web_boost ||
                                                !contextAccount.hasOption('webboost') ||
                                                hasOnlyServiceDivision
                                        "
                                        :selected="
                                            defaultScheduleOptions.find(
                                                option => option.id == preferenceOptions.takeOver.availability,
                                            )
                                        "
                                        :options="defaultScheduleOptions"
                                        :searchable="false"
                                        :allow-empty="false"
                                        @update="openFrequencyModal('takeOver', $event)"
                                    />
                                </div>
                            </td>
                            <td class="text-center w-56">
                                <notification-divisions
                                    notification-name="takeOver"
                                    :disabled="
                                        !currentForm.module_access.web_boost ||
                                            !contextAccount.hasOption('webboost') ||
                                            hasOnlyServiceDivision
                                    "
                                />
                            </td>
                        </tr>
                        <tr
                            :class="{
                                'opacity-50':
                                    !currentForm.module_access.web_boost || !contextAccount.hasOption('webboost'),
                            }"
                        >
                            <td>
                                {{ $t('users.edit.leadsWithoutDivision') }}
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="generateTooltip('webBoost')">
                                    <activix-checkbox
                                        :value="preferenceOptions.noDivision.screen"
                                        :disabled="
                                            !currentForm.module_access.web_boost ||
                                                !contextAccount.hasOption('webboost') ||
                                                hasOnlyServiceDivision
                                        "
                                        @input="updateUserPreferenceOptions('noDivision', 'screen', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-tooltip
                                    :content="generateTooltip('webBoost') || restrictedNotificationsTooltip"
                                >
                                    <activix-checkbox
                                        :value="preferenceOptions.noDivision.sms"
                                        :disabled="
                                            !currentForm.module_access.web_boost ||
                                                !contextAccount.hasOption('webboost') ||
                                                hasOnlyServiceDivision ||
                                                hasRestrictedNotifications
                                        "
                                        @input="updateUserPreferenceOptions('noDivision', 'sms', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="generateTooltip('webBoost')">
                                    <activix-checkbox
                                        :value="preferenceOptions.noDivision.push"
                                        :disabled="
                                            !currentForm.module_access.web_boost ||
                                                !contextAccount.hasOption('webboost') ||
                                                hasOnlyServiceDivision
                                        "
                                        @input="updateUserPreferenceOptions('noDivision', 'push', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="generateTooltip('webBoost')">
                                    <activix-checkbox
                                        :value="preferenceOptions.noDivision.email"
                                        :disabled="
                                            !currentForm.module_access.web_boost ||
                                                !contextAccount.hasOption('webboost') ||
                                                hasOnlyServiceDivision
                                        "
                                        @input="updateUserPreferenceOptions('noDivision', 'email', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <div class="flex items-center justify-center">
                                    <activix-multiselect
                                        class="max-w-64"
                                        label="value"
                                        :disabled="
                                            !currentForm.module_access.web_boost ||
                                                !contextAccount.hasOption('webboost') ||
                                                hasOnlyServiceDivision
                                        "
                                        :selected="
                                            defaultScheduleOptions.find(
                                                option => option.id == preferenceOptions.noDivision.availability,
                                            )
                                        "
                                        :options="defaultScheduleOptions"
                                        :searchable="false"
                                        :allow-empty="false"
                                        @update="openFrequencyModal('noDivision', $event)"
                                    />
                                </div>
                            </td>
                            <td class="text-center w-56" />
                        </tr>
                        <tr
                            :class="{
                                'opacity-50':
                                    !currentForm.module_access.web_boost || !contextAccount.hasOption('webboost'),
                            }"
                        >
                            <td>
                                <div class="flex items-center">
                                    {{ $t('users.edit.automaticLeadInfo') }}
                                    <activix-tooltip :content="$t('users.edit.automaticLeadInfoTooltip')">
                                        <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                    </activix-tooltip>
                                </div>
                            </td>
                            <td />
                            <td class="text-center">
                                <activix-tooltip
                                    :content="generateTooltip('webBoost') || restrictedNotificationsTooltip"
                                >
                                    <activix-checkbox
                                        :value="preferenceOptions.leadAuto.sms"
                                        :disabled="
                                            !currentForm.module_access.web_boost ||
                                                !contextAccount.hasOption('webboost') ||
                                                hasOnlyServiceDivision ||
                                                hasRestrictedNotifications
                                        "
                                        @input="updateUserPreferenceOptions('leadAuto', 'sms', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td />
                            <td class="text-center">
                                <activix-tooltip :content="generateTooltip('webBoost')">
                                    <activix-checkbox
                                        :value="preferenceOptions.leadAuto.email"
                                        :disabled="
                                            !currentForm.module_access.web_boost ||
                                                !contextAccount.hasOption('webboost') ||
                                                hasOnlyServiceDivision
                                        "
                                        @input="updateUserPreferenceOptions('leadAuto', 'email', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <div class="flex items-center justify-center">
                                    <activix-multiselect
                                        class="max-w-64"
                                        label="value"
                                        :disabled="
                                            !currentForm.module_access.web_boost ||
                                                !contextAccount.hasOption('webboost') ||
                                                hasOnlyServiceDivision
                                        "
                                        :selected="
                                            defaultScheduleOptions.find(
                                                option => option.id == preferenceOptions.leadAuto.availability,
                                            )
                                        "
                                        :options="defaultScheduleOptions"
                                        :searchable="false"
                                        :allow-empty="false"
                                        @update="openFrequencyModal('leadAuto', $event)"
                                    />
                                </div>
                            </td>
                            <td class="text-center w-56" />
                        </tr>
                        <tr
                            :class="{
                                'opacity-50':
                                    !currentForm.module_access.web_boost || !contextAccount.hasOption('webboost'),
                            }"
                        >
                            <td>
                                <div class="flex items-center">
                                    {{ $t('users.edit.notAnsweredLead') }}
                                    <activix-tooltip :content="$t('users.edit.missedWbTooltip')">
                                        <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                    </activix-tooltip>
                                </div>
                            </td>
                            <td />
                            <td class="text-center">
                                <activix-tooltip
                                    :content="generateTooltip('webBoost') || restrictedNotificationsTooltip"
                                >
                                    <activix-checkbox
                                        :value="preferenceOptions.missedCall.sms"
                                        :disabled="
                                            !currentForm.module_access.web_boost ||
                                                !contextAccount.hasOption('webboost') ||
                                                hasOnlyServiceDivision ||
                                                hasRestrictedNotifications
                                        "
                                        @input="updateUserPreferenceOptions('missedCall', 'sms', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="generateTooltip('webBoost')">
                                    <activix-checkbox
                                        :value="preferenceOptions.missedCall.push"
                                        :disabled="
                                            !currentForm.module_access.web_boost ||
                                                !contextAccount.hasOption('webboost') ||
                                                hasOnlyServiceDivision
                                        "
                                        @input="updateUserPreferenceOptions('missedCall', 'push', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="generateTooltip('webBoost')">
                                    <activix-checkbox
                                        :value="preferenceOptions.missedCall.email"
                                        :disabled="
                                            !currentForm.module_access.web_boost ||
                                                !contextAccount.hasOption('webboost') ||
                                                hasOnlyServiceDivision
                                        "
                                        @input="updateUserPreferenceOptions('missedCall', 'email', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <div class="flex items-center justify-center">
                                    <activix-multiselect
                                        class="max-w-64"
                                        label="value"
                                        :disabled="
                                            !currentForm.module_access.web_boost ||
                                                !contextAccount.hasOption('webboost') ||
                                                hasOnlyServiceDivision
                                        "
                                        :selected="
                                            defaultScheduleOptions.find(
                                                option => option.id == preferenceOptions.missedCall.availability,
                                            )
                                        "
                                        :options="defaultScheduleOptions"
                                        :searchable="false"
                                        :allow-empty="false"
                                        @update="openFrequencyModal('missedCall', $event)"
                                    />
                                </div>
                            </td>
                            <td class="text-center w-56" />
                        </tr>

                        <!-- LeadXpress -->
                        <tr
                            class="text-white"
                            :class="
                                !currentForm.module_access.leadxpress || !contextAccount.hasOption('leadxpress')
                                    ? 'bg-blue-400'
                                    : 'bg-blue-500'
                            "
                        >
                            <th class="text-center">
                                {{ $t('users.edit.leadXpress') }}
                            </th>
                            <th class="text-center">
                                {{ $t('users.edit.screen') }}
                            </th>
                            <th class="text-center">
                                {{ $t('users.edit.sms') }}
                            </th>
                            <th class="text-center">
                                {{ $t('users.edit.push') }}
                            </th>
                            <th class="text-center">
                                {{ $t('users.edit.email') }}
                            </th>
                            <th class="text-center">
                                {{ $t('users.edit.frequencySituation') }}
                            </th>
                            <th class="text-center">
                                {{ $t('users.edit.notificationByDivision') }}
                            </th>
                        </tr>

                        <tr
                            :class="{
                                'opacity-50':
                                    !currentForm.module_access.leadxpress || !contextAccount.hasOption('leadxpress'),
                            }"
                        >
                            <td>
                                <div class="flex items-center">
                                    {{ $t('users.edit.newLeadReceived') }}
                                    <activix-tooltip :content="$t('users.edit.newLeadReceivedTooltip')">
                                        <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                    </activix-tooltip>
                                </div>
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="generateTooltip('leadXpress', 'screen')">
                                    <activix-checkbox
                                        :value="preferenceOptions.newLead.screen"
                                        :disabled="
                                            (hasOneCommunicationMethod && preferenceOptions.newLead.screen) ||
                                                !currentForm.module_access.leadxpress ||
                                                !contextAccount.hasOption('leadxpress')
                                        "
                                        @input="updateUserPreferenceOptions('newLead', 'screen', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-tooltip
                                    :content="generateTooltip('leadXpress', 'sms') || restrictedNotificationsTooltip"
                                >
                                    <activix-checkbox
                                        :value="preferenceOptions.newLead.sms"
                                        :disabled="
                                            (hasOneCommunicationMethod && preferenceOptions.newLead.sms) ||
                                                !currentForm.module_access.leadxpress ||
                                                !contextAccount.hasOption('leadxpress') ||
                                                hasRestrictedNotifications
                                        "
                                        @input="updateUserPreferenceOptions('newLead', 'sms', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="generateTooltip('leadXpress', 'push')">
                                    <activix-checkbox
                                        :value="preferenceOptions.newLead.push"
                                        :disabled="
                                            (hasOneCommunicationMethod && preferenceOptions.newLead.push) ||
                                                !currentForm.module_access.leadxpress ||
                                                !contextAccount.hasOption('leadxpress')
                                        "
                                        @input="updateUserPreferenceOptions('newLead', 'push', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="generateTooltip('leadXpress', 'email')">
                                    <activix-checkbox
                                        :value="preferenceOptions.newLead.email"
                                        :disabled="
                                            (hasOneCommunicationMethod && preferenceOptions.newLead.email) ||
                                                !currentForm.module_access.leadxpress ||
                                                !contextAccount.hasOption('leadxpress')
                                        "
                                        @input="updateUserPreferenceOptions('newLead', 'email', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <div class="flex items-center justify-center">
                                    <activix-multiselect
                                        class="max-w-64"
                                        label="value"
                                        :disabled="
                                            !currentForm.module_access.leadxpress ||
                                                !contextAccount.hasOption('leadxpress')
                                        "
                                        :selected="
                                            defaultScheduleOptions.find(
                                                option => option.id == preferenceOptions.newLead.availability,
                                            )
                                        "
                                        :options="defaultScheduleOptions"
                                        :searchable="false"
                                        :allow-empty="false"
                                        @update="openFrequencyModal('newLead', $event)"
                                    />
                                </div>
                            </td>
                            <td class="text-center w-56">
                                <notification-divisions
                                    notification-name="newLead"
                                    :disabled="!currentForm.module_access.leadxpress || !contextAccount.hasOption('leadxpress')"
                                />
                            </td>
                        </tr>
                        <tr
                            :class="{
                                'opacity-50':
                                    !currentForm.module_access.leadxpress || !contextAccount.hasOption('leadxpress'),
                            }"
                        >
                            <td>
                                <div class="flex items-center">
                                    {{ $t('users.edit.newLeadDuplicateReceived') }}
                                    <activix-tooltip :content="$t('users.edit.newLeadDuplicateReceivedTooltip')">
                                        <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                    </activix-tooltip>
                                </div>
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="generateTooltip('leadXpressDuplicate')">
                                    <activix-checkbox
                                        :value="preferenceOptions.newLeadDuplicate.screen"
                                        :disabled="
                                            !currentForm.module_access.leadxpress ||
                                                !contextAccount.hasOption('leadxpress')
                                        "
                                        @input="updateUserPreferenceOptions('newLeadDuplicate', 'screen', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-tooltip
                                    :content="generateTooltip('leadXpressDuplicate') || restrictedNotificationsTooltip"
                                >
                                    <activix-checkbox
                                        :value="preferenceOptions.newLeadDuplicate.sms"
                                        :disabled="
                                            !currentForm.module_access.leadxpress ||
                                                !contextAccount.hasOption('leadxpress') ||
                                                hasRestrictedNotifications
                                        "
                                        @input="updateUserPreferenceOptions('newLeadDuplicate', 'sms', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="generateTooltip('leadXpressDuplicate')">
                                    <activix-checkbox
                                        :value="preferenceOptions.newLeadDuplicate.push"
                                        :disabled="
                                            !currentForm.module_access.leadxpress ||
                                                !contextAccount.hasOption('leadxpress')
                                        "
                                        @input="updateUserPreferenceOptions('newLeadDuplicate', 'push', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="generateTooltip('leadXpressDuplicate')">
                                    <activix-checkbox
                                        :value="preferenceOptions.newLeadDuplicate.email"
                                        :disabled="
                                            !currentForm.module_access.leadxpress ||
                                                !contextAccount.hasOption('leadxpress')
                                        "
                                        @input="updateUserPreferenceOptions('newLeadDuplicate', 'email', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <div class="flex items-center justify-center">
                                    <activix-multiselect
                                        class="max-w-64"
                                        label="value"
                                        :disabled="
                                            !currentForm.module_access.leadxpress ||
                                                !contextAccount.hasOption('leadxpress')
                                        "
                                        :selected="
                                            defaultScheduleOptions.find(
                                                option => option.id == preferenceOptions.newLeadDuplicate.availability,
                                            )
                                        "
                                        :options="defaultScheduleOptions"
                                        :searchable="false"
                                        :allow-empty="false"
                                        @update="openFrequencyModal('newLeadDuplicate', $event)"
                                    />
                                </div>
                            </td>
                            <td class="text-center w-56">
                                <notification-divisions
                                    notification-name="newLeadDuplicate"
                                    :disabled="
                                        !currentForm.module_access.leadxpress || !contextAccount.hasOption('leadxpress')
                                    "
                                />
                            </td>
                        </tr>
                        <tr
                            :class="{
                                'opacity-50':
                                    !currentForm.module_access.leadxpress || !contextAccount.hasOption('leadxpress'),
                            }"
                        >
                            <td>
                                {{ $t('users.edit.untreatedLead') }}
                                <activix-tooltip :content="$t('users.edit.untreatedLeadTooltip')">
                                    <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="generateTooltip('leadXpressUntreated')">
                                    <activix-checkbox
                                        :value="preferenceOptions.untreatedLead.screen"
                                        :disabled="
                                            !currentForm.module_access.leadxpress ||
                                                !contextAccount.hasOption('leadxpress') ||
                                                !accountHasUntreatedLeadsAlerts
                                        "
                                        @input="updateUserPreferenceOptions('untreatedLead', 'screen', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-tooltip
                                    :content="generateTooltip('leadXpressUntreated') || restrictedNotificationsTooltip"
                                >
                                    <activix-checkbox
                                        :value="preferenceOptions.untreatedLead.sms"
                                        :disabled="
                                            !currentForm.module_access.leadxpress ||
                                                !contextAccount.hasOption('leadxpress') ||
                                                enabledUntreatedLeadAlertSms ||
                                                hasRestrictedNotifications
                                        "
                                        @input="updateUserPreferenceOptions('untreatedLead', 'sms', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="generateTooltip('leadXpressUntreated')">
                                    <activix-checkbox
                                        :value="preferenceOptions.untreatedLead.push"
                                        :disabled="
                                            !currentForm.module_access.leadxpress ||
                                                !contextAccount.hasOption('leadxpress') ||
                                                enabledUntreatedLeadAlertSms
                                        "
                                        @input="updateUserPreferenceOptions('untreatedLead', 'push', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="generateTooltip('leadXpressUntreated')">
                                    <activix-checkbox
                                        :value="preferenceOptions.untreatedLead.email"
                                        :disabled="
                                            (hasOneCommunicationMethod && preferenceOptions.untreatedLead.email) ||
                                                !currentForm.module_access.leadxpress ||
                                                !contextAccount.hasOption('leadxpress') ||
                                                enabledUntreatedLeadAlertEmail
                                        "
                                        @input="updateUserPreferenceOptions('untreatedLead', 'email', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <div class="flex items-center justify-center">
                                    <activix-multiselect
                                        class="max-w-64"
                                        label="value"
                                        :disabled="
                                            !currentForm.module_access.leadxpress ||
                                                !contextAccount.hasOption('leadxpress')
                                        "
                                        :selected="
                                            defaultScheduleOptions.find(
                                                option => option.id == preferenceOptions.untreatedLead.availability,
                                            )
                                        "
                                        :options="defaultScheduleOptions"
                                        :searchable="false"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @update="openFrequencyModal('untreatedLead', $event)"
                                    />
                                </div>
                            </td>
                            <td class="text-center w-56">
                                <notification-divisions
                                    notification-name="untreatedLead"
                                    :disabled="
                                        !currentForm.module_access.leadxpress || !contextAccount.hasOption('leadxpress')
                                    "
                                />
                            </td>
                        </tr>
                        <tr
                            :class="{
                                'opacity-50':
                                    !currentForm.module_access.leadxpress || !contextAccount.hasOption('leadxpress'),
                            }"
                        >
                            <td>
                                {{ $t('users.edit.untreatedLeadDuplicate') }}
                                <activix-tooltip :content="$t('users.edit.untreatedLeadDuplicateTooltip')">
                                    <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="generateTooltip('leadXpressUntreated')">
                                    <activix-checkbox
                                        :value="preferenceOptions.untreatedLeadDuplicate.screen"
                                        :disabled="
                                            !currentForm.module_access.leadxpress ||
                                                !contextAccount.hasOption('leadxpress') ||
                                                !accountHasUntreatedLeadsAlerts
                                        "
                                        @input="updateUserPreferenceOptions('untreatedLeadDuplicate', 'screen', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-tooltip
                                    :content="generateTooltip('leadXpressUntreated') || restrictedNotificationsTooltip"
                                >
                                    <activix-checkbox
                                        :value="preferenceOptions.untreatedLeadDuplicate.sms"
                                        :disabled="
                                            !currentForm.module_access.leadxpress ||
                                                !contextAccount.hasOption('leadxpress') ||
                                                !accountHasUntreatedLeadsAlerts ||
                                                hasRestrictedNotifications
                                        "
                                        @input="updateUserPreferenceOptions('untreatedLeadDuplicate', 'sms', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="generateTooltip('leadXpressUntreated')">
                                    <activix-checkbox
                                        :value="preferenceOptions.untreatedLeadDuplicate.push"
                                        :disabled="
                                            !currentForm.module_access.leadxpress ||
                                                !contextAccount.hasOption('leadxpress') ||
                                                !accountHasUntreatedLeadsAlerts
                                        "
                                        @input="updateUserPreferenceOptions('untreatedLeadDuplicate', 'push', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="generateTooltip('leadXpressUntreated')">
                                    <activix-checkbox
                                        :value="preferenceOptions.untreatedLeadDuplicate.email"
                                        :disabled="
                                            !currentForm.module_access.leadxpress ||
                                                !contextAccount.hasOption('leadxpress') ||
                                                !accountHasUntreatedLeadsAlerts
                                        "
                                        @input="updateUserPreferenceOptions('untreatedLeadDuplicate', 'email', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <div class="flex items-center justify-center">
                                    <activix-multiselect
                                        class="max-w-64"
                                        label="value"
                                        :disabled="
                                            !currentForm.module_access.leadxpress ||
                                                !contextAccount.hasOption('leadxpress')
                                        "
                                        :selected="
                                            defaultScheduleOptions.find(
                                                option =>
                                                    option.id == preferenceOptions.untreatedLeadDuplicate.availability,
                                            )
                                        "
                                        :options="defaultScheduleOptions"
                                        :searchable="false"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @update="openFrequencyModal('untreatedLeadDuplicate', $event)"
                                    />
                                </div>
                            </td>
                            <td class="text-center w-56">
                                <notification-divisions
                                    notification-name="untreatedLeadDuplicate"
                                    :disabled="
                                        !currentForm.module_access.leadxpress || !contextAccount.hasOption('leadxpress')
                                    "
                                />
                            </td>
                        </tr>

                        <tr
                            :class="{
                                'opacity-50':
                                    !currentForm.module_access.leadxpress || !contextAccount.hasOption('leadxpress'),
                            }"
                        >
                            <td>
                                <div class="flex items-center">
                                    {{ $t('users.edit.newLeadLoyaltyNotif') }}
                                    <activix-tooltip :content="$t('users.edit.newLeadLoyaltyNotifTooltip')">
                                        <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                    </activix-tooltip>
                                </div>
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.newLeadLoyalty.screen"
                                    :disabled="
                                        !currentForm.module_access.leadxpress ||
                                            !contextAccount.hasOption('leadxpress')
                                    "
                                    @input="updateUserPreferenceOptions('newLeadLoyalty', 'screen', $event)"
                                />
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="restrictedNotificationsTooltip">
                                    <activix-checkbox
                                        :value="preferenceOptions.newLeadLoyalty.sms"
                                        :disabled="
                                            !currentForm.module_access.leadxpress ||
                                                !contextAccount.hasOption('leadxpress') ||
                                                hasRestrictedNotifications
                                        "
                                        @input="updateUserPreferenceOptions('newLeadLoyalty', 'sms', $event)"
                                    />
                                </activix-tooltip>
                            </td>

                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.newLeadLoyalty.push"
                                    :disabled="
                                        !currentForm.module_access.leadxpress ||
                                            !contextAccount.hasOption('leadxpress')
                                    "
                                    @input="updateUserPreferenceOptions('newLeadLoyalty', 'push', $event)"
                                />
                            </td>

                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.newLeadLoyalty.email"
                                    :disabled="
                                        !currentForm.module_access.leadxpress ||
                                            !contextAccount.hasOption('leadxpress')
                                    "
                                    @input="updateUserPreferenceOptions('newLeadLoyalty', 'email', $event)"
                                />
                            </td>
                            <td class="text-center">
                                <div class="flex items-center justify-center">
                                    <activix-multiselect
                                        class="max-w-64"
                                        label="value"
                                        :disabled="
                                            !currentForm.module_access.leadxpress ||
                                                !contextAccount.hasOption('leadxpress')
                                        "
                                        :selected="
                                            defaultScheduleOptions.find(
                                                option =>
                                                    option.id == preferenceOptions.newLeadLoyalty.availability,
                                            )
                                        "
                                        :options="defaultScheduleOptions"
                                        :searchable="false"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @update="openFrequencyModal('newLeadLoyalty', $event)"
                                    />
                                </div>
                            </td>
                            <td class="text-center w-56">
                                <notification-divisions
                                    notification-name="newLeadLoyalty"
                                    :disabled="
                                        !currentForm.module_access.leadxpress || !contextAccount.hasOption('leadxpress')
                                    "
                                />
                            </td>
                        </tr>

                        <tr
                            :class="{
                                'opacity-50':
                                    !currentForm.module_access.leadxpress || !contextAccount.hasOption('leadxpress'),
                            }"
                        >
                            <td>
                                <div class="flex items-center">
                                    {{ $t('users.edit.newLeadLoyaltyDuplicateNotif') }}
                                    <activix-tooltip :content="$t('users.edit.newLeadLoyaltyDuplicateNotifTooltip')">
                                        <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                    </activix-tooltip>
                                </div>
                            </td>
                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.newLeadLoyaltyDuplicate.screen"
                                    :disabled="
                                        !currentForm.module_access.leadxpress ||
                                            !contextAccount.hasOption('leadxpress')
                                    "
                                    @input="updateUserPreferenceOptions('newLeadLoyaltyDuplicate', 'screen', $event)"
                                />
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="restrictedNotificationsTooltip">
                                    <activix-checkbox
                                        :value="preferenceOptions.newLeadLoyaltyDuplicate.sms"
                                        :disabled="
                                            !currentForm.module_access.leadxpress ||
                                                !contextAccount.hasOption('leadxpress') ||
                                                hasRestrictedNotifications
                                        "
                                        @input="updateUserPreferenceOptions('newLeadLoyaltyDuplicate', 'sms', $event)"
                                    />
                                </activix-tooltip>
                            </td>

                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.newLeadLoyaltyDuplicate.push"
                                    :disabled="
                                        !currentForm.module_access.leadxpress ||
                                            !contextAccount.hasOption('leadxpress')
                                    "
                                    @input="updateUserPreferenceOptions('newLeadLoyaltyDuplicate', 'push', $event)"
                                />
                            </td>

                            <td class="text-center">
                                <activix-checkbox
                                    :value="preferenceOptions.newLeadLoyaltyDuplicate.email"
                                    :disabled="
                                        !currentForm.module_access.leadxpress ||
                                            !contextAccount.hasOption('leadxpress')
                                    "
                                    @input="updateUserPreferenceOptions('newLeadLoyaltyDuplicate', 'email', $event)"
                                />
                            </td>
                            <td class="text-center">
                                <div class="flex items-center justify-center">
                                    <activix-multiselect
                                        class="max-w-64"
                                        label="value"
                                        :disabled="
                                            !currentForm.module_access.leadxpress ||
                                                !contextAccount.hasOption('leadxpress')
                                        "
                                        :selected="
                                            defaultScheduleOptions.find(
                                                option =>
                                                    option.id == preferenceOptions.newLeadLoyaltyDuplicate.availability,
                                            )
                                        "
                                        :options="defaultScheduleOptions"
                                        :searchable="false"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @update="openFrequencyModal('newLeadLoyaltyDuplicate', $event)"
                                    />
                                </div>
                            </td>
                            <td class="text-center w-56">
                                <notification-divisions
                                    notification-name="newLeadLoyaltyDuplicate"
                                    :disabled="
                                        !currentForm.module_access.leadxpress || !contextAccount.hasOption('leadxpress')
                                    "
                                />
                            </td>
                        </tr>

                        <!-- InTurn -->
                        <tr
                            class="text-white"
                            :class="!accountHasInTurn || hasOnlyServiceDivision ? 'bg-blue-400' : 'bg-blue-500'"
                        >
                            <th class="text-center">
                                {{ $t('users.edit.inTurn') }}
                            </th>
                            <th class="text-center">
                                {{ $t('users.edit.screen') }}
                            </th>
                            <th class="text-center">
                                {{ $t('users.edit.sms') }}
                            </th>
                            <th class="text-center">
                                {{ $t('users.edit.push') }}
                            </th>
                            <th class="text-center">
                                {{ $t('users.edit.email') }}
                            </th>
                            <th class="text-center">
                                {{ $t('users.edit.frequencySituation') }}
                            </th>
                            <th class="text-center">
                                {{ $t('users.edit.notificationByDivision') }}
                            </th>
                        </tr>

                        <tr>
                            <td :class="{ 'text-grey-600': !accountHasInTurn || hasOnlyServiceDivision }">
                                {{ $t('users.edit.nextInTurn') }}
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="generateTooltip('inTurn')">
                                    <activix-checkbox
                                        :value="preferenceOptions.nextInTurn.screen"
                                        :disabled="!accountHasInTurn || hasOnlyServiceDivision"
                                        @input="updateUserPreferenceOptions('nextInTurn', 'screen', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="generateTooltip('inTurn') || restrictedNotificationsTooltip">
                                    <activix-checkbox
                                        :value="preferenceOptions.nextInTurn.sms"
                                        :disabled="
                                            !accountHasInTurn || hasOnlyServiceDivision || hasRestrictedNotifications
                                        "
                                        @input="updateUserPreferenceOptions('nextInTurn', 'sms', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <activix-tooltip :content="generateTooltip('inTurn')">
                                <td class="text-center">
                                    <activix-checkbox
                                        :value="preferenceOptions.nextInTurn.push"
                                        :disabled="!accountHasInTurn || hasOnlyServiceDivision"
                                        @input="updateUserPreferenceOptions('nextInTurn', 'push', $event)"
                                    />
                                </td>
                            </activix-tooltip>
                            <td />
                            <td
                                :class="[
                                    'text-center',
                                    { 'text-grey-600': !accountHasInTurn || hasOnlyServiceDivision },
                                ]"
                            >
                                <div class="flex items-center justify-center">
                                    <activix-multiselect
                                        class="max-w-64 "
                                        label="value"
                                        :disabled="!accountHasInTurn || hasOnlyServiceDivision"
                                        :selected="
                                            defaultScheduleOptions.find(
                                                option => option.id == preferenceOptions.nextInTurn.availability,
                                            )
                                        "
                                        :options="defaultScheduleOptions"
                                        :searchable="false"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @update="openFrequencyModal('nextInTurn', $event)"
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr v-if="!empty(currentForm.role) && currentForm.role.id == Role.DIRECTOR">
                            <td :class="{ 'text-grey-600': !accountHasInTurn || hasOnlyServiceDivision }">
                                {{ $t('users.edit.inTurnRemoved') }}
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="generateTooltip('inTurn')">
                                    <activix-checkbox
                                        :value="preferenceOptions.inTurnRemove.screen"
                                        :disabled="!accountHasInTurn || hasOnlyServiceDivision"
                                        @input="updateUserPreferenceOptions('inTurnRemove', 'screen', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="generateTooltip('inTurn') || restrictedNotificationsTooltip">
                                    <activix-checkbox
                                        :value="preferenceOptions.inTurnRemove.sms"
                                        :disabled="
                                            !accountHasInTurn || hasOnlyServiceDivision || hasRestrictedNotifications
                                        "
                                        @input="updateUserPreferenceOptions('inTurnRemove', 'sms', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <activix-tooltip :content="generateTooltip('inTurn')">
                                <td class="text-center">
                                    <activix-checkbox
                                        :value="preferenceOptions.inTurnRemove.push"
                                        :disabled="!accountHasInTurn || hasOnlyServiceDivision"
                                        @input="updateUserPreferenceOptions('inTurnRemove', 'push', $event)"
                                    />
                                </td>
                            </activix-tooltip>
                            <td />
                            <td
                                :class="[
                                    'text-center',
                                    { 'text-grey-600': !accountHasInTurn || hasOnlyServiceDivision },
                                ]"
                            >
                                <div class="flex items-center justify-center">
                                    <activix-multiselect
                                        class="max-w-64"
                                        label="value"
                                        :disabled="!accountHasInTurn || hasOnlyServiceDivision"
                                        :selected="
                                            defaultScheduleOptions.find(
                                                option => option.id == preferenceOptions.inTurnRemove.availability,
                                            )
                                        "
                                        :options="defaultScheduleOptions"
                                        :searchable="false"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @update="openFrequencyModal('inTurnRemove', $event)"
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr v-if="!empty(currentForm.role) && currentForm.role.id == Role.DIRECTOR">
                            <td :class="{ 'text-grey-600': !accountHasInTurn || hasOnlyServiceDivision }">
                                {{ $t('users.edit.noUserInTurn') }}
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="generateTooltip('inTurn')">
                                    <activix-checkbox
                                        :value="preferenceOptions.noUserInTurn.screen"
                                        :disabled="!accountHasInTurn || hasOnlyServiceDivision"
                                        @input="updateUserPreferenceOptions('noUserInTurn', 'screen', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="generateTooltip('inTurn') || restrictedNotificationsTooltip">
                                    <activix-checkbox
                                        :value="preferenceOptions.noUserInTurn.sms"
                                        :disabled="
                                            !accountHasInTurn || hasOnlyServiceDivision || hasRestrictedNotifications
                                        "
                                        @input="updateUserPreferenceOptions('noUserInTurn', 'sms', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="generateTooltip('inTurn') || restrictedNotificationsTooltip">
                                    <activix-checkbox
                                        :value="preferenceOptions.noUserInTurn.push"
                                        :disabled="!accountHasInTurn || hasOnlyServiceDivision"
                                        @input="updateUserPreferenceOptions('noUserInTurn', 'push', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td />
                            <td
                                :class="[
                                    'text-center',
                                    { 'text-grey-600': !accountHasInTurn || hasOnlyServiceDivision },
                                ]"
                            >
                                <div class="flex items-center justify-center">
                                    <activix-multiselect
                                        class="max-w-64"
                                        label="value"
                                        :disabled="!accountHasInTurn || hasOnlyServiceDivision"
                                        :selected="
                                            defaultScheduleOptions.find(
                                                option => option.id == preferenceOptions.noUserInTurn.availability,
                                            )
                                        "
                                        :options="defaultScheduleOptions"
                                        :searchable="false"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @update="openFrequencyModal('noUserInTurn', $event)"
                                    />
                                </div>
                            </td>
                        </tr>

                        <!-- Service -->
                        <tr class="text-white" :class="!hasService ? 'bg-blue-400' : 'bg-blue-500'">
                            <th class="text-center">
                                {{ $t('users.edit.service') }}
                            </th>
                            <th class="text-center">
                                {{ $t('users.edit.screen') }}
                            </th>
                            <th class="text-center">
                                {{ $t('users.edit.sms') }}
                            </th>
                            <th class="text-center">
                                {{ $t('users.edit.push') }}
                            </th>
                            <th class="text-center">
                                {{ $t('users.edit.email') }}
                            </th>
                            <th class="text-center">
                                {{ $t('users.edit.frequencySituation') }}
                            </th>
                            <th class="text-center">
                                {{ $t('users.edit.notificationByDivision') }}
                            </th>
                        </tr>

                        <tr>
                            <td>
                                <div class="flex items-baseline" :class="{ 'opacity-50': !hasService }">
                                    {{ $t('users.edit.serviceMyClientsAppointments') }}
                                    <activix-tooltip
                                        :content="$t('users.edit.serviceMyClientsAppointmentsDescription')"
                                    >
                                        <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                    </activix-tooltip>
                                </div>
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="generateTooltip('service')">
                                    <activix-checkbox
                                        :value="preferenceOptions.serviceMyClientsAppointments.screen"
                                        :disabled="!hasService"
                                        @input="
                                            updateUserPreferenceOptions(
                                                'serviceMyClientsAppointments',
                                                'screen',
                                                $event,
                                            )
                                        "
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-tooltip
                                    :content="generateTooltip('service') || restrictedNotificationsTooltip"
                                >
                                    <activix-checkbox
                                        :value="preferenceOptions.serviceMyClientsAppointments.sms"
                                        :disabled="!hasService || hasRestrictedNotifications"
                                        @input="
                                            updateUserPreferenceOptions('serviceMyClientsAppointments', 'sms', $event)
                                        "
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="generateTooltip('service')">
                                    <activix-checkbox
                                        :value="preferenceOptions.serviceMyClientsAppointments.push"
                                        :disabled="!hasService"
                                        @input="
                                            updateUserPreferenceOptions('serviceMyClientsAppointments', 'push', $event)
                                        "
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="generateTooltip('service')">
                                    <activix-checkbox
                                        :value="preferenceOptions.serviceMyClientsAppointments.email"
                                        :disabled="!hasService"
                                        @input="
                                            updateUserPreferenceOptions('serviceMyClientsAppointments', 'email', $event)
                                        "
                                    />
                                </activix-tooltip>
                            </td>

                            <td class="text-center">
                                <div class="flex items-center justify-center">
                                    <activix-multiselect
                                        class="max-w-64"
                                        label="value"
                                        :disabled="!hasService"
                                        :selected="
                                            defaultScheduleOptions.find(
                                                option =>
                                                    option.id ==
                                                    preferenceOptions.serviceMyClientsAppointments.availability,
                                            )
                                        "
                                        :options="defaultScheduleOptions"
                                        :searchable="false"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @update="openFrequencyModal('serviceMyClientsAppointments', $event)"
                                    />
                                </div>
                            </td>
                            <td class="text-center w-56" />
                        </tr>
                        <tr>
                            <td>
                                <div class="flex items-baseline" :class="{ 'opacity-50': !hasService }">
                                    {{ $t('users.edit.serviceMyClientsVehiclePickUp') }}
                                    <activix-tooltip
                                        :content="$t('users.edit.serviceMyClientsVehiclePickUpDescription')"
                                    >
                                        <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                    </activix-tooltip>
                                </div>
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="generateTooltip('service')">
                                    <activix-checkbox
                                        :value="preferenceOptions.serviceMyClientsVehiclePickUp.screen"
                                        :disabled="!hasService"
                                        @input="
                                            updateUserPreferenceOptions(
                                                'serviceMyClientsVehiclePickUp',
                                                'screen',
                                                $event,
                                            )
                                        "
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-tooltip
                                    :content="generateTooltip('service') || restrictedNotificationsTooltip"
                                >
                                    <activix-checkbox
                                        :value="preferenceOptions.serviceMyClientsVehiclePickUp.sms"
                                        :disabled="!hasService || hasRestrictedNotifications"
                                        @input="
                                            updateUserPreferenceOptions('serviceMyClientsVehiclePickUp', 'sms', $event)
                                        "
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="generateTooltip('service')">
                                    <activix-checkbox
                                        :value="preferenceOptions.serviceMyClientsVehiclePickUp.push"
                                        :disabled="!hasService"
                                        @input="
                                            updateUserPreferenceOptions('serviceMyClientsVehiclePickUp', 'push', $event)
                                        "
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="generateTooltip('service')">
                                    <activix-checkbox
                                        :value="preferenceOptions.serviceMyClientsVehiclePickUp.email"
                                        :disabled="!hasService"
                                        @input="
                                            updateUserPreferenceOptions(
                                                'serviceMyClientsVehiclePickUp',
                                                'email',
                                                $event,
                                            )
                                        "
                                    />
                                </activix-tooltip>
                            </td>

                            <td class="text-center">
                                <div class="flex items-center justify-center">
                                    <activix-multiselect
                                        class="max-w-64"
                                        label="value"
                                        :disabled="!hasService"
                                        :selected="
                                            defaultScheduleOptions.find(
                                                option =>
                                                    option.id ==
                                                    preferenceOptions.serviceMyClientsVehiclePickUp.availability,
                                            )
                                        "
                                        :options="defaultScheduleOptions"
                                        :searchable="false"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @update="openFrequencyModal('serviceMyClientsVehiclePickUp', $event)"
                                    />
                                </div>
                            </td>
                            <td class="text-center w-56" />
                        </tr>
                        <tr>
                            <td>
                                <div class="flex items-baseline" :class="{ 'opacity-50': !hasService }">
                                    {{ $t('users.edit.serviceVehiclePickUp') }}
                                    <activix-tooltip :content="$t('users.edit.serviceVehiclePickUpDescription')">
                                        <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                    </activix-tooltip>
                                </div>
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="generateTooltip('service')">
                                    <activix-checkbox
                                        :value="preferenceOptions.serviceVehiclePickUp.screen"
                                        :disabled="!hasService"
                                        @input="updateUserPreferenceOptions('serviceVehiclePickUp', 'screen', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-tooltip
                                    :content="generateTooltip('service') || restrictedNotificationsTooltip"
                                >
                                    <activix-checkbox
                                        :value="preferenceOptions.serviceVehiclePickUp.sms"
                                        :disabled="!hasService || hasRestrictedNotifications"
                                        @input="updateUserPreferenceOptions('serviceVehiclePickUp', 'sms', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="generateTooltip('service')">
                                    <activix-checkbox
                                        :value="preferenceOptions.serviceVehiclePickUp.push"
                                        :disabled="!hasService"
                                        @input="updateUserPreferenceOptions('serviceVehiclePickUp', 'push', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="generateTooltip('service')">
                                    <activix-checkbox
                                        :value="preferenceOptions.serviceVehiclePickUp.email"
                                        :disabled="!hasService"
                                        @input="updateUserPreferenceOptions('serviceVehiclePickUp', 'email', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <div class="flex items-center justify-center">
                                    <activix-multiselect
                                        class="max-w-64"
                                        label="value"
                                        :disabled="!hasService"
                                        :selected="
                                            defaultScheduleOptions.find(
                                                option =>
                                                    option.id == preferenceOptions.serviceVehiclePickUp.availability,
                                            )
                                        "
                                        :options="defaultScheduleOptions"
                                        :searchable="false"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @update="openFrequencyModal('serviceVehiclePickUp', $event)"
                                    />
                                </div>
                            </td>
                            <td class="text-center w-56" />
                        </tr>

                        <!-- NioText -->
                        <tr class="text-white" :class="!hasNioText ? 'bg-blue-400' : 'bg-blue-500'">
                            <th class="text-center">
                                {{ $t('users.edit.nioText') }}
                            </th>
                            <th class="text-center">
                                {{ $t('users.edit.screen') }}
                            </th>
                            <th class="text-center">
                                {{ $t('users.edit.sms') }}
                            </th>
                            <th class="text-center">
                                {{ $t('users.edit.push') }}
                            </th>
                            <th class="text-center">
                                {{ $t('users.edit.email') }}
                            </th>
                            <th class="text-center">
                                {{ $t('users.edit.frequencySituation') }}
                            </th>
                            <th class="text-center">
                                {{ $t('users.edit.notificationByDivision') }}
                            </th>
                        </tr>

                        <tr>
                            <td>
                                <div class="flex items-baseline" :class="{ 'opacity-50': !hasNioText }">
                                    {{ $t('users.edit.nioTextIncoming') }}
                                    <activix-tooltip :content="$t('users.edit.nioTextIncomingDescription')">
                                        <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                    </activix-tooltip>
                                </div>
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="generateTooltip('nioText')">
                                    <activix-checkbox
                                        :value="preferenceOptions.nioTextIncoming.screen"
                                        :disabled="!hasNioText"
                                        @input="updateUserPreferenceOptions('nioTextIncoming', 'screen', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-tooltip
                                    :content="generateTooltip('nioText') || restrictedNotificationsTooltip"
                                >
                                    <activix-checkbox
                                        :value="preferenceOptions.nioTextIncoming.sms"
                                        :disabled="!hasNioText || hasRestrictedNotifications"
                                        @input="updateUserPreferenceOptions('nioTextIncoming', 'sms', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="generateTooltip('nioText')">
                                    <activix-checkbox
                                        :value="preferenceOptions.nioTextIncoming.push"
                                        :disabled="!hasNioText"
                                        @input="updateUserPreferenceOptions('nioTextIncoming', 'push', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="generateTooltip('nioText')">
                                    <activix-checkbox
                                        :value="preferenceOptions.nioTextIncoming.email"
                                        :disabled="!hasNioText"
                                        @input="updateUserPreferenceOptions('nioTextIncoming', 'email', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center" :class="{ 'opacity-50': !hasNioText }">
                                {{ $t('users.edit.anytime') }}
                            </td>
                            <td class="text-center w-56">
                                <notification-divisions notification-name="nioTextIncoming" :disabled="!hasNioText" />
                            </td>
                        </tr>

                        <!-- Equity Alert -->
                        <tr
                            class="text-white"
                            :class="
                                !hasRenewalAlertEquity && !hasRenewalAlertLocation && !hasRenewalAlertWarranty
                                    ? 'bg-blue-400'
                                    : 'bg-blue-500'
                            "
                        >
                            <th class="text-center">
                                {{ $t('users.edit.equityAlert') }}
                            </th>
                            <th class="text-center">
                                {{ $t('users.edit.screen') }}
                            </th>
                            <th class="text-center" />
                            <th class="text-center">
                                {{ $t('users.edit.push') }}
                            </th>
                            <th class="text-center">
                                {{ $t('users.edit.email') }}
                            </th>
                            <th class="text-center">
                                {{ $t('users.edit.frequencySituation') }}
                            </th>
                            <th class="text-center">
                                {{ $t('users.edit.notificationByDivision') }}
                            </th>
                        </tr>

                        <tr>
                            <td>
                                <div class="flex items-baseline" :class="{ 'opacity-50': !hasRenewalAlertEquity }">
                                    {{ $t('users.edit.renewalAlertEquity') }}
                                    <activix-tooltip :content="$t('users.edit.renewalAlertEquityDescription')">
                                        <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                    </activix-tooltip>
                                </div>
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="tooltipRenewalAlertEquity">
                                    <activix-checkbox
                                        :value="preferenceOptions.renewalAlertEquity.screen"
                                        :disabled="!hasRenewalAlertEquity"
                                        @input="updateUserPreferenceOptions('renewalAlertEquity', 'screen', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center" />
                            <td class="text-center">
                                <activix-tooltip :content="tooltipRenewalAlertEquity">
                                    <activix-checkbox
                                        :value="preferenceOptions.renewalAlertEquity.push"
                                        :disabled="!hasRenewalAlertEquity"
                                        @input="updateUserPreferenceOptions('renewalAlertEquity', 'push', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="tooltipRenewalAlertEquity">
                                    <activix-checkbox
                                        :value="preferenceOptions.renewalAlertEquity.email"
                                        :disabled="!hasRenewalAlertEquity"
                                        @input="updateUserPreferenceOptions('renewalAlertEquity', 'email', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center" :class="{ 'opacity-50': !hasRenewalAlertEquity }">
                                {{ $t('users.edit.weekly') }}
                            </td>
                            <td class="text-center w-56" />
                        </tr>
                        <!-- RenewalAlert -->
                        <tr
                            class="text-white"
                            :class="
                                !hasRenewalAlertEquity && !hasRenewalAlertLocation && !hasRenewalAlertWarranty
                                    ? 'bg-blue-400'
                                    : 'bg-blue-500'
                            "
                        >
                            <th class="text-center">
                                {{ $t('users.edit.renewalAlert') }}
                            </th>
                            <th class="text-center">
                                {{ $t('users.edit.screen') }}
                            </th>
                            <th class="text-center" />
                            <th class="text-center">
                                {{ $t('users.edit.push') }}
                            </th>
                            <th class="text-center">
                                {{ $t('users.edit.email') }}
                            </th>
                            <th class="text-center">
                                {{ $t('users.edit.frequencySituation') }}
                            </th>
                            <th class="text-center">
                                {{ $t('users.edit.notificationByDivision') }}
                            </th>
                        </tr>
                        <tr>
                            <td>
                                <div class="flex items-baseline" :class="{ 'opacity-50': !hasRenewalAlertLocation }">
                                    {{ $t('users.edit.renewalAlertEndLocation') }}
                                    <activix-tooltip :content="$t('users.edit.renewalAlertEndLocationDescription')">
                                        <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                    </activix-tooltip>
                                </div>
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="tooltipRenewalAlertLocation">
                                    <activix-checkbox
                                        :value="preferenceOptions.renewalAlertEndLocation.screen"
                                        :disabled="!hasRenewalAlertLocation"
                                        @input="
                                            updateUserPreferenceOptions('renewalAlertEndLocation', 'screen', $event)
                                        "
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center" />
                            <td class="text-center">
                                <activix-tooltip :content="tooltipRenewalAlertLocation">
                                    <activix-checkbox
                                        :value="preferenceOptions.renewalAlertEndLocation.push"
                                        :disabled="!hasRenewalAlertLocation"
                                        @input="updateUserPreferenceOptions('renewalAlertEndLocation', 'push', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="tooltipRenewalAlertLocation">
                                    <activix-checkbox
                                        :value="preferenceOptions.renewalAlertEndLocation.email"
                                        :disabled="!hasRenewalAlertLocation"
                                        @input="updateUserPreferenceOptions('renewalAlertEndLocation', 'email', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center" :class="{ 'opacity-50': !hasRenewalAlertLocation }">
                                {{ $t('users.edit.daily') }}
                            </td>
                            <td class="text-center w-56" />
                        </tr>
                        <tr>
                            <td>
                                <div class="flex items-baseline" :class="{ 'opacity-50': !hasRenewalAlertWarranty }">
                                    {{ $t('users.edit.renewalAlertEndWarranty') }}
                                    <activix-tooltip :content="$t('users.edit.renewalAlertEndWarrantyDescription')">
                                        <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                    </activix-tooltip>
                                </div>
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="tooltipRenewalAlertWarranty">
                                    <activix-checkbox
                                        :value="preferenceOptions.renewalAlertEndWarranty.screen"
                                        :disabled="!hasRenewalAlertWarranty"
                                        @input="
                                            updateUserPreferenceOptions('renewalAlertEndWarranty', 'screen', $event)
                                        "
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center" />
                            <td class="text-center">
                                <activix-tooltip :content="tooltipRenewalAlertWarranty">
                                    <activix-checkbox
                                        :value="preferenceOptions.renewalAlertEndWarranty.push"
                                        :disabled="!hasRenewalAlertWarranty"
                                        @input="updateUserPreferenceOptions('renewalAlertEndWarranty', 'push', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="tooltipRenewalAlertWarranty">
                                    <activix-checkbox
                                        :value="preferenceOptions.renewalAlertEndWarranty.email"
                                        :disabled="!hasRenewalAlertWarranty"
                                        @input="updateUserPreferenceOptions('renewalAlertEndWarranty', 'email', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center" :class="{ 'opacity-50': !hasRenewalAlertWarranty }">
                                {{ $t('users.edit.daily') }}
                            </td>
                            <td class="text-center w-56" />
                        </tr>
                        <!-- Videoconference -->
                        <tr
                            class="text-white"
                            :class="!contextAccount.video_conference ? 'bg-blue-400' : 'bg-blue-500'"
                        >
                            <th class="text-center">
                                {{ $t('users.edit.videoconference') }}
                            </th>
                            <th class="text-center" />
                            <th class="text-center">
                                {{ $t('users.edit.sms') }}
                            </th>
                            <th class="text-center">
                                {{ $t('users.edit.push') }}
                            </th>
                            <th class="text-center">
                                {{ $t('users.edit.email') }}
                            </th>
                            <th class="text-center">
                                {{ $t('users.edit.frequencySituation') }}
                            </th>
                            <th class="text-center" />
                        </tr>

                        <tr>
                            <td>
                                <div
                                    class="flex items-baseline"
                                    :class="{ 'opacity-50': !contextAccount.video_conference }"
                                >
                                    {{ $t('users.edit.leadWaitingInRoom') }}
                                    <activix-tooltip :content="$t('users.edit.leadWaitingInRoomTooltip')">
                                        <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                    </activix-tooltip>
                                </div>
                            </td>

                            <td />

                            <td class="text-center">
                                <activix-tooltip
                                    :content="generateTooltip('videoconference') || restrictedNotificationsTooltip"
                                >
                                    <activix-checkbox
                                        :value="preferenceOptions.leadWaitingInRoom.sms"
                                        :disabled="!contextAccount.video_conference || hasRestrictedNotifications"
                                        @input="updateUserPreferenceOptions('leadWaitingInRoom', 'sms', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="generateTooltip('videoconference')">
                                    <activix-checkbox
                                        :value="preferenceOptions.leadWaitingInRoom.push"
                                        :disabled="!contextAccount.video_conference"
                                        @input="updateUserPreferenceOptions('leadWaitingInRoom', 'push', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                <activix-tooltip :content="generateTooltip('videoconference')">
                                    <activix-checkbox
                                        :value="preferenceOptions.leadWaitingInRoom.email"
                                        :disabled="!contextAccount.video_conference"
                                        @input="updateUserPreferenceOptions('leadWaitingInRoom', 'email', $event)"
                                    />
                                </activix-tooltip>
                            </td>
                            <td class="text-center">
                                {{ $t('users.edit.anytime') }}
                            </td>
                            <td />
                        </tr>
                        <!-- Start automations notifications -->
                        <template v-if="automationNotificationEnabled">
                            <tr
                                class="text-white"
                                :class="!contextAccount.automation ? 'bg-blue-400' : 'bg-blue-500'"
                            >
                                <th class="text-center">
                                    {{ $t('users.edit.automationNotification') }}
                                    <activix-tooltip :content="$t('users.edit.automationNotificationTooltip')">
                                        <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                    </activix-tooltip>
                                </th>
                                <th class="text-center">
                                    {{ $t('users.edit.screen') }}
                                </th>
                                <th class="text-center" />
                                <th class="text-center">
                                    {{ $t('users.edit.push') }}
                                </th>
                                <th class="text-center">
                                    {{ $t('users.edit.email') }}
                                </th>
                                <th class="text-center">
                                    {{ $t('users.edit.frequencySituation') }}
                                </th>
                                <th class="text-center">
                                    {{ $t('users.edit.notificationByDivision') }}
                                </th>
                            </tr>

                            <tr :key="index" v-for="(automationOption, index) in automationOptions">
                                <td>
                                    <div
                                        class="flex items-baseline"
                                        :class="{ 'opacity-50': !automationOption.action.active || !automationOption.action.automation.active }"
                                    >
                                        <router-link
                                            target="_blank"
                                            :to="{ name: 'automations.update', params: { id: automationOption.action.automation_id } }"
                                            v-if="authUser.hasAccessTo('automations.index')"
                                        >
                                            {{ getAutomationName(automationOption) }}
                                        </router-link>
                                        <span v-else>
                                            {{ getAutomationName(automationOption) }}
                                        </span>
                                        <activix-tooltip :content="getAutomationTooltip(automationOption)">
                                            <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                                        </activix-tooltip>
                                    </div>
                                </td>

                                <td class="text-center">
                                    <activix-checkbox
                                        :value="automationOption.screen"
                                        :disabled="!automationOption.action.active || !automationOption.action.automation.active"
                                        @input="updateUserAutomationOptions(index, 'screen', $event)"
                                    />
                                </td>
                                <td class="text-center" />
                                <td class="text-center">
                                    <activix-checkbox
                                        :value="automationOption.push"
                                        :disabled="!automationOption.action.active || !automationOption.action.automation.active"
                                        @input="updateUserAutomationOptions(index, 'push', $event)"
                                    />
                                </td>
                                <td class="text-center">
                                    <activix-checkbox
                                        :value="automationOption.email"
                                        :disabled="!automationOption.action.active || !automationOption.action.automation.active"
                                        @input="updateUserAutomationOptions(index, 'email', $event)"
                                    />
                                </td>
                                <td class="text-center">
                                    <div class="flex items-center justify-center">
                                        <activix-multiselect
                                            class="max-w-64"
                                            label="value"
                                            :disabled="!automationOption.action.active || !automationOption.action.automation.active"
                                            :selected="
                                                defaultScheduleOptions.find(
                                                    option => option.id == automationOption.availability,
                                                )
                                            "
                                            :options="defaultScheduleOptions"
                                            :searchable="false"
                                            :allow-empty="false"
                                            :show-labels="false"
                                            @update="openFrequencyModal(index, $event, true)"
                                        />
                                    </div>
                                </td>
                                <td class="text-center w-56">
                                    <notification-divisions :automation-option="index" :disabled="!automationOption.action.active || !automationOption.action.automation.active" />
                                </td>
                            </tr>
                        </template>
                        <!-- End automations notifications -->
                    </tbody>
                </table>
            </div>
        </div>

        <div class="row | flex flex-wrap" v-if="$route.name == 'users.update'">
            <div class="col-xs-12 col-md-6 | flex">
                <div class="box | mb-6">
                    <div class="box-header | border-b">
                        <h4 class="box-title">
                            {{ $t('users.edit.signature_fr') }}
                        </h4>
                    </div>
                    <div class="box-body">
                        <activix-summernote
                            :height="200"
                            :value="currentForm.email_config.signature_fr"
                            ref="signature"
                            @input="updateUserProperties({ 'email_config.signature_fr': $event })"
                        />
                    </div>
                </div>
            </div>

            <div class="col-xs-12 col-md-6 | flex">
                <div class="box | mb-6">
                    <div class="box-header | border-b">
                        <h4 class="box-title">
                            {{ $t('users.edit.signature_en') }}
                        </h4>
                    </div>
                    <div class="box-body">
                        <activix-summernote
                            :height="200"
                            :value="currentForm.email_config.signature_en"
                            ref="signature"
                            @input="updateUserProperties({ 'email_config.signature_en': $event })"
                        />
                    </div>
                </div>
            </div>
            <div class="flex" :class="!showNioTextBox ? 'col-xs-12 col-md-12' : 'col-xs-6 col-md-6'">
                <div class="box | flex-1 mb-6">
                    <div class="box-header | border-b">
                        <div class="flex items-center">
                            <h4 class="box-title">
                                {{ $t('users.edit.dateOptions') }}
                            </h4>
                            <activix-tooltip :content="$t('users.edit.dateRangeTooltip')">
                                <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                            </activix-tooltip>
                        </div>
                    </div>
                    <div class="box-body">
                        <div class="row">
                            <div class="col-xs-12 col-xl-6 mb-4">
                                <label>{{ $t('users.edit.olderThan') }}</label>
                                <activix-masked-input
                                    mask-type="number"
                                    :integer-limit="3"
                                    :value="currentForm.older_than"
                                    @input="updateUserProperties({ older_than: $event })"
                                />
                            </div>
                            <div class="col-xs-12 col-xl-6 mb-4">
                                <label>{{ $t('users.edit.newerThan') }}</label>
                                <activix-masked-input
                                    mask-type="number"
                                    :integer-limit="3"
                                    :value="currentForm.newer_than"
                                    @input="updateUserProperties({ newer_than: $event })"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-6 col-md-6 | flex" v-if="showNioTextBox">
                <div class="box | mb-6">
                    <div class="box-header | border-b">
                        <h4 class="box-title">
                            {{ $t('users.edit.nioText') }}
                        </h4>
                    </div>
                    <div class="box-body">
                        <div class="form-group">
                            <label>{{ $t('users.edit.confirmationApptOption') }}</label>
                            <nio-text-timeframe
                                :timeframe="currentForm.confirmation_timeframe"
                                @update="updateUserProperties({ confirmation_timeframe: $event })"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="box | mb-6">
            <div class="box-header | border-b">
                <h4 class="box-title">
                    {{ $t('users.edit.calendar') }}
                </h4>
            </div>
            <div class="box-body">
                <div class="row">
                    <div class="col-xs-6">
                        <label class="flex items-baseline">
                            {{ $t('calendar.taskReminders') }}
                            <activix-tooltip :content="$t('calendar.taskRemindersTooltip')">
                                <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                            </activix-tooltip>
                            <icon class="link-grey-light text-sm ml-1" name="bold/add" @click="addReminder('task')" />
                        </label>

                        <div class="-mt-4">
                            <calendar-row
                                :reminder.sync="reminder"
                                :key="index"
                                @delete-reminder="deleteReminder(reminder)"
                                @update-reminder="updateReminder(reminder)"
                                v-for="(reminder, index) in taskReminders"
                            />
                        </div>
                    </div>

                    <div class="col-xs-6">
                        <label class="flex items-baseline">
                            {{ $t('calendar.eventReminders') }}
                            <activix-tooltip :content="$t('calendar.eventRemindersTooltip')">
                                <icon class="text-grey-500 text-sm ml-1" name="regular/information-circle" />
                            </activix-tooltip>
                            <icon class="link-grey-light text-sm ml-1" name="bold/add" @click="addReminder('event')" />
                        </label>

                        <div class="-mt-4">
                            <calendar-row
                                :reminder.sync="reminder"
                                :key="index"
                                @delete-reminder="deleteReminder(reminder)"
                                @update-reminder="updateReminder(reminder)"
                                v-for="(reminder, index) in eventReminders"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="box | mb-6">
            <div class="box-header | border-b">
                <h4 class="box-title">
                    {{ $t('users.edit.imapEmail') }}
                </h4>
            </div>
            <div class="box-body">
                <mail-config @state-changed="imapIsActive = $event == 'activate'" />
            </div>
        </div>

        <activix-confirm-modal
            type="warning"
            :content="frequencyModalContent"
            :opened.sync="frequencyWarningModalOpened"
            :show-deny="false"
            approve-text="Ok"
            @approve="updateNotificationFrequency"
            @closed="clearNotificationFrequencyModal"
        />

        <activix-confirm-modal
            type="warning"
            :title="$t('users.edit.smsCosts')"
            :content="$t('users.edit.warningSmsActivation')"
            :opened.sync="warningSmsActivation"
            :approve-text="$t('general.ok')"
            :deny-text="$t('general.cancel')"
            @deny="denySmsActivation"
        />
    </div>
</template>

<script>
    // Utils
    import { get } from 'lodash-es';

    import { mapActions, mapState } from 'pinia';
    // Components
    import MailConfig from './MailConfig.vue';
    import CalendarRow from './CalendarRow.vue';
    import NioTextTimeframe from '../nio_text/NioTextTimeframe.vue';
    // Entities
    import Role from '../../entities/Role.js';
    import NotificationDivisions from './NotificationDivisions.vue';

    // Pinia
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useUserCardStore } from '../../store/modules/userCard/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        components: {
            NotificationDivisions,
            MailConfig,
            CalendarRow,
            NioTextTimeframe,
        },

        data() {
            return {
                warningSmsActivation: false,
                groupSmsActivation: null,
                imapIsActive: false,
                frequencyWarningModalOpened: false,
                frequencyModalValue: null,
                frequencyNotificationName: '',
                frequencyNoticationAutomationId: null,
                defaultScheduleOptions: [
                    {
                        id: 'anytime',
                        value: this.$t('users.edit.anytime'),
                    },
                    {
                        id: 'user_schedule',
                        value: this.$t('users.edit.userSchedule'),
                    },
                    {
                        id: 'account_schedule',
                        value: this.$t('users.edit.accountSchedule'),
                    },
                ],
                Role,
                roles: {
                    commercial_id: this.$t('automations.leadRelatedUser.commercial'),
                    service_agent_id: this.$t('automations.leadRelatedUser.serviceAgentUser'),
                    service_advisor_id: this.$t('automations.leadRelatedUser.serviceAdvisorUser'),
                    bdc_user_id: this.$t('automations.leadRelatedUser.bdcUser'),
                    user_id: this.$t('automations.leadRelatedUser.user'),
                },
            };
        },

        computed: {
            ...mapState(useUserCardStore, {
                currentForm: 'currentForm',
                preferenceOptions: store => store.currentForm.options,
                automationOptions: store => store.currentForm.automation_options,
            }),
            ...mapState(useContextStore, {
                contextAccount: 'account',
                contextUser: 'user',
            }),
            ...mapState(useGlobalStore, ['authUser']),

            automationNotificationEnabled() {
                if (!this.$feature.isEnabled('automation-notification')) {
                    return false;
                }

                return !empty(this.automationOptions);
            },

            hasRestrictedNotifications() {
                return this.contextAccount.restricted_notifications && !this.authUser.isAdmin();
            },

            restrictedNotificationsTooltip() {
                if (!this.contextAccount.restricted_notifications) {
                    return '';
                }

                if (this.authUser.isAdmin()) {
                    return this.$t('users.edit.restrictedNotificationsWarning');
                }

                return this.$t('users.edit.restrictedNotifications');
            },

            frequencyModalContent() {
                if (!this.frequencyModalValue) {
                    return '';
                }

                if (this.frequencyModalValue.id == 'user_schedule') {
                    return this.$t('users.edit.userFrequencyWarning');
                }

                if (this.frequencyModalValue.id == 'account_schedule') {
                    return this.$t('users.edit.accountFrequencyWarning');
                }

                return '';
            },

            accountHasInTurn() {
                return get(this.contextAccount, 'in_turn', false);
            },

            accountHasUntreatedLeadsAlerts() {
                return (
                    get(this.contextAccount, 'untreated_lead', false) && get(this.contextAccount, 'leadxpress', false)
                );
            },

            hasNioText() {
                return this.contextAccount.hasOption('niotext') && this.currentForm.niotext;
            },

            hasRenewalAlertEquity() {
                return (
                    this.contextAccount.hasOption('equity_alert') &&
                    this.contextAccount.equity_alert &&
                    this.contextAccount.renewal_alert_options.equity_base
                );
            },

            hasRenewalAlertLocation() {
                return (
                    this.currentForm.module_access.renewal &&
                    !empty(this.contextAccount.renewal_alert_options.end_location_notifications)
                );
            },

            hasRenewalAlertWarranty() {
                return (
                    this.currentForm.module_access.renewal &&
                    !empty(this.contextAccount.renewal_alert_options.end_warranty_notifications)
                );
            },

            tooltipRenewalAlertEquity() {
                if (!this.contextAccount.hasOption('equity_alert') || !this.contextAccount.equity_alert) {
                    return this.$t('users.edit.needEquityAlert');
                }

                if (!this.hasRenewalAlertEquity) {
                    return this.$t('users.edit.needRenewalAlertConfig');
                }

                return '';
            },

            tooltipRenewalAlertLocation() {
                if (!this.currentForm.module_access.renewal) {
                    return this.$t('users.edit.needRenewal');
                }

                if (!this.hasRenewalAlertLocation) {
                    return this.$t('users.edit.needRenewalAlertConfig');
                }

                return '';
            },

            tooltipRenewalAlertWarranty() {
                if (!this.currentForm.module_access.renewal) {
                    return this.$t('users.edit.needRenewal');
                }

                if (!this.hasRenewalAlertWarranty) {
                    return this.$t('users.edit.needRenewalAlertConfig');
                }

                return '';
            },

            showNioTextBox() {
                return this.hasNioText && this.contextAccount.confirmation_appt_sale;
            },

            eventReminders() {
                if (empty(this.currentForm.reminder_preferences)) {
                    return {};
                }

                return this.currentForm.reminder_preferences.filter(reminder => {
                    return reminder.type == 'event' || empty(reminder);
                });
            },

            taskReminders() {
                if (empty(this.currentForm.reminder_preferences)) {
                    return {};
                }

                return this.currentForm.reminder_preferences.filter(reminder => {
                    return reminder.type == 'task';
                });
            },

            userInFlow() {
                if (!this.$route.params.id) {
                    return false;
                }

                return this.currentForm.flows.some(flow => {
                    return (
                        flow.communication_type_id == 2 && flow.communication_method_id == 2 && !empty(flow.pivot.order)
                    );
                });
            },

            hasOneCommunicationMethod() {
                if (!this.userInFlow) {
                    return false;
                }

                const contactMethodCounter =
                    this.currentForm.options.newLead.sms +
                    this.currentForm.options.newLead.screen +
                    this.currentForm.options.newLead.push +
                    this.currentForm.options.newLead.email;

                return contactMethodCounter == 1;
            },

            enabledUntreatedLeadAlertSms() {
                if (!this.accountHasUntreatedLeadsAlerts) {
                    return true;
                }

                if (
                    this.authUser.isAdmin() ||
                    this.authUser.isDirector() ||
                    this.authUser.isBdcDirector() ||
                    this.authUser.isCommercialDirector()
                ) {
                    return false;
                }

                return this.currentForm.options.untreatedLead.sms && !this.currentForm.options.untreatedLead.email;
            },

            enabledUntreatedLeadAlertEmail() {
                if (!this.accountHasUntreatedLeadsAlerts) {
                    return true;
                }

                if (
                    this.authUser.isAdmin() ||
                    this.authUser.isDirector() ||
                    this.authUser.isBdcDirector() ||
                    this.authUser.isCommercialDirector()
                ) {
                    return false;
                }

                return !this.currentForm.options.untreatedLead.sms && this.currentForm.options.untreatedLead.email;
            },

            hasService() {
                return this.contextAccount.hasOption('service') && this.currentForm.module_access.service;
            },

            hasOnlyServiceDivision() {
                return this.currentForm.divisions.length == 1 && this.currentForm.divisions[0].name == 'service';
            },

            isAdvisorOrBdcAgent() {
                return [Role.ADVISOR, Role.AGENT].includes(this.currentForm.role.id);
            },

            isDirectorOrBdcDirector() {
                return [Role.DIRECTOR, Role.DIRECTOR_BDC].includes(this.currentForm.role.id);
            },

            isAuthDirectorOrBdcDirector() {
                return this.authUser.isAdmin() || this.authUser.isDirector() || this.authUser.isBdcDirector();
            },
        },

        methods: {
            ...mapActions(useUserCardStore, ['updateUserProperties']),

            mustKeepOneExternalMedium(group, property) {
                const option = this.preferenceOptions[group];
                const otherMandatoryProperties = ['email', 'sms', 'push'].filter(prop => prop !== property);
                const atLeastOneOtherMandatoryPropertyActive = otherMandatoryProperties.some(prop => option[prop]);

                return !atLeastOneOtherMandatoryPropertyActive && option[property];
            },

            openFrequencyModal(notification, value, isAutomationOption = false) {
                this.frequencyModalValue = value;
                if (isAutomationOption) {
                    this.frequencyNoticationAutomationId = notification;
                } else {
                    this.frequencyNotificationName = notification;
                }

                if (value.id == 'anytime') {
                    this.updateNotificationFrequency();
                    this.clearNotificationFrequencyModal();
                } else {
                    this.frequencyWarningModalOpened = true;
                }
            },

            updateNotificationFrequency() {
                if (this.frequencyNoticationAutomationId) {
                    const options = this.automationOptions;
                    options[this.frequencyNoticationAutomationId].availability = this.frequencyModalValue.id;

                    useUserCardStore().currentForm.automation_options = options;
                }

                if (this.frequencyNotificationName) {
                    const options = this.preferenceOptions;
                    options[this.frequencyNotificationName].availability = this.frequencyModalValue.id;

                    useUserCardStore().currentForm.options = options;
                }
            },

            clearNotificationFrequencyModal() {
                this.frequencyModalValue = null;
                this.frequencyNotificationName = '';
                this.frequencyNoticationAutomationId = null;
            },

            addReminder(type) {
                const reminderPreferences = this.currentForm.reminder_preferences || [];

                reminderPreferences.push({
                    date_range: 10,
                    sms: false,
                    email: false,
                    notification: true,
                    push: false,
                    type,
                });

                useUserCardStore().currentForm.reminder_preferences = reminderPreferences;
            },

            deleteReminder(reminder) {
                const reminderPreferences = this.currentForm.reminder_preferences;

                const index = reminderPreferences.indexOf(reminder);
                reminderPreferences.splice(index, 1);

                useUserCardStore().currentForm.reminder_preferences = reminderPreferences;
            },

            updateReminder(reminder) {
                const reminderPreferences = this.currentForm.reminder_preferences;

                const index = reminderPreferences.indexOf(reminder);
                reminderPreferences[index] = reminder;

                useUserCardStore().currentForm.reminder_preferences = reminderPreferences;
            },

            generateTooltip(type, property) {
                switch (type) {
                    case 'obtainedSale':
                        if (!empty(this.currentForm.role) && this.currentForm.role.id == Role.DIRECTOR) {
                            return this.$t('users.edit.obtainedSaleTooltip');
                        }

                        return this.$t('users.edit.obtainedSaleAssignedTooltip');

                    case 'deposit':
                        if (!empty(this.currentForm.role) && this.currentForm.role.id == Role.DIRECTOR) {
                            return this.$t('users.edit.depositTooltip');
                        }

                        return this.$t('users.edit.depositAssignedTooltip');

                    case 'approved':
                        if (!empty(this.currentForm.role) && this.currentForm.role.id == Role.DIRECTOR) {
                            return this.$t('users.edit.approvedTooltip');
                        }

                        return this.$t('users.edit.approvedAssignedTooltip');

                    case 'prepared':
                        if (!empty(this.currentForm.role) && this.currentForm.role.id == Role.DIRECTOR) {
                            return this.$t('users.edit.preparedTooltip');
                        }

                        return this.$t('users.edit.preparedAssignedTooltip');

                    case 'inspected':
                        if (!empty(this.currentForm.role) && this.currentForm.role.id == Role.DIRECTOR) {
                            return this.$t('users.edit.inspectedTooltip');
                        }

                        return this.$t('users.edit.inspectedAssignedTooltip');

                    case 'gas':
                        if (!empty(this.currentForm.role) && this.currentForm.role.id == Role.DIRECTOR) {
                            return this.$t('users.edit.gasTooltip');
                        }

                        return this.$t('users.edit.gasAssignedTooltip');

                    case 'delivered':
                        if (!empty(this.currentForm.role) && this.currentForm.role.id == Role.DIRECTOR) {
                            return this.$t('users.edit.deliveredTooltip');
                        }

                        return this.$t('users.edit.deliveredAssignedTooltip');

                    case 'deliveryDate':
                        if (!empty(this.currentForm.role) && this.currentForm.role.id == Role.DIRECTOR) {
                            return this.$t('users.edit.deliveryTooltip');
                        }

                        return this.$t('users.edit.deliveryAssignedTooltip');

                    case 'availableDate':
                        if (!empty(this.currentForm.role) && this.currentForm.role.id == Role.DIRECTOR) {
                            return this.$t('users.edit.availableTooltip');
                        }

                        return this.$t('users.edit.availableAssignedTooltip');

                    case 'paperworkDate':
                        if (!empty(this.currentForm.role) && this.currentForm.role.id == Role.DIRECTOR) {
                            return this.$t('users.edit.paperworkTooltip');
                        }

                        return this.$t('users.edit.paperworkAssignedTooltip');

                    case 'webBoost':
                        if (!this.currentForm.module_access.web_boost || !this.contextAccount.hasOption('webboost')) {
                            return this.$t('users.edit.needWebBoost');
                        }

                        break;

                    case 'leadXpress':
                        if (
                            this.hasOneCommunicationMethod &&
                            ((this.currentForm.options.newLead.screen && property === 'screen') ||
                                (this.currentForm.options.newLead.push && property === 'push') ||
                                (this.currentForm.options.newLead.sms && property === 'sms') ||
                                (this.currentForm.options.newLead.email && property === 'email'))
                        ) {
                            return this.$t('users.edit.newLeadNotificationTooltip');
                        }

                        if (
                            !this.currentForm.module_access.leadxpress ||
                            !this.contextAccount.hasOption('leadxpress')
                        ) {
                            return this.$t('users.edit.needLeadXpress');
                        }

                        break;

                    case 'leadXpressDuplicate':
                        if (
                            !this.currentForm.module_access.leadxpress ||
                            !this.contextAccount.hasOption('leadxpress')
                        ) {
                            return this.$t('users.edit.needLeadXpress');
                        }

                        break;

                    case 'leadXpressUntreated':
                        if (
                            !this.currentForm.module_access.leadxpress ||
                            !this.contextAccount.hasOption('leadxpress')
                        ) {
                            return this.$t('users.edit.needLeadXpress');
                        }
                        if (!this.accountHasUntreatedLeadsAlerts) {
                            return this.$t('users.edit.activeOptionAccount');
                        }

                        break;

                    case 'inTurn':
                        if (!this.accountHasInTurn) {
                            return this.$t('users.edit.needInTurn');
                        }

                        break;

                    case 'event':
                        if (!this.currentForm.module_access.event || !this.contextAccount.hasOption('event')) {
                            return this.$t('users.edit.needEvent');
                        }

                        break;

                    case 'service':
                        if (!this.currentForm.module_access.service || !this.contextAccount.hasOption('service')) {
                            return this.$t('users.edit.needService');
                        }

                        break;

                    case 'nioText':
                        if (!this.hasNioText) {
                            return this.$t('users.edit.needNioText');
                        }

                        break;

                    case 'videoconference':
                        if (!this.contextAccount.video_conference) {
                            return this.$t('users.edit.needVideoConference');
                        }

                        break;

                    case 'mentions':
                        if (property === 'push' && !this.contextUser.last_mobile_heartbeat_at) {
                            return this.$t('users.edit.needToLogOnceBeforeActivatingPush');
                        }

                        if (this.mustKeepOneExternalMedium(type, property)) {
                            return this.$t('users.edit.externalNotificationMediumRequired');
                        }

                        break;
                }

                return '';
            },

            updateUserPreferenceOptions(group, property, value) {
                if (typeof this.currentForm.options[group] === 'undefined') {
                    return;
                }

                const options = this.currentForm.options;
                options[group][property] = value;

                useUserCardStore().currentForm.options = options;

                if (property == 'sms' && value) {
                    this.groupSmsActivation = group;
                    this.warningSmsActivation = true;
                }

                if (property == 'screen' && value) {
                    this.updateUserPreferenceOptions(group, 'push', value);
                }
            },

            getAutomationName(automationOption) {
                const multipleOptionForSameAutomation = Object.entries(this.automationOptions)
                    .filter(option => option[1].action.automation_id === automationOption.action.automation_id)
                    .length >= 2;
                const multipleOptionForSameTemplate = Object.entries(this.automationOptions)
                    .filter(option => option[1].action.parameters.template.title === automationOption.action.parameters.template.title)
                    .length >= 2;
                const toWhom = automationOption.action.parameters.to_role
                    ? this.roles[automationOption.action.parameters.to_role]
                    : this.$t('automations.leadRelatedUser.you');

                let automationName = automationOption.action.automation.name;

                if (multipleOptionForSameAutomation) {
                    automationName = `${automationName} - ${automationOption.action.parameters.template.title}`;
                }

                if (multipleOptionForSameTemplate) {
                    automationName = `${automationName} - ${toWhom}`;
                }

                return automationName;
            },

            getAutomationTooltip(automationOption) {
                if (automationOption.action.parameters.to_role) {
                    return this.$t('users.edit.automationActionNotificationTooltip', [
                        automationOption.action.automation.name,
                        this.roles[automationOption.action.parameters.to_role],
                    ]);
                }

                return this.$t('users.edit.automationActionNotificationYouTooltip', [
                    automationOption.action.automation.name,
                ]);
            },

            updateUserAutomationOptions(index, property, value) {
                if (typeof this.currentForm.automation_options[index] === 'undefined') {
                    return;
                }

                const options = this.currentForm.automation_options;
                options[index][property] = value;

                useUserCardStore().currentForm.automation_options = options;

                if (property == 'screen' && value) {
                    this.updateUserAutomationOptions(index, 'push', value);
                }
            },

            denySmsActivation() {
                this.updateUserPreferenceOptions(this.groupSmsActivation, 'sms', false);
            },
        },
    };
</script>
