<template>
    <activix-modal scrollable :opened="opened" @close="closeModal">
        <template slot="header">
            <h4 class="modal-title" v-text="$t('users.edit.copyModifs')" />
        </template>

        <div class="flex flex-col items-center space-y-3 py-2" slot="body">
            <div class="text-xl font-medium" v-text="$t('users.edit.updateChildModalTitle')" />

            <div class="w-2/3">
                <activix-multiselect
                    multiple="multiple"
                    :options="childUsers"
                    :searchable="false"
                    :selected="userSelected"
                    v-model="userSelected"
                />
            </div>
        </div>

        <template slot="footer">
            <div class="flex items-center justify-end space-x-2">
                <activix-button @click="closeModal" v-text="$t('users.edit.ignore')" />

                <activix-tooltip>
                    <activix-button
                        :loading="loading"
                        type="primary"
                        :disabled="!userSelected.length"
                        @click="update"
                    >
                        {{ $t('users.edit.copy') }}
                    </activix-button>
                </activix-tooltip>
            </div>
        </template>
    </activix-modal>
</template>

<script>
    import { mapState } from 'pinia';
    import axios from '@/plugins/axios.js';
    import { useUserCardStore } from '@/store/modules/userCard/store.js';

    export default {
        props: {
            opened: {
                type: Boolean,
                default: false,
            },
            changes: {
                type: Object,
                default: null,
            },
        },

        data() {
            return {
                userSelected: [],
                loading: false,
            };
        },

        computed: {
            ...mapState(useUserCardStore, ['currentForm']),

            childUsers() {
                return this.currentForm.children.map(child => {
                    return { label: child.account.name, id: child.id };
                });
            },
        },

        methods: {
            async update() {
                this.loading = true;

                await axios.put('v1/users/child', {
                    changes: this.changes,
                    user_ids: this.userSelected.map(user => user.id),
                });

                this.closeModal();
            },

            closeModal() {
                this.userSelected = [];
                this.loading = false;

                this.$emit('closed');
            },
        },
    };
</script>
